import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allGrades: [],
        allParentGrades: [],
        Grade: {
            id: null,
            parent_id: null,
            productgroups: [],
            title: null
        },
        Parent: {
            id: null,
            title: null
        }
    }
}

const state = {
    allGrades: [],
    allParentGrades: [],
    current: {
        Grade: {
            id: null,
            parent_id: null,
            productgroups: [],
            title: null
        },
        Parent: {
            id: null,
            title: null
        }
    },
    update: {
        Grade: {},
        Parent: {}
    }
}

const getters = {
    allGrades: state => {
        return state.allGrades;
    },
    allParentGrades: state => {
        return state.allParentGrades;
    },
    findGradeById: state => {
        return id => state.allGrades.find (grade => {
            return grade.Grade.id === id
        })
    },
    getField
}

const actions = {
    createGrade ({state, rootState}) {
        let grade = _.cloneDeep(state.current.Grade)
        let parent = _.cloneDeep(state.current.Parent)
        return new Promise((resolve, reject) => {
            api
                .post('/grades',{
                    grade: grade,
                    parent: parent
                })
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteGradeById ({commit}, grade_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/grades/' + grade_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllGrades({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/grades")
                .then(response => {
                    commit('setAllGrades',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllParentGrades ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/parents/grades")
                .then(response => {
                    commit('setAllParentGrades',response.data.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getGradeToUpdateById( {commit}, gradeId ) {
        return new Promise((resolve, reject) => {
            api
                .get('/grades/' + gradeId )
                .then(response => {
                    commit('setGradeToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetAllGrades({commit}){
        commit('resetAllGrades')
    },
    resetAllParentGrades({commit}){
        commit('resetAllParentGrades')
    },
    resetGradeToUpdate( {commit} ) {
        commit('resetGradeToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateGrade ( {state} ) {
        let grade = _.cloneDeep(state.update.Grade)
        let parent = _.cloneDeep(state.update.Parent)
        //remove unneeded properties
        return new Promise((resolve, reject) => {
            api
                .put("/grades/" + state.update.Grade.id,{
                    grade: grade,
                    parent: parent
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

const mutations = {
    setAllParentGrades(state, data){
        state.allParentGrades = data;
    },
    setAllGrades(state,data){
        state.allGrades = data;
    },
    setGradeToUpdate(state, grade){
        state.update.Grade = grade.Grade
        state.update.Parent = grade.Parent
    },
    resetAllGrades(state){
        state.allGrades = getDefaultState().allGrades
    },
    resetAllParentGrades(state){
        state.allParentGrades = getDefaultState().allParentGrades
    },
    resetGradeToUpdate (state) {
        state.update.Grade = getDefaultState().Grade
        state.update.Parent = getDefaultState().Parent
    },
    resetCurrentState (state) {
        state.current.Grade = getDefaultState().Grade
        state.current.Parent = getDefaultState().Parent
    },
    resetState(state){
        state.allGrades = getDefaultState().allGrades
    },
    updateField
}

export const grade = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}