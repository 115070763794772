import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Courier: {
			id: null,
			title: null,
			tracking_url: null,
			tracking_id_placeholder: 'TRACKING_ID',
		}
	}
}

const state = {
	allCouriers: [],
	current: {
		Courier: {
			id: null,
			title: null,
			tracking_url: null,
			tracking_id_placeholder: 'TRACKING_ID',
		}
	},
	update: {
		Courier: {}
	}
}

const getters = {
	allCouriers: state => {
		return state.allCouriers;
	},
	getField
}

const mutations = {
	setAllCouriers (state,data) {
		state.allCouriers = data;
	},
	setCourierToUpdate ( state, courier ){
		state.update.Courier = courier.Courier
	},
	resetCourierToUpdate(state) {
		state.update.Courier = getDefaultState().Courier
	},
	resetCurrentState(state) {
		state.current.Courier = getDefaultState().Courier
	},
	resetState(state) {
		state.allCouriers = []
	},
	updateField
}

const actions = {
	createCourier({state}) {
		const courier = _.cloneDeep(state.current.Courier)
		return new Promise((resolve, reject) => {
			api
				.post('/couriers',{
					courier: courier,
					}
				)
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllCouriers ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/couriers")
				.then(response => {
					commit('setAllCouriers',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getCourierToUpdateById({commit}, courierId) {
		return new Promise((resolve, reject) => {
			api
				.get('/couriers/' + courierId )
				.then(response => {
					commit('setCourierToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetCourierToUpdate({commit}) {
		commit('resetCourierToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateCourier({state}) {
		const courier = _.cloneDeep(state.update.Courier)
		return new Promise((resolve, reject) => {
			api
				.put("/couriers/" + state.update.Courier.id,{
					courier: courier
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const courier = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}