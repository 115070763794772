<!-- App Main Structure -->
<template>
    <div>
        <div class="app-default-layout">
            <template v-if="loading">
                <rotate-square2/>
            </template>
            <template v-else>
                <!-- App Header -->
                <app-header/>
                <!-- App Main Content -->
                <v-main>
                    <v-alert v-if="appIsIdle" class="warning">The app has timed out!</v-alert>
                    <!-- Update Notification-->
<!--                    <v-alert class="mb-0" light dense type="warning" v-if="updateExists">{{ $t('message.appicUpdateAvailable') }}</v-alert>-->
<!--                    <v-snackbar bottom right :value="updateExists" :timeout="0" color="success" class="text-center">-->
<!--                        {{ $t('message.appicUpdateAvailable') }}-->
<!--                        <v-btn text @click="refreshApp">{{ $t('main-nav.update') }}</v-btn>-->
<!--                    </v-snackbar>-->
                    <!-- App Router -->
                    <transition name="router-anim" :enter-active-class="`animated ${selectedRouterAnimation}`">
                        <router-view/>
                    </transition>
                </v-main>
            </template>
            <AppTimedOut :options="{timeoutPeriod: timeoutPeriod, countdownStart: countdownStart}" v-if="loadAppTimedOutComponent"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "Components/Header/Header.vue";
import {idleTime} from "Helpers/idle-time";
// import Customizer from "Components/Customizer/Customizer";
// import Tour from "Components/Tour/Tour";
// import AppConfig from "Constants/AppConfig";
import update from "Helpers/update";
// import ClosedSessions from 'Components/Appic/Alert/ClosedSessions.vue';
import AppTimedOut from "Components/Appic/Alert/AppTimedOut.vue";
// import {store} from "@/store/store";

export default {
    mixins: [update],
	data() {
		return {
            appIsIdle: false,
            closedSessions: 0,
            countdownStart: 30000,
            hasClosedSessions: false,
		    loading: true,
			snackbar: false,
            timeoutPeriod: 60000 * 60 * 2, //in hours
            loadAppTimedOutComponent: false
		};
	},
	components: {
        AppTimedOut,
	    appHeader: Header
	},
	computed: {
		...mapGetters(["selectedRouterAnimation"]),
        ...mapGetters('user',{
            User: 'User'
        }),
	},
    methods: {
        closedSessionsDialogClosed() {
            this.closedSessions = 0
            this.hasClosedSessions = false
        }
    },
    created(){
        let perfEntries = performance.getEntriesByType("navigation");
        for (let i=0; i < perfEntries.length; i++) {
            let p = perfEntries[i];
            if(p.type == 'reload'){
                this.$store.commit('resetPackageVersion')
            }
        }
    },
    mounted() {
        const closedSessions = JSON.parse(localStorage.getItem('closedSessions'));
        if(closedSessions && parseInt(closedSessions) > 0 ) {
            this.closedSessions = parseInt(closedSessions)
            this.hasClosedSessions = true
        }
        setTimeout(() => {
			this.loading = false;
            if(!this.User.appic_timeout && this.User.appic_timeout != null){
                this.timeoutPeriod = parseInt(this.User.appic_timeout)
                this.timeoutPeriod = this.timeoutPeriod * 60000 * 60 //in hours
            }
            if(this.timeoutPeriod > 0) {
                // temp hold 2023-06-07
                // this.loadAppTimedOutComponent = true
            }
		}, 300);
    }
};
</script>

<style scoped>
	.app-default-layout {
	  height: 100vh;
	}
</style>
