import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allThickness: [],
		Thickness: {
			id: null,
			ms: 1,
			title: null
		}
	}
}

const state = {
	allThickness: [],
	current: {
		Thickness: {
			id: null,
			ms: 1,
			title: null
		}
	},
	update: {
		Thickness: {}
	}
}

const getters = {
	allThickness: state => {
		return state.allThickness;
	},
	getField
}

const mutations = {
	setAllThickness(state,data){
		state.allThickness = data;
	},
	setThicknessToUpdate(state, thickness){
		state.update.Thickness = thickness.Thickness
	},
	resetAllThickness(state){
		state.allThickness = getDefaultState().allThickness
	},
	resetThicknessToUpdate (state) {
		state.update.Thickness = getDefaultState().Thickness
	},
	resetCurrentState (state) {
		state.current.Thickness = getDefaultState().Thickness
	},
	resetState(state){
		state.allThickness = getDefaultState().allThickness
	},
	updateField
}

const actions = {
	createThickness ({state, rootState}) {
		let thickness = _.cloneDeep(state.current.Thickness)
		return new Promise((resolve, reject) => {
			api
				.post('/thickness',{
						thickness: thickness,
					}
				)
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	deleteThicknessById ({commit}, thicknessId) {
		return new Promise((resolve, reject) => {
			api
				.delete('/thickness/' + thicknessId)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllThickness({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/thickness")
				.then(response => {
					commit('setAllThickness',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getThicknessToUpdateById( {commit}, thicknessId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/thickness/' + thicknessId )
				.then(response => {
					commit('setThicknessToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetAllThickness({commit}){
		commit('resetAllThickness')
	},
	resetThicknessToUpdate( {commit} ) {
		commit('resetThicknessToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateThickness ( {state} ) {
		let thickness = _.cloneDeep(state.update.Thickness)
		return new Promise((resolve, reject) => {
			api
				.put("/thickness/" + state.update.Thickness.id,{
					thickness: thickness
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const thickness = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}