/**
 * Helpers Functions
 */
import moment from 'moment';
import numeral from 'numeral'
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import * as DOMPurify from 'dompurify';

/**
 * Convert breaks to new lines
 * @param str
 * @returns {*}
 */
export function br2nl(str) {
	return str.replace(/<br\s*\/?>/mg,"\n");
}

/**
 * Convert new lines to HTML breaks
 * @param str
 * @param is_xhtml
 * @returns {string}
 */
export function nl2br (str, is_xhtml = false) {
	if (typeof str === 'undefined' || str === null) {
		return '';
	}
	let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

/**
 * Get date
 * @param timestamp
 * @param format
 * @returns {string}
 */
export function getTheDate(timestamp, format) {
	let time = timestamp * 1000;
	let formatDate = format ? format : 'MM-DD-YYYY';
	return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
 * @param date
 * @param format
 * @returns {number}
 */
export function convertDateToTimeStamp(date, format) {
	let formatDate = format ? format : 'YYYY-MM-DD';
	return moment(date, formatDate).unix();
}

/**
 * Text Truncate
 * @param str
 * @param length
 * @param ending
 * @returns {string|*}
 */
export function textTruncate(str, length, ending) {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
}

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
	}
	throw new Error('Bad Hex');
}

/**
 * Function to return current app layout
 */
export function getCurrentAppLayout(router) {
	let location = router.history.current.fullPath;
	let path = location.split("/")
	return path[1];
}

/**
 *Number format
 */
export function numberFormat(value, format) {
	return numeral(value).format(format)
}

/**
 * Check if variable or param exists
 * @param fn
 * @returns {undefined|*}
 */
export function getSafe(fn) {
	try {
		return fn();
	} catch (e) {
		return undefined;
	}
}

/**
 * Check if string is numeric
 * @param n
 * @returns {boolean|boolean}
 */
export function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Format dates to APP Timber standard
 * @param date
 * @returns {string|null}
 */
export function formatDate (date, format='d/m/y') {
	if (!date) return null
	let [year, month, day] = date.split('-')
	if(format == 'd/m/y') {
		year = year.substring(2, 4)
		return `${day}/${month}/${year}`
	} else if(format == 'W'){
		const dateObj = new Date(date);
		return dateObj.getWeek()
	} else {
		// year = year.substring(2,4)
		return `${day}/${month}/${year}`
	}
}

/**
 * Format dates to APP Timber standard - year shortend
 * @param date
 * @returns {string|null}
 */
export function formatDateShort (date) {
	if (!date) return null
	const [year, month, day] = date.split('-')
	return `${day}/${month}/${year.substring(2,4)}`
}

/**
 * Convert string to dot notation for object reference
 * @param obj
 * @param is
 * @param value
 * @returns {*}
 */
function index(obj,is, value) {
	if (typeof is == 'string')
		return index(obj,is.split('.'), value);
	else if (is.length == 1 && value !== undefined)
		return obj[is[0]] = value;
	else if (is.length == 0)
		return obj;
	else
		return index(obj[is[0]],is.slice(1), value);
}

/**
 *  Add a getWeek() method in Javascript inbuilt Date object.
 * This function is the closest I could find which is ISO-8601 compatible. This is what php's `Date->format('w')` uses.
 * ISO-8601 means.
 *    Week starts from Monday.
 *    Week 1 is the week with first Thursday of the year or the week which has 4th jan in it.
 * @param  {[Date]}   Prototype binding with Date Object.
 * @return {[Int]}    Integer from 1 - 53 which denotes the week of the year.
 */

Date.prototype.getWeek = function() {

	// Create a copy of this date object
	var target = new Date(this.valueOf());

	// ISO week date weeks start on monday, so correct the day number
	var dayNr = (this.getDay() + 6) % 7;

	// Set the target to the thursday of this week so the
	// target date is in the right year
	target.setDate(target.getDate() - dayNr + 3);

	// ISO 8601 states that week 1 is the week with january 4th in it
	var jan4 = new Date(target.getFullYear(), 0, 4);

	// Number of days between target date and january 4th
	var dayDiff = (target - jan4) / 86400000;

	if(new Date(target.getFullYear(), 0, 1).getDay() < 5) {
		// Calculate week number: Week 1 (january 4th) plus the
		// number of weeks between target date and january 4th
		return 1 + Math.ceil(dayDiff / 7);
	}
	else {  // jan 4th is on the next week (so next week is week 1)
		return Math.ceil(dayDiff / 7);
	}
};

/**
 * Get the date and days within a week from week number.
 * eg: date range for 8th week in 2013 is 17th Feb to 23rd Feb. This
 * code snippet will give you.
 *
 * It is not my code completely, Bit of modification from something
 * i found on net. Cant find it anymore so keeping a backup.
 *
 * @param  {[Integer]} weekNo [From week 1 to Week 52/53 based on the system date setting]
 * @return {[Date]}        [description]
 */
export function getDateRangeOfWeek(weekNo) {
	let d1 = new Date();
	let numOfDaysPastSinceLastMonday = eval(d1.getDay() - 1);
	d1.setDate(d1.getDate() - numOfDaysPastSinceLastMonday);
	// let weekNoToday = d1.getWeek();

	let weekNoToday = getCurrentWeek();

	let weeksInTheFuture = eval( weekNo - weekNoToday );
	d1.setDate(d1.getDate() + eval( 7 * weeksInTheFuture ));
	let rangeIsFrom = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();
	d1.setDate(d1.getDate() + 6);
	let rangeIsTo = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
	return rangeIsFrom + " to " + rangeIsTo;
}

/**
 * Get the date and days within a week from week number.
 * eg: date range for 8th week in 2013 is 17th Feb to 23rd Feb. This
 * code snippet will give you.
 *
 * It is not my code completely, Bit of modification from something
 * i found on net. Cant find it anymore so keeping a backup.
 *
 * @param  {[Integer]} weekNo [From week 1 to Week 52/53 based on the system date setting]
 * @param  {[Integer]} yearNo
 * @return {[Date]}        [description]
 */
export function getDateRangeOfWeekWithYear(weekNo, yearNo) {
	let d1 = new Date();
	let numOfDaysPastSinceLastMonday = eval(d1.getDay() - 1);
	d1.setDate(d1.getDate() - numOfDaysPastSinceLastMonday);
	// let weekNoToday = d1.getWeek();

	let weekNoToday = getCurrentWeek();

	let weeksInTheFuture = 0;
	if(yearNo >= d1.getFullYear()) {
		weeksInTheFuture = eval(weekNo - weekNoToday + (yearNo - d1.getFullYear()) * 52);
	} else {
		weeksInTheFuture = eval(weekNo - weekNoToday + ((d1.getFullYear() - yearNo) * 52));
	}
	// log(weeksInTheFuture)
	d1.setDate(d1.getDate() + eval( 7 * weeksInTheFuture ));
	let rangeIsFrom = eval(d1.getMonth() + 1) +"/" + d1.getDate() + "/" + d1.getFullYear();
	d1.setDate(d1.getDate() + 6);
	let rangeIsTo = eval(d1.getMonth() + 1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
	return rangeIsFrom + " to " + rangeIsTo;
}

/**
 *
 * @param  {[Integer]} weekNo [From week 1 to Week 52/53 based on the system date setting]
 * @return {[Date]}        [description]
 */
export function getFirstDateOfWeek(weekNo) {
	let d = new Date()
	let numOfDaysPastSinceLastMonday = eval(d.getDay()- 1);
	d.setDate(d.getDate() - numOfDaysPastSinceLastMonday);
	let weekNoToday = d.getWeek();
	let weeksInTheFuture = eval( weekNo - weekNoToday );
	d.setDate(d.getDate() + eval( 7 * weeksInTheFuture ));
	return d.toISOString().substr(0, 10);
}

/**
 *
 * @param  {[Integer]} weekNo [From week 1 to Week 52/53 based on the system date setting]
 * @return {[Date]}        [description]
 */
export function getLastDateOfWeek(weekNo) {
	let d = new Date();
	let numOfDaysPastSinceLastMonday = eval(d.getDay()- 1);
	d.setDate(d.getDate() - numOfDaysPastSinceLastMonday);
	let weekNoToday = d.getWeek();
	let weeksInTheFuture = eval( weekNo - weekNoToday );
	d.setDate(d.getDate() + eval( 7 * weeksInTheFuture ));
	d.setDate(d.getDate() + 6);
	return d.toISOString().substr(0, 10);
}

/**
 * Test if a number is even
 * @param n
 * @returns {boolean}
 */
export function isEven(n) {
	return n % 2 == 0
}

/**
 * Test if a number is odd
 * @param n
 * @returns {boolean}
 */
export function isOdd(n) {
	return Math.abs(n % 2) == 1
}

/**
 * Remove object from an array
 * @param arr
 * @param attr
 * @param value
 * @returns {*}
 */
export function removeByAttr(arr, model, attr, value){
	let i = arr.length;
	while(i--){
		if( arr[i] && arr[i][model].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][model][attr] === value ) ){
			arr.splice(i,1);
		}
	}
	return arr;
}

/**
 * Check if variable is an object
 * @param variable
 * @returns {boolean}
 */
export function isObject (variable) {
	return Object.prototype.toString.call(variable) === '[object Object]'
}

/**
 * Capitalize first letter of any word
 * @param word
 * @returns {string}
 */
export function capitalize (word) {
	return word[0].toUpperCase() + word.substring(1);
}

export const TYPE_KEY = Symbol('resourceType')

/**
 * generate a dimension string
 * @param dimThickness
 * @param dimWidth
 * @param dimLength
 * @returns {string}
 */
export function generateDimension(dimThickness = null ,dimWidth = null ,dimLength = null, unit = 'mm') {
	let dim = []
	if(dimThickness != null && dimThickness.length > 0) {
		dimThickness = dimThickness.toString()
		dimThickness = dimThickness.trim()
		dim.push(dimThickness)
	}
	if(dimWidth != null && dimWidth.length > 0) {
		dimWidth = dimWidth.toString()
		dimWidth = dimWidth.trim()
		dim.push(dimWidth)
	}
	if(dimLength != null && dimLength.length > 0) {
		dimLength = dimLength.toString()
		dimLength = dimLength.trim()
		dim.push(dimLength)
	}
	if(dimWidth == null && dimLength == null) {
		dim.push(unit)
		return dim.join('')
	}
	return dim.join(' x ')
}

/**
 * generate specification text
 * @param product
 * @param spec
 * @param mc
 * @returns {string}
 */
export function generateSpec(product, spec, mc) {
	let description = []
	if(product != null && product.length > 0) description.push(product)
	if(spec != null && spec.length > 0) description.push(spec)
	if(mc != null && mc.length > 0) description.push(mc)
	return description.join(', ')
}

/**
 * get current week
 * @returns {*|number}
 */
export function getCurrentWeekOld() {
	const currentDate = new Date();
	const weekNumber = currentDate.getWeek()
	console.log(weekNumber)
	return weekNumber
}

/**
 * Get current week V2
 * @param date
 * @param dowOffset
 * @returns {number|number}
 */
export function getCurrentWeekV2Old() {
	const date = new Date()
	const dowOffset = 4
	/*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */
	const newYear = new Date(date.getFullYear(), 0, 1);
	console.log(newYear.getDay())
	let day = newYear.getDay() - dowOffset; //the day of week the year begins on
	day = (day >= 0 ? day : day + 7);
	const dayNum = Math.floor((date.getTime() - newYear.getTime() -
		(date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
	//if the year starts before the middle of a week
	if (day < 4) {
		const weekNum = Math.floor((dayNum + day - 1) / 7) + 1;
		if (weekNum > 52) {
			const nYear = new Date(date.getFullYear() + 1, 0, 1);
			let nDay = nYear.getDay() - dowOffset;
			nDay = nDay >= 0 ? nDay : nDay + 7;
			/*if the next year starts before the middle of
			  the week, it is week #1 of that year*/
			return nDay < 4 ? 1 : 53;
		}
		console.log(weekNum)
		return weekNum;
	}
	else {
		console.log(Math.floor((dayNum + day - 1) / 7))
		return Math.floor((dayNum + day - 1) / 7);
	}
}

export function getCurrentWeek() {
	dayjs.extend(isoWeek)
	return dayjs().isoWeek()
}


/**
 * Shift array elements left/right by N positions
 * @param arr
 * @param direction
 * @param n
 * @returns {*}
 */
export function shift(arr, direction, n) {
	for (let i = n; i > 0; --i) { (direction > 0 ? arr.unshift(arr.pop()) : arr.push(arr.shift())); }
	return arr;
}

/**
 * get current year
 * @returns {number}
 */
export function getCurrentYear() {
	const currentDate = new Date();
	const yearNumber = currentDate.getFullYear()
	return yearNumber
}

/**
 * get next year
 * @returns {number}
 */
export function getNextYear() {
	const currentDate = new Date();
	const yearNumber = currentDate.getFullYear()
	return yearNumber + 1
}

/**
 * Check if object is empty
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}

/**
 * Scroll to first validation error
 */
export function scrollToFirstFormValidationError() {
	setTimeout(function(){
		let nodes = document.querySelectorAll('form div.v-input')
		const element = document.querySelector(".v-messages.error--text:first-of-type")
		const parentElement = element.closest('.v-input')
		let index = [...nodes].indexOf(parentElement)
		let scrollToIndex = index - 1
		if(scrollToIndex < 0) scrollToIndex = 0
		let scrollToElement = document.querySelector("form div.v-input:nth-child("+ scrollToIndex +")")
		if(!Object.is(scrollToElement, null)){
			scrollToElement.scrollIntoView({behavior: "smooth"})
		} else {
			//this is the fallback
			scrollToElement = document.querySelector("form div.v-input:nth-child("+ index +")")
			if(!Object.is(scrollToElement, null)) {
				scrollToElement.scrollIntoView({behavior: "smooth"})
			}
		}
	}, 250)
}

/**
 * Equivalent to array_column in PHP
 * @param array
 * @param columnName
 * @returns {*}
 */
export function arrayColumn (input, ColumnKey, IndexKey = null) {
	if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
		const newArray = []
		if (typeof input === 'object') {
			const tempArray = []
			for (const key of Object.keys(input)) {
				tempArray.push(input[key])
			}
			input = tempArray
		}
		if (Array.isArray(input)) {
			for (const key of input.keys()) {
				if (IndexKey && input[key][IndexKey]) {
					if (ColumnKey) {
						newArray[input[key][IndexKey]] = input[key][ColumnKey]
					} else {
						newArray[input[key][IndexKey]] = input[key]
					}
				} else {
					if (ColumnKey) {
						newArray.push(input[key][ColumnKey])
					} else {
						newArray.push(input[key])
					}
				}
			}
		}
		return Object.assign({}, newArray)
	}
}

export function log (arg){
	console.log(arg)
}

export function warn (arg){
	console.warn(arg)
}

export function info (arg){
	console.info(arg)
}

export function error (arg){
	console.error(arg)
}

/**
 *
 * @param numerator
 * @param denominator
 * @param decimals
 * @returns {float}
 */
export function getPercent (numerator, denominator = 1, decimals = 0) {
	let percent = 0
	if(denominator > 0) {
		percent = (numerator / denominator) * 100
	}
	return percent
}

/**
 * Checks to see if a value is set.
 *
 * @param   {Function} accessor Function that returns our value
 * @returns {Boolean}           Value is not undefined or null
 */
export function isset (accessor) {
	try {
		// Note we're seeing if the returned value of our function is not
		// undefined or null
		return accessor() !== undefined && accessor() !== null
	} catch (e) {
		// And we're able to catch the Error it would normally throw for
		// referencing a property of undefined
		return false
	}
}

/**
 * get year component
 * @param dateString
 * @returns {number}
 */
export function getYear (dateString) {
	let date = new Date(dateString)
	return date.getFullYear()
}

/**
 * generate an array of years
 * @returns {*[]}
 */
export function generateArrayOfYears(min = null, max = null) {
	if(max == null) max = new Date().getFullYear()
	if(min == null) min = max - 5
	let years = []
	for (let i = max; i >= min; i--) {
		years.push(i)
	}
	return years
}

/**
 * generate random ID
 * @param length
 * @returns {string}
 */
export function makeId(length) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

/**
 * Check if year has 53 weeks instead of 52
 * @param year
 * @returns {boolean}
 */
export function yearHasExtraWeekOld(yearToCheck) {
	let p = (year) => {
		return (year + Math.floor(year/4) - Math.floor(year/100) + Math.floor(year/400))%7
	}
	let weeks = (year) => {
		let w = 52
		if(p(yearToCheck) == 4 || p(yearToCheck - 1)){
			w++
		}
		return w
	}
	// console.log(weeks(yearToCheck))
	if(weeks(yearToCheck) == 53) return true
	return false
}

/**
 * Check if year has 53 weeks instead of 52
 * @param year
 * @returns {boolean}
 */
export function yearHasExtraWeek(yearToCheck) {
	dayjs.extend(isoWeeksInYear)
	dayjs.extend(isLeapYear)

	if(dayjs().isoWeeksInYear() == 53) return true
	return false
}

/**
 * Sort array according to predefined order
 * @param array
 * @param myorder
 * @param key
 * @returns {*}
 */
export function mapOrder (array, myOrder, key) {
	let order = myOrder.reduce((r, k, i) => (r[k] = i + 1, r), {})
	const theSort = array.sort((a, b) => {
		if(key.indexOf('.' >= 0)){
			const keys = key.split('.')
			if(keys.length == 2){
				return (order[a[keys[0]][keys[1]]] || Infinity) - (order[b[keys[0]][keys[1]]] || Infinity)
			} else if(keys.length == 3){
				return (order[a[keys[0]][keys[1]][keys[2]]] || Infinity) - (order[b[keys[0]][keys[1]][keys[2]]] || Infinity)
			}
		} else {
			return (order[a[key]] || Infinity) - (order[b[key]] || Infinity)
		}
	})
	return theSort
}

/**
 * Get current quarter
 * @param d
 * @returns {number}
 */
export function getCurrentQuarter() {
	const now = new Date()
	const currentMonth = now.getMonth() + 1
	const currentQuarter = {
		1: 1, // January
		2: 1, // February
		3: 1, // March

		4: 2, // April
		5: 2, // May
		6: 2, // June

		7: 3, // July
		8: 3, // August
		9: 3, // September

		10: 4, // October
		11: 4, // November
		12: 4, // December
	}[currentMonth]
	return currentQuarter
}

/**
 *
 * @param dirty
 * @returns string
 */
export function purify(dirty) {
	return DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } })
}