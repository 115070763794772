/**
 * App Entry File
 * Vuely: A Powerfull Material Design Admin Template
 * Copyright 2018. All Rights Reserved
 * Created By: The Iron Network, LLC
 * Made with Love
 */
import 'babel-polyfill';
import Vue from 'vue'
import Vuetify from 'vuetify'
// import * as VueGoogleMaps from 'vue2-google-maps'
// import { Vue2Dragula } from 'vue2-dragula'
import VueQuillEditor from 'vue-quill-editor'
import wysiwyg from 'vue-wysiwyg'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
// import AmCharts from 'amcharts3'
// import AmSerial from 'amcharts3/amcharts/serial'
// import AmAngularGauge from 'amcharts3/amcharts/gauge'
import Nprogress from 'nprogress'
import VueI18n from 'vue-i18n'
import VueTour from 'vue-tour'
import fullscreen from 'vue-fullscreen'
import InstantSearch from 'vue-instantsearch'
import VueVideoPlayer from 'vue-video-player';
import Croppa from 'vue-croppa';
import VueScrollTo from 'vue-scrollto';
import 'vuetify/dist/vuetify.min.css'
import VueCloneya from 'vue-cloneya'
import MultiFiltersPlugin from './plugins/MultiFilters'
import vueDebounce from 'vue-debounce'
import VuetifyToast from "vuetify-toast-snackbar";
import AsyncComputed from "vue-async-computed";

// global components
import GlobalComponents from './globalComponents'

// app.vue
import App from './App'

// router
import router from './router'

// themes
import primaryTheme from './themes/primaryTheme';

// casl
import { abilitiesPlugin } from "@casl/vue";

// store
import { store } from './store/store';

// firebase
import './firebase'

// include script file
import './lib/VuelyScript'

// include all css files
import './lib/VuelyCss'

// messages
import messages from './lang';

// fontawesome
import '@fortawesome/fontawesome-free/css/all.css';

// api
import { api } from "./api";

// title mixin
// import mixinTitle from './mixins/title';
import VuePageTitle from "vue-page-title";

import JsonExcel from "vue-json-excel";

//TODO add PWA to the app to facilitate updates
//import './registerServiceWorker'

Nprogress.configure({ showSpinner: false })

const waitForStorageToBeReady = async (to, from, next) => {
	await store.restored
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		Nprogress.start()
		if (localStorage.getItem('user') === null) {
			next({
				path: '/session/login',
				query: {redirect: to.fullPath}
			})
		} else {
			//check if refresh token is valid. if not then log out and redirect to login page
			let user = JSON.parse(localStorage.getItem('user'))
			let token = new Promise((resolve, reject) => {
				api
					.get("/refresh-token/" + user.refreshToken)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error);
					});
			})
			token
				.then((token)=>{
					// check table updates
					let tableUpdates = token.tableUpdates
					if(tableUpdates) {
						const currentTableUpdates = JSON.parse(localStorage.getItem('tableUpdates'))
						const currentBankAccounts = currentTableUpdates?.bankaccounts
						const currentAgents = currentTableUpdates?.agents
						const currentBuyers = currentTableUpdates?.customers
						// let currentCertifications = currentTableUpdates?.certifications
						const currentCodeVersion = currentTableUpdates?.code_version
						const currentConstants = currentTableUpdates?.constants
						const currentContracts = currentTableUpdates?.contracts
						const currentCountries = currentTableUpdates?.countries
						const currentDoctypes = currentTableUpdates?.doctypes
						const currentGrades = currentTableUpdates?.grades
						const currentInvoices = currentTableUpdates?.invoices
						const currentIpas = currentTableUpdates?.ipas
						const currentLcas = currentTableUpdates?.lcas
						let currentMoistureContent = currentTableUpdates?.mcs
						const currentPaymentstatements = currentTableUpdates?.paymentstatements
						const currentPackinglists = currentTableUpdates?.pldos
						const currentProductGroups = currentTableUpdates?.productgroups
						const currentPurchaseorders = currentTableUpdates?.purchaseorders
						const currentSdas = currentTableUpdates?.sdas
						const currentShippingports = currentTableUpdates?.shippingports
						const currentSizes = currentTableUpdates?.sizes
						const currentSpecs = currentTableUpdates?.specs
						const currentSpecies = currentTableUpdates?.species
						const currentSuppliers = currentTableUpdates?.suppliers
						const currentThickness = currentTableUpdates?.thicknesses
						const currentTtas = currentTableUpdates?.ttas
						const currentWprs = currentTableUpdates?.wprs
						const currentStandardAssetDocument = currentTableUpdates?.standard_asset_documents

						// check agents
						if (currentAgents != tableUpdates?.agents) {
							store.commit('agent/resetState')
							store.dispatch('agent/getAllAgents')
						}
						// check bankaccounts
						if (currentBankAccounts != tableUpdates?.bankaccounts) {
							store.commit('bankaccount/resetState')
							store.dispatch('bankaccount/getAllBankAccounts')
							store.dispatch('bankaccount/getAllSupplierBankAccounts')
						}
						// check buyers
						if (currentBuyers != tableUpdates?.customers) {
							store.commit('buyer/resetState')
							store.dispatch('buyer/getAllActiveBuyers')
						}
						//check countries
						if (currentCountries != tableUpdates?.countries) {
							store.commit('country/resetState')
							store.dispatch('country/getAllCountries')
						}
						// check constants
						if (currentConstants != tableUpdates?.constants) {
							store.commit('resetConstantsState')
						}
						//check contracts
						if (currentContracts != tableUpdates?.contracts) {
							store.commit('contract/resetState')
							store.dispatch('contract/getAllActiveContracts')
						}
						// check doctypes
						if (currentDoctypes != tableUpdates?.doctypes) {
							store.commit('document/resetState')
						}
						//check grades
						if(currentGrades != tableUpdates?.grades){
							store.commit('grade/resetState')
							store.dispatch('grade/getAllGrades')
						}
						//check invoices
						if(currentInvoices != tableUpdates?.invoices){
							store.commit('invoice/resetState')
							store.dispatch('invoice/getAllActiveInvoices')
						}
						//check ipas
						if(currentIpas != tableUpdates?.ipas){
							store.commit('ipa/resetState')
							store.dispatch('ipa/getAllActiveIpas')
						}
						//check lcas
						if(currentLcas != tableUpdates?.lcas){
							store.commit('lca/resetState')
							store.dispatch('lca/getAllActiveLcas')
						}
						//check mcs
						if(currentMoistureContent != tableUpdates?.mcs){
							store.commit('mc/resetState')
							store.dispatch('mc/getAllMoistureContent')
						}
						//check paymentstatements
						if(currentPaymentstatements != tableUpdates?.paymentstatements){
							store.commit('paymentterm/resetState')
							store.dispatch('paymentterm/getAllPaymentTerms')
						}
						//check packing lists
						if (currentPackinglists != tableUpdates?.pldos) {
							store.commit('packinglist/resetState')
							store.dispatch('packinglist/getAllActivePackingLists')
						}
						//check product groups
						if (currentProductGroups != tableUpdates?.productgroups) {
							store.commit('productgroup/resetState')
							store.dispatch('productgroup/getAllProductGroups')
						}
						//check purchaseorders
						if(currentPurchaseorders != tableUpdates?.purchaseorders){
							store.commit('purchaseorder/resetState')
							store.dispatch('purchaseorder/getAllActivePurchaseOrders')
						}
						//check sdas
						if(currentSdas != tableUpdates?.sdas){
							store.commit('sda/resetState')
							store.dispatch('sda/getAllShippingPorts')
						}
						//check shipping ports
						if(currentShippingports != tableUpdates?.shippingports){
							store.commit('shippingport/resetState')
							store.dispatch('shippingport/getAllShippingPorts')
						}
						//check species
						if(currentSpecies != tableUpdates?.species){
							store.commit('species/resetState')
							store.dispatch('species/getAllSpecies')
						}
						//check sizes
						if(currentSizes != tableUpdates?.sizes){
							store.commit('size/resetState')
							store.dispatch('size/getAllSizes')
						}
						//check specs
						if(currentSpecs != tableUpdates?.specs){
							store.commit('spec/resetState')
							store.dispatch('spec/getAllSpecs')
						}
						//check suppliers
						if (currentSuppliers != tableUpdates?.suppliers) {
							store.commit('supplier/resetState')
							store.dispatch('supplier/getAllSuppliers')
						}
						// check thicknesses
						if (currentThickness != tableUpdates?.thicknesses) {
							store.commit('thickness/resetState')
							store.dispatch('thickness/getAllThickness')
						}
						//check cover notes/ttas
						if (currentTtas != tableUpdates?.ttas) {
							store.commit('covernote/resetState')
							store.dispatch('covernote/getAllCoverNotes')
						}
						//check warehouse purchase requests/wprs
						if (currentWprs != tableUpdates?.wprs) {
							store.commit('wpr/resetState')
							store.dispatch('wpr/getAllActiveWprs')
						}
						//check code version
						if(currentCodeVersion != tableUpdates?.code_version){
							store.commit('updatePackageVersion')
						}
						if(currentStandardAssetDocument != tableUpdates?.standard_asset_documents){
							store.commit('document/resetStandardShipmentDocumentState')
							store.dispatch('document/getAllStandardShippingDocuments')
						}
						//update localstorage
						localStorage.setItem('tableUpdates', JSON.stringify(tableUpdates))
					}
					next()
				})
				.catch(()=>{
					// if(localStorage.getItem('concurrentLogin') === null) {
						localStorage.setItem('sessionExpired', 'true');
					// }
					localStorage.setItem('lastRoute',to.path);
					store.dispatch("logoutUserFromFirebase" );
					next({
						path: '/session/login',
						query: {redirect: to.fullPath}
					})
				})
		}
	} else if (to.matched.some(record => record.meta.requiresToken)) {
		let user = JSON.parse(localStorage.getItem('user'))
		next()
	} else {
		Nprogress.start()
		next() // make sure to always call next()!
	}
}

router.beforeEach(waitForStorageToBeReady)

// navigation guards before each
// router.beforeEach((to, from, next) => {
// 	// Nprogress.start()
// 	if (to.matched.some(record => record.meta.requiresAuth)) {
// 		// this route requires auth, check if logged in
// 		// if not, redirect to login page.
// 		Nprogress.start()
// 		if (localStorage.getItem('user') === null) {
// 			next({
// 				path: '/session/login',
// 				query: {redirect: to.fullPath}
// 			})
// 		} else {
// 			//check if refresh token is valid. if not then log out and redirect to login page
// 			let user = JSON.parse(localStorage.getItem('user'))
// 			let token = new Promise((resolve, reject) => {
// 				api
// 					.get("/refresh-token/" + user.refreshToken)
// 					.then(response => {
// 						resolve(response.data)
// 					})
// 					.catch(error => {
// 						reject(error);
// 					});
// 			})
// 			token.then((token)=>{
// 				// check table updates
// 				let tableUpdates = token.tableUpdates
// 				if(tableUpdates) {
// 					let currentTableUpdates = JSON.parse(localStorage.getItem('tableUpdates'))
// 					// console.log(currentTableUpdates)
// 					// check contracts
// 					let currentBankAccounts = currentTableUpdates?.bankaccounts
// 					let currentBuyers = currentTableUpdates?.customers
// 					let currentConstants = currentTableUpdates?.constants
// 					let currentContracts = currentTableUpdates?.contracts
// 					let currentDoctypes = currentTableUpdates?.doctypes
// 					let currentGrades = currentTableUpdates?.grades
// 					let currentInvoices = currentTableUpdates?.invoices
// 					let currentIpas = currentTableUpdates?.ipas
// 					let currentLcas = currentTableUpdates?.lcas
// 					let currentPaymentstatements = currentTableUpdates?.paymentstatements
// 					let currentPackinglists = currentTableUpdates?.pldos
// 					let currentPurchaseorders = currentTableUpdates?.purchaseorders
// 					let currentSdas = currentTableUpdates?.sdas
// 					let currentShippingports = currentTableUpdates?.shippingports
// 					let currentSizes = currentTableUpdates?.sizes
// 					let currentSpecs = currentTableUpdates?.specs
// 					let currentSpecies = currentTableUpdates?.species
// 					let currentSuppliers = currentTableUpdates?.suppliers
// 					let currentTtas = currentTableUpdates?.ttas
// 					let currentWprs = currentTableUpdates?.wprs
//
// 					// check bankaccounts
// 					if (currentBankAccounts != tableUpdates?.bankaccounts) {
// 						store.commit('bankaccount/resetState')
// 						store.dispatch('bankaccount/getAllBankAccounts')
// 						store.dispatch('bankaccount/getAllSupplierBankAccounts')
// 					}
//
// 					// check buyers
// 					if (currentBuyers != tableUpdates?.customers) {
// 						store.commit('buyer/resetState')
// 						store.dispatch('buyer/getAllActiveBuyers')
// 					}
//
// 					// check constants
// 					if (currentConstants != tableUpdates?.constants) {
// 						store.commit('resetConstantsState')
// 					}
//
// 					// check doctypes
// 					if (currentDoctypes != tableUpdates?.doctypes) {
// 						store.commit('document/resetState')
// 					}
//
// 					//check contracts
// 					if (currentContracts != tableUpdates?.contracts) {
// 						store.commit('contract/resetState')
// 						store.dispatch('contract/getAllActiveContracts')
// 					}
// 					//check grades
// 					if(currentGrades != tableUpdates?.grades){
// 						store.commit('grade/resetState')
// 						store.dispatch('grade/getAllGrades')
// 					}
// 					//check invoices
// 					if(currentInvoices != tableUpdates?.invoices){
// 						store.commit('invoice/resetState')
// 						store.dispatch('invoice/getAllActiveInvoices')
// 					}
// 					//check ipas
// 					if(currentIpas != tableUpdates?.ipas){
// 						store.commit('ipa/resetState')
// 						store.dispatch('ipa/getAllActiveIpas')
// 					}
// 					//check lcas
// 					if(currentLcas != tableUpdates?.lcas){
// 						store.commit('lca/resetState')
// 						store.dispatch('lca/getAllActiveLcas')
// 					}
// 					//check paymentstatements
// 					if(currentPaymentstatements != tableUpdates?.paymentstatements){
// 						store.commit('paymentterm/resetState')
// 						store.dispatch('paymentterm/getAllPaymentTerms')
// 					}
// 					//check packing lists
// 					if (currentPackinglists != tableUpdates?.pldos) {
// 						store.commit('packinglist/resetState')
// 						store.dispatch('packinglist/getAllActivePackingLists')
// 					}
// 					//check purchaseorders
// 					if(currentPurchaseorders != tableUpdates?.purchaseorders){
// 						store.commit('purchaseorder/resetState')
// 						store.dispatch('purchaseorder/getAllActivePurchaseOrders')
// 					}
// 					//check sdas
// 					if(currentSdas != tableUpdates?.sdas){
// 						store.commit('sda/resetState')
// 						store.dispatch('sda/getAllShippingPorts')
// 					}
// 					//check shipping ports
// 					if(currentShippingports != tableUpdates?.shippingports){
// 						store.commit('shippingport/resetState')
// 						store.dispatch('shippingport/getAllShippingPorts')
// 					}
// 					//check species
// 					if(currentSpecies != tableUpdates?.species){
// 						store.commit('species/resetState')
// 						store.dispatch('species/getAllSpecies')
// 					}
// 					//check sizes
// 					if(currentSizes != tableUpdates?.sizes){
// 						store.commit('size/resetState')
// 						store.dispatch('size/getAllSizes')
// 					}
// 					//check specs
// 					if(currentSpecs != tableUpdates?.specs){
// 						store.commit('spec/resetState')
// 						store.dispatch('spec/getAllSpecs')
// 					}
// 					//check suppliers
// 					if (currentSuppliers != tableUpdates?.suppliers) {
// 						store.commit('supplier/resetState')
// 						store.dispatch('supplier/getAllSuppliers')
// 					}
// 					//check cover notes/ttas
// 					if (currentTtas != tableUpdates?.ttas) {
// 						store.commit('covernote/resetState')
// 						store.dispatch('covernote/getAllCoverNotes')
// 					}
// 					//check warehouse purchase requests/wprs
// 					if (currentWprs != tableUpdates?.wprs) {
// 						store.commit('wpr/resetState')
// 						store.dispatch('wpr/getAllActiveWprs')
// 					}
// 					//update localstorage
// 					localStorage.setItem('tableUpdates', JSON.stringify(tableUpdates))
// 				}
// 				// reset some store contstants
// 				// store.dispatch('resetConstantsState')
// 				next()
// 			}).catch(()=>{
// 				localStorage.setItem('sessionExpired','true');
// 				localStorage.setItem('lastRoute',to.path);
// 				store.dispatch("logoutUserFromFirebase" );
// 				next({
// 					path: '/session/login',
// 					query: {redirect: to.fullPath}
// 				})
// 			})
// 		}
// 	} else if (to.matched.some(record => record.meta.requiresToken)) {
// 		let user = JSON.parse(localStorage.getItem('user'))
// 		next()
// 	} else {
// 		Nprogress.start()
// 		next() // make sure to always call next()!
// 	}
// })

// navigation guard after each
router.afterEach((to, from) => {
	Nprogress.done()
	setTimeout(() => {
		const contentWrapper = document.querySelector(".v-content__wrap");
		if(contentWrapper !== null){
			contentWrapper.scrollTop = 0;
		}
		const boxedLayout = document.querySelector('.app-boxed-layout .app-content');
		if(boxedLayout !==  null){
			boxedLayout.scrollTop = 0;
		}
		const miniLayout = document.querySelector('.app-mini-layout .app-content');
		if(miniLayout !== null){
			miniLayout.scrollTop = 0;
		}
	}, 200);
})
const vuetifyOptions = {
    theme: {
		themes: {
            light: store.getters.selectedTheme.theme
        }
    }
}
// plugins
Vue.use(Vuetify);
Vue.use(InstantSearch);
Vue.use(VueI18n)
// Vue.use(AmCharts)
// Vue.use(AmSerial)
Vue.use(VueTour)
// Vue.use(AmAngularGauge)
// Vue.use(Vue2Dragula)
Vue.use(VueQuillEditor)
Vue.use(VueResource)
Vue.use(wysiwyg, {})
Vue.use(VueBreadcrumbs)
Vue.use(Notifications, { velocity })
Vue.use(fullscreen);
Vue.use(GlobalComponents);
Vue.use(VueVideoPlayer);
Vue.use(Croppa);

Vue.use(VueScrollTo);
Vue.use(VueCloneya);
Vue.use(MultiFiltersPlugin);
Vue.use(vueDebounce);
Vue.use(AsyncComputed);

// const ability = store.getters.ability
Vue.use(abilitiesPlugin, store.getters.ability)

// Vue.mixin(mixinTitle);
Vue.use(VuePageTitle);

Vue.component('downloadExcel',JsonExcel)

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: store.getters.selectedLocale.locale, // set locale
	messages, // set locale messages
})

/* eslint-disable no-new */
let vm = new Vue({
	store,
	i18n,
	icons: {
		iconfont: 'fa'
	},
	router,
	render: h => h(App),
	components: { App },
    vuetify: new Vuetify(vuetifyOptions),
}).$mount('#app')

export default vm.$vuetify

Vue.use(VuetifyToast, {
	$vuetify: vm.$vuetify,
	x: 'center',
	y: 'bottom'
})
