import Vue from 'vue'
import Router from 'vue-router'

//routes
import defaultRoutes from './appic';
import printRoutes from  './appic-print';

// session components
const Auth0CallBack = () => import('Components/Auth0Callback/Auth0Callback');
const ForgotPassword = () => import('Views/v1/session/ForgotPassword');
const LockScreen = () => import('Views/session/LockScreen');
const LoginAppic = () => import('Views/v1/session/LoginAppic');
const NotFound = () => import('Views/v1/pages/NotFound');
const ResetPassword = () => import('Views/session/ResetPassword');
const SignUpOne = () => import('Views/session/SignUpOne');
const NoAccess = () => import('Views/v1/pages/NoAccess');

//containers
import Full from 'Container/Full';

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		defaultRoutes,
		printRoutes,
		{
			path: '/callback',
			component: Auth0CallBack
		},
		{
			path: '/session/sign-up',
			component: SignUpOne,
			meta: {
				title: 'message.signUp',
				breadcrumb: null
			}
		},
		{
			path: '/session/login',
			component: LoginAppic,
			meta: {
				title: 'message.login',
				breadcrumb: null
			}
		},
		{
			path: '/session/forgot-password',
			component: ForgotPassword,
			meta: {
				title: 'message.forgotPassword',
				breadcrumb: null
			}
		},
		{
			path: '/v1/no-access',
			component: NoAccess,
			meta: {
				title: 'message.accessDenied',
				breadcrumb: null
			}
		},
		{
			path: "/",
			component: Full,
			meta: {
				requiresAuth: true
			},
			children: [
				{
					path: '/v1',
					component: NotFound,
					meta: {
						requiresAuth: true
					},
				},
				{
					path: '/v1/*',
					component: NotFound,
					meta: {
						requiresAuth: true
					},
				}
			]
		}
	]
})
