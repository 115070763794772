import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		allProductGroups: [],
		allParentProductGroups: [],
		ProductGroup: {
			id: null,
			fsc_product_id: null,
			hscode: null,
			parent_id: null,
			pefc_product_id: null,
			title: null
		},
		Parent: {
			id: null,
			title: null
		}
	}
}

const state = {
	allProductGroups: [],
	allParentProductGroups: [],
	current: {
		ProductGroup: {
			id: null,
			fsc_product_id: null,
			hscode: null,
			parent_id: null,
			pefc_product_id: null,
			title: null
		},
		Parent: {
			id: null,
			title: null
		}
	},
	update: {
		ProductGroup: {},
		Parent: {}
	}
}

const getters = {
	allParentProductGroups: state => {
		return state.allParentProductGroups;
	},
	allProductGroups: state => {
		return state.allProductGroups;
	},
	getField
}

const actions = {
	createProductGroup ({state, rootState}) {
		let productgroup = _.cloneDeep(state.current.ProductGroup)
		let parent = _.cloneDeep(state.current.Parent)

		return new Promise((resolve, reject) => {
			api
				.post('/product-groups',{
					productgroup: productgroup,
					parent: parent,
				})
				.then((response)=>{
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	deleteProductGroupById ({commit}, productgroup_id) {
		return new Promise((resolve, reject) => {
			api
				.delete('/product-groups/' + productgroup_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	getAllParentProductGroups({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/parents/product-groups")
				.then(response => {
					commit('setAllParentProductGroups',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllProductGroups ({commit}){
		return new Promise((resolve, reject) => {
			api
				.get("/product-groups")
				.then(response => {
					commit('setAllProductGroups',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getProductGroupToUpdateById( {commit}, productGroupId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/product-groups/' + productGroupId )
				.then(response => {
					commit('setProductGroupToUpdate', response.data.data[0])
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetAllParentProductGroups( {commit} ) {
		commit('resetAllParentProductGroups')
	},
	resetAllProductGroups( {commit} ) {
		commit('resetAllProductGroups')
	},
	resetProductGroupToUpdate( {commit} ) {
		commit('resetProductGroupToUpdate')
	},
	resetCurrentState ({commit}) {
		commit('resetCurrentState')
	},
	resetState ({commit}){
		commit('resetState')
	},
	updateProductGroup( {state} ) {
		let productgroup = _.cloneDeep(state.update.ProductGroup)
		let parent = _.cloneDeep(state.update.Parent)
		//remove unneeded properties

		return new Promise((resolve, reject) => {
			api
				.put("/product-groups/" + state.update.ProductGroup.id,{
					productgroup: productgroup,
					parent: parent
				})
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

const mutations = {
	setAllParentProductGroups(state,data){
		state.allParentProductGroups = data;
	},
	setAllProductGroups(state,data){
		state.allProductGroups = data;
	},
	setProductGroupToUpdate ( state, data ){
		state.update.ProductGroup = data.ProductGroup
		state.update.Parent = data.Parent
	},
	resetAllParentProductGroups(state) {
		state.allParentProductGroups = getDefaultState().allParentProductGroups
	},
	resetAllProductGroups(state){
		state.allProductGroups = getDefaultState().allProductGroups
	},
	resetProductGroupToUpdate (state) {
		state.update.ProductGroup = getDefaultState().ProductGroup
		state.update.Parent = getDefaultState().Parent
	},
	resetCurrentState (state) {
		state.current.ProductGroup = getDefaultState().ProductGroup
		state.current.ProductGroup = getDefaultState().Parent
	},
	resetState(state){
		state.allProductGroups = getDefaultState().allProductGroups
	},
	updateField
}

export const productgroup = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}