import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Clause: {},
		defaultGroups: [
			{value: 1, altValue: 'standard', text: 'message.sdaStandardDocuments'},
			{value: 2, altValue: 'specific', text: 'message.sdaCountryCustomerDocuments'},
			{value: 3, altValue: 'other', text: 'message.sdaOtherRequests'},
			{value: 4, altValue: 'presentation', text: 'message.sdaPresentationDocuments'}
		]
	}
}

const state = {
	defaultGroups: [
		{value: 1, altValue: 'standard', text: 'message.sdaStandardDocuments'},
		{value: 2, altValue: 'specific', text: 'message.sdaCountryCustomerDocuments'},
		{value: 3, altValue: 'other', text: 'message.sdaOtherRequests'},
		{value: 4, altValue: 'presentation', text: 'message.sdaPresentationDocuments'}
	],
	defaultClauses: {
		standard: [], //sdadoctype_id = 1
		specific: [], //sdadoctype_id = 2
		other: [], //sdadoctype_id = 3
		presentation: [], //sdadoctype_id = 4
	},
	current: {
		Clauses: {
			standard: [], //sdadoctype_id = 1
			specific: [], //sdadoctype_id = 2
			other: [], //sdadoctype_id = 3
			presentation: [], //sdadoctype_id = 4
		},
		Clause: {}
	},
	update: {
		Clauses: {
			standard: [], //sdadoctype_id = 1
			specific: [], //sdadoctype_id = 2
			other: [], //sdadoctype_id = 3
			presentation: [], //sdadoctype_id = 4
		},
		Clause: {}
	}
}

const getters = {
	getField
}

const mutations = {
	addClause (state, payload) {
		let clauseGroup = state.defaultGroups.find(group => group.value == payload.group).altValue
		let newClause = {
			Clause: {
				id: payload.id,
				sort_id: null,
				title: payload.value,
				sdadoctype_id: payload.group,
				sdadoctype: clauseGroup
			}
		}
		state[payload.prefix].Clauses[clauseGroup].push(newClause)
		let i = 0;
		state[payload.prefix].Clauses[clauseGroup].forEach(clause => {
			clause.index = i
			clause.Clause.sort_id = i + 1
			i++
		})
	},
	deleteClause (state, payload) {
		state[payload.prefix].Clauses[payload.group].splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].Clauses[payload.group].forEach(clause => {
			clause.index = i
			clause.Clause.sort_id = i + 1
			i++
		})
	},
	reCalculateListIndices (state, payload) {
		let i = 0;
		state[payload.prefix].Clauses[payload.group].forEach(clause => {
			clause.index = i
			clause.Clause.sort_id = i + 1
			i++
		})
	},
	resetCurrentState (state) {
		state.current.Clauses = {
			standard: [],
			specific: [],
			other: [],
			presentation: []
		}
	},
	resetDefaultClauses (state) {
		state.defaultClauses = {
			standard: [],
			specific: [],
			other: [],
			presentation: []
		}
	},
	resetDefaultGroups () {
		console.log('resetting')
		state.defaultGroups = getDefaultState().defaultGroups
	},
	setClausesToUpdate (state, data) {
		let clauses = data
		state.update.Clauses = {
			standard: clauses.standard != null ? clauses.standard : [],
			specific: clauses.specific != null ? clauses.specific : [],
			other: clauses.other != null ? clauses.other : [],
			presentation: clauses.presentation != null ? clauses.presentation : []
		}
	},
	setCurrentClauses (state, data) {
		let clauses = data
		state.current.Clauses = {
			standard: clauses.standard != null ? clauses.standard : [],
			specific: clauses.specific != null ? clauses.specific : [],
			other: clauses.other != null ? clauses.other : [],
			presentation: clauses.presentation != null ? clauses.presentation : []
		}
	},
	setDefaultClauses (state, data) {
		let clauses = data
		state.defaultClauses = {
			standard: clauses.standard != null ? clauses.standard : [],
			specific: clauses.specific != null ? clauses.specific : [],
			other: clauses.other != null ? clauses.other : [],
			presentation: clauses.presentation != null ? clauses.presentation : []
		}
	},
	updateField
}

const actions = {
	addClauseToList ({commit},payload) {
		commit('addClause', payload)
	},
	deleteClause ({commit},payload) {
		return new Promise((resolve, reject) => {
			let sda_id = payload.sda_id
			let clause_id = payload.clause_id
			api
				.delete('/sdas/' + sda_id + '/clauses/' + clause_id)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	deleteClauseFromList ({commit},payload) {
		commit('deleteClause', payload)
	},
	getClausesBySda ({commit},sda_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/sdadocuments/' + sda_id + '/clauses')
				.then(response => {
					commit('setClausesToUpdate',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(() => {
					resolve('done')
				})
		})
	},
	getCurrentClauses ({commit},contract_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/sdadocuments/default/' + contract_id)
				.then(response => {
					commit('setCurrentClauses',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(() => {
					resolve('done')
				})
		})
	},
	getDefaultClauses ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get('/sdadocuments/default')
				.then(response => {
					commit('setDefaultClauses',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(() => {
					resolve('done')
				})
		})
	},
	reCalculateListIndices ({commit},payload){
		commit('reCalculateListIndices',payload)
	},
	resetCurrentClauses ({commit}) {
		commit('resetCurrentState')
	},
	resetDefaultClauses ({commit}){
		commit('resetDefaultClauses')
	},
	resetDefaultGroups({commit}) {
		commit('resetDefaultGroups')
	}
}

export const sdaclause = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}