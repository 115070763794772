import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "../../../helpers/helpers";

const namespaced = {
    namespaced: true
}

const getOtherChargesRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Grade: {
            id: 39,
            title: 'Other Charges'
        },
        Lmeasurement: {
            id: null,
            title: ''
        },
        Smeasurement: {
            id: null,
            title: ''
        },
        Soldmeasurement: {
            id: null,
            title: ''
        },
        Sosalesdescription: {
            id: null,
            title: ''
        },
        Spec: {
            id: null,
            title: null,
        },
        Species: {
            id: null,
            title: ''
        },
        Stock: {
            bundleno: null,
            costamount: 0,
            costamount_converted: 0,
            costprice: 0,
            forwardingcharge: 0,
            fsc: null,
            fsc_description: null,
            grade_id: 39,
            id: null,
            invoiceamount: 0,
            invoiceamount_converted: 0,
            length: null,
            piece: 0,
            productgroup_id: null,
            sellingprice: 0,
            sellingprice_converted: 0,
            size: 'Other Charges',
            spec_id: null,
            species_id: null,
            thick: null,
            totalcost: 0,
            volume: 1,
            warehousecost:0,
            width: null
        },
        Stockorder: {
            id: null,
            currency_id: 1,
            currencyrate: 1,
            title: ''
        },
        Wmeasurement: {
            id: null,
            title: ''
        },
        Options: {
            grades: [],
            species: [],
            specs: []
        },
        type: 'othercharge'
    }
}

const getDefaultState = () => {
    return {

    }
}

const state = {
    current: {
        LocalSalesInvoiceItems: [],
        Salesitems: [],
        commissionByUnit: false,
        itemsCurrencyRate: 1,
        itemsTotalAmount: 0,
        itemsTotalAmountConverted: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalCostConverted: 0,
        itemsTotalMargin: 0,
        itemsTotalPieces: 0,
        itemsTotalQty: [],
        itemsTotalTax: 0,
        itemsTotalTaxConverted: 0,
        itemsGrandTotal: 0,
        itemsGrandTotalConverted: 0,
        gstpercent: 0,
        specificationNotes: ''
    },
    update: {
        LocalSalesInvoiceItems: [],
        itemsCurrencyRate: 1,
        gstpercent: 0,
        itemsGrandTotal: 0,
        itemsTotalAmount: 0,
        itemsTotalQty: [],
        itemsTotalTax: 0,
        itemsTotalPieces: 0
    }
}

const getters = {
    getField,
    itemsCurrencyRate: state => {return state.current.itemsCurrencyRate },
    itemsTotalAmount: state => { return state.current.itemsTotalAmount },
    itemsTotalAmountConverted: state => { return state.current.itemsTotalAmountConverted },
    itemsTotalCommission: state => { return state.current.itemsTotalCommission },
    itemsTotalCost: state => { return state.current.itemsTotalCost },
    itemsTotalCostConverted: state => { return state.current.itemsTotalCostConverted },
    itemsTotalMargin: state => { return state.current.itemsTotalMargin },
    itemsTotalMarginConverted: state => { return state.current.itemsTotalMarginConverted },
    itemsTotalQty: state => { return state.current.itemsTotalQty },
    itemsTotalTax: state => { return state.current.itemsTotalTax },
    itemsTotalTaxConverted: state => { return state.current.itemsTotalTaxConverted },
    itemsGrandTotal: state => { return state.current.itemsGrandTotal },
    itemsGrandTotalConverted: state => { return state.current.itemsGrandTotalConverted },
    commissionByUnit: state => {return state.current.commissionByUnit},
    specificationNotes: state => {return state.current.specificationNotes}
}

const mutations = {
    addOtherChargesItemRow (state, prefix) {
        let i = 0;
        let newItem = getOtherChargesRow()
        newItem.Stock.stock_id = null
        newItem['Options'] = {
            grades: [],
            species: [],
            specs: []
        }
        state[prefix].LocalSalesInvoiceItems.push(newItem)
        state[prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    deleteLocalSalesInvoiceItemRow (state, payload) {
        state[payload.prefix].LocalSalesInvoiceItems.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState () {
        console.log('Resetting invoice sales items...')
        state.current.LocalSalesInvoiceItems = []
    },
    setCurrentSalesItems (state, data) {
        state.current.Salesitems = data;
        let i = 0;
        state.current.Salesitems.forEach((item,index)=>{
            state.current.Salesitems[index]['index'] = i;
            state.current.Salesitems[index]['lineNumber'] = i + 1;
            state.current.Salesitems[index]['Options'] = {
                grades: [],
                species: [],
                specs: []
            };
            i++
        })
    },
    setCurrentLocalSalesInvoiceItemsFromSalesOrder ( state, data ) {
        state.current.LocalSalesInvoiceItems = data
        let i = 0;
        state.current.LocalSalesInvoiceItems.forEach((item) => {
            item.Stock.salesdescription_id = item.Stock.sosalesdescription_id
            item.Stock.id = item.Stock.stock_id
            item.index = i
            item.lineNumber = i + 1
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            }
            i++
        })
    },
    setCurrentLocalSalesInvoiceItems (state, data) {
        state.current.LocalSalesInvoiceItems = data
        let i = 0
        state.current.LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            }
            i++
        })
    },
    setLocalSalesInvoiceItems ( state, payload ) {
        state[payload.prefix].LocalSalesInvoiceItems = payload.data
        let i = 0
        state[payload.prefix].LocalSalesInvoiceItems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            item['Options'] = {
                grades: [],
                species: [],
                specs: []
            }
            i++
        })
    },
    updateField,
    updateLocalSalesInvoiceItems (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        state[prefix].LocalSalesInvoiceItems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['costprice','forwardingcharge','invoiceamount','sellingprice','warehousecost']
        if(keys[0] == 'Stock' && numberFields.indexOf(keys[1]) !== -1){
            if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'default') {
                //update amount
                let amount = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice'])
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['amount'] = amount

                //update cost
                let cost = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['costprice']) + parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['forwardingcharge']) + parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['warehousecost'])
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['totalcost'] = cost

                //update costamount
                let costamount = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['totalcost'])
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['costamount'] = costamount
            }
            if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'othercharge'){
                let amount = 1 * parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice'])
                state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['amount'] = amount
            }
            //update converted fields
            // if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'default') {
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice']) * state[prefix].itemsCurrencyRate
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['volume']) * (parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['sellingprice']) * state[prefix].itemsCurrencyRate)
            // } else if(state[prefix].LocalSalesInvoiceItems[payload['index']]['type'] == 'othercharge') {
            //    state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invprice_converted'] = 0;
            //     state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount_converted'] = parseFloat(state[prefix].LocalSalesInvoiceItems[payload['index']]['Stock']['invoiceamount']) * state[prefix].itemsCurrencyRate
            // }
        }
    },
    updateTotals ( state, prefix ) {
        let amount = 0, amountConverted = 0, costamount = 0, costamountConverted = 0, tax = 0, taxConverted = 0, grandTotal = 0, grandTotalConverted = 0, quantities = [], pieces = 0
        state[prefix].LocalSalesInvoiceItems.forEach((item) => {
            if(item.type == 'credit') {
                amount -= isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0;
                // amountConverted -= isNumeric(item.Stock.invoiceamount_converted) ? parseFloat(item.Stock.invoiceamount_converted) : 0;
                costamount += isNumeric(item.Stock.costamount) ? parseFloat(item.Stock.costamount) : 0;
            } else {
                amount += isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0;
                // amountConverted += isNumeric(item.Stock.invoiceamount_converted) ? parseFloat(item.Stock.invoiceamount_converted) : 0;
                costamount += isNumeric(item.Stock.costamount) ? parseFloat(item.Stock.costamount) : 0;
            }
            if(item.type == 'default'){
                pieces += isNumeric(item.Stock.piece) ? parseInt(item.Stock.piece) : 0;
            }
            if(item.type == 'default') {
                let idx = quantities.findIndex(t => t.Total.uofm == item.Soldmeasurement.title);
                if (idx !== -1) {
                    quantities[idx]['Total']['qty'] += isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                } else {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Soldmeasurement.title;
                    obj['Total']['qty'] = isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                    quantities.push(obj)
                }
            }
        })
        state[prefix].itemsTotalAmount = amount
        // state[prefix].itemsTotalAmountConverted = amountConverted
        tax = amount * state[prefix].gstpercent/100
        // taxConverted = amountConverted * state[prefix].gstpercent/100
        state[prefix].itemsTotalTax = tax
        // state[prefix].itemsTotalTaxConverted = taxConverted
        grandTotal = amount + tax
        // grandTotalConverted = amountConverted + taxConverted
        state[prefix].itemsGrandTotal = grandTotal
        // state[prefix].itemsGrandTotalConverted = grandTotalConverted
        state[prefix].itemsTotalQty = quantities
        state[prefix].itemsTotalCost = costamount
        // state[prefix].itemsTotalCostConverted = costamountConverted
        state[prefix].itemsTotalMargin = amount - costamount
        // state[prefix].itemsTotalMarginConverted = amountConverted - costamountConverted
        state[prefix].itemsTotalPieces = pieces
    }
}

const actions = {
    addOtherChargesItemRow ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            commit('addOtherChargesItemRow',prefix)
            resolve('done')
        })
    },
    deleteLocalSalesInvoiceItem ({commit}, payload) {
        return new Promise((resolve, reject) => {
            let localSalesInvoiceId = payload.localSalesInvoiceId
            let itemId = payload.itemId
            api
                .delete('/localsales/' + localSalesInvoiceId + '/salesitems/' + itemId)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    // deleteLocalSalesItemRow ({commit},index){
    //     commit('deleteLocalSalesItemRow',index)
    // },
    getSalesItemsBySalesOrder ({commit}, payload) {
        commit('resetState')
        let localSalesOrderId = payload.localsalesorder_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/localsalesorders/" + localSalesOrderId + "/salesitems")
                .then(response => {
                    commit('setCurrentLocalSalesInvoiceItemsFromSalesOrder',response.data.data);
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error);
                })
                .then(function(){
                    commit('updateTotals',prefix);
                });
        })
    },
    getSalesItemsByLocalSaleInvoice ({commit}, payload) {
        let localSaleId = payload.localSalesInvoiceId
        let prefix = payload.prefix
        commit('resetState')

        return new Promise((resolve, reject) => {
            api
                .get("/localsales/" + localSaleId + "/salesitems",{

                })
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setLocalSalesInvoiceItems', payload);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //TODO delete in the future?
    getSalesItemsByLocalSale ({commit}, localsale_id) {
        commit('resetState')
        return new Promise((resolve, reject) => {
            api
                .get("/localsales/" + localsale_id + "/salesitems",{

                })
                .then(response => {
                    commit('setCurrentLocalSalesInvoiceItems',response.data.data);
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateTotals ({commit}, prefix) {
        return new Promise ((resolve, reject) => {
            commit('updateTotals',prefix)
            resolve('done')
        })
    }
}

export const localsalesitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}