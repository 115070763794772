import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isObject, isNumeric, log} from "../../../helpers/helpers";
import _ from 'lodash'
import roundTo from 'round-to'

const namespaced = {
    namespaced: true
}

const getCreditRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Contract: {
            id: null,
            salestype_id: null
        },
        Grade: {
            id: null,
            title: null
        },
        Measurement: {
            id: null,
            title: null
        },
        Mc: {
            id: null,
            title: null
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: 0,
            title: null
        },
        Salesitem: {
            id: null,
            amount: 0,
            certification_id: 0,
            chargeto: 0,
            commission: 0,
            comm_pct: 0,
            comm_unit: 0,
            costprice: 0,
            cfr_price: 0,
            cfr_amount: 0,
            description: null,
            grade_id: null,
            import_transport_price: 0,
            import_transport_cost: 0,
            itemqty: 0,
            length: null,
            margin: 0,
            mc_id: null,
            measurement_id: 0,
            othercharge: 1,
            poqty: null,
            productgroup_id: null,
            salesdescription_id: null,
            size: null,
            size_id: null,
            thickness: null,
            thickness_id: null,
            unitmargin: 0,
            unitprice: 0,
            width: null,
        },
        Size: {
            id: null,
            title: null
        },
        Spec: {
            id: null,
            title: 'Credit'
        },
        Species: {
            id: 0,
            title: 'N/A'
        },
        type: 'credit',
    }
}

const getOtherChargesRow = () => {
    return {
        index: 0,
        lineNumber: 1,
        Contract: {
            id: null,
            salestype_id: null
        },
        Grade: {
            id: 39,
            title: 'Other Charges'
        },
        Mc: {
            id: null,
            title: ''
        },
        Measurement: {
            id: null,
            title: '',
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: null,
            title: ''
        },
        Salesitem: {
            id: null,
            amount: 0,
            certification_id: null,
            chargeto: 0,
            commission: 0,
            comm_pct: null,
            comm_unit: null,
            costprice: 0,
            cfr_price: 0,
            cfr_amount: 0,
            description: null,
            grade_id: 39,
            import_transport_price: 0,
            import_transport_cost: 0,
            itemqty: 1,
            length: null,
            margin: null,
            mc_id: null,
            measurement_id: null,
            othercharge: 1,
            poqty: null,
            productgroup_id: null,
            salesdescription_id: null,
            size: 'Other Charges',
            size_id: null,
            thickness: null,
            thickness_id: null,
            unitmargin: null,
            unitprice: null,
            width: null
        },
        Size: {
            id: null,
            title: 'Other Charges'
        },
        Spec: {
            id: null,
            title: ''
        },
        Species: {
            id: null,
            title: null
        },
        type: 'othercharge'
    }
}

const getDefaultState = () => {
    return {
        index: 0,
        lineNumber: 1,
        Contract: {
            id: null,
            salestype_id: null
        },
        Grade: {
            id: null,
            title: null
        },
        Measurement: {
            id: '1',
            title: 'M3'
        },
        Mc: {
            id: null,
            title: null
        },
        Salesdescription: {
            id: null,
            spec_id: null,
            species_id: 0,
            title: null
        },
        Salesitem: {
            id: null,
            amount: 0,
            certification_id: null,
            chargeto: 0,
            commission: 0,
            comm_pct: 0,
            comm_unit: 0,
            costprice: 0,
            cfr_price: 0,
            cfr_amount: 0,
            description: null,
            grade_id: null,
            import_transport_price: 0,
            import_transport_cost: 0,
            itemqty: 0,
            length: null,
            margin: 0,
            mc_id: null,
            measurement_id: 1,
            othercharge: null,
            poqty: null,
            productgroup_id: null,
            salesdescription_id: null,
            size: null,
            size_id: null,
            thickness_id: null,
            unitmargin: 0,
            unitprice: 0,
            width: null
        },
        Size: {
            id: null,
            title: null
        },
        Spec: {
            id: null,
            title: null
        },
        Species: {
            id: null,
            title: null
        },
        type: 'default'
    }
}

const state = {
    //current data. can either be a loaded existing contract or a new unsaved contract
    current: {
        Salesitems: [
            {
                index: 0,
                lineNumber: 1,
                Grade: {
                    id: null,
                    title: null
                },
                Measurement: {
                    id: 1,
                    title: 'M3'
                },
                Mc: {
                    id: null,
                    title: null
                },
                Salesdescription: {
                    id: null,
                    spec_id: null,
                    species_id: null,
                    title: null
                },
                Salesitem: {
                    id: null,
                    amount: 0,
                    certification_id: null,
                    chargeto: 0,
                    cfr_price: 0,
                    cfr_amount: 0,
                    commission: 0,
                    comm_pct: 0,
                    comm_unit: 0,
                    costprice: 0,
                    grade_id: null,
                    import_transport_price: 0,
                    import_transport_cost: 0,
                    itemqty: 0,
                    length: null,
                    margin: 0,
                    mc_id: null,
                    measurement_id: 1,
                    othercharge: null,
                    poqty: null,
                    salesdescription_id: null,
                    size: null,
                    size_id: null,
                    thickness_id: null,
                    unitmargin: 0,
                    unitprice: 0,
                    width: null
                },
                Size: {
                    id: null,
                    title: null
                },
                Spec: {
                    id: null,
                    title: null
                },
                Species: {
                    id: null,
                    title: null
                },
                type: 'default',
            },
        ],
        commissionByUnit: 0,
        itemsGrandTotal: 0,
        itemsTotalAmount: 0,
        itemsTotalCfrAmount: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalImportTransportCost: 0,
        itemsTotalMargin: 0,
        itemsTotalQty: [],
        specificationNotes: null
    },
    update: {
        Salesitems: [],
        commissionByUnit: 0,
        itemsGrandTotal: 0,
        itemsTotalAmount: 0,
        itemsTotalCfrAmount: 0,
        itemsTotalCommission: 0,
        itemsTotalCost: 0,
        itemsTotalImportTransportCost: 0,
        itemsTotalMargin: 0,
        itemsTotalQty: [],
        specificationNotes: null
    }
}

const getters = {
    getField,
    // itemsTotalAmount: state => { return state.current.itemsTotalAmount },
    // itemsTotalCommission: state => { return state.current.itemsTotalCommission },
    // itemsTotalCost: state => { return state.current.itemsTotalCost },
    // itemsTotalMargin: state => { return state.current.itemsTotalMargin },
    // itemsTotalQty: state => { return state.current.itemsTotalQty },
    // commissionByUnit: state => {return state.current.commissionByUnit},
    // specificationNotes: state => {return state.current.specificationNotes}

    // updateItemsTotalAmount: state => { return state.update.itemsTotalAmount },
    // updateItemsTotalCommission: state => { return state.update.itemsTotalCommission },
    // updateItemsTotalCost: state => { return state.update.itemsTotalCost },
    // updateItemsTotalMargin: state => { return state.update.itemsTotalMargin },
    // updateItemsTotalQty: state => { return state.update.itemsTotalQty },
    // updateCommissionByUnit: state => {return state.update.commissionByUnit},
}

const mutations = {
    addCreditSalesItemRow (state, payload) {
        let i = 0;
        let row = getCreditRow()
        let contract = payload.contract
        row.Contract.id = contract.id
        row.Contract.salestype_id = contract.salestype_id
        state[payload.prefix].Salesitems.push(row)
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    addOtherChargesItemRow (state, payload) {
        let i = 0;
        let row = getOtherChargesRow()
        let contract = payload.contract
        row.Contract.id = contract.id
        row.Contract.salestype_id = contract.salestype_id
        state[payload.prefix].Salesitems.push(row)
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    addSalesItemRow (state, payload) {
        let i = 0;
        let row = getDefaultState();
        //check for commission contract sales
        let contract = payload.contract
        if(contract.salestype_id == 2 && contract.commission_per_line == 0){
            row.Salesitem.comm_pct = contract.comm_pct
        }
        row.Contract.id = contract.id
        row.Contract.salestype_id = contract.salestype_id
        if(state[payload.prefix].Salesitems.length > 0){
            let lastItemIndex = state[payload.prefix].Salesitems.length - 1
            let copiedRow = _.cloneDeep(state[payload.prefix].Salesitems[lastItemIndex])
            row.Salesdescription = copiedRow.Salesdescription
            row.Species = copiedRow.Species
            row.Spec = copiedRow.Spec
            row.salesdescription_id = copiedRow.salesdescription_id
        }
        state[payload.prefix].Salesitems.push(row)
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    deleteSalesItemRow (state, payload) {
        state[payload.prefix].Salesitems.splice(payload.index, 1)
        let i = 0;
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    setSalesItems(state,payload){
        state[payload.prefix].Salesitems = payload.data
        let i = 0;
        state[payload.prefix].Salesitems.forEach((item) => {
            item.index = i
            item.lineNumber = i + 1
            i++
        })
    },
    resetState (state, prefix){
        state[prefix].Salesitems = []
        state[prefix].Salesitems.push(getDefaultState())
        state[prefix].itemsTotalAmount = 0
        state[prefix].itemsTotalCfrAmount = 0
        state[prefix].itemsTotalCommission = 0
        state[prefix].itemsTotalCost = 0
        state[prefix].itemsTotalImportTransportCost = 0
        state[prefix].itemsTotalMargin = 0
        state[prefix].itemsTotalQty = [{Total: {qty: 0.0, uofm: 'M3'}}]
        state[prefix].commissionByUnit = 0
        state[prefix].specificationNotes = null
    },
    //TODO: Remove this function
    setPrintSalesItems (state, data) {
        state.print.Salesitems = data;
    },
    updateField,
    updateSalesItems (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload['prefix']
        let precision = payload?.precision ? payload?.precision : 2
        state[prefix].Salesitems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['itemqty','unitprice','costprice','comm_pct','comm_unit','import_transport_price']
        if(keys[0] == 'Salesitem' && numberFields.indexOf(keys[1]) !== -1 && state[prefix].Salesitems[payload['index']]['type'] == 'default'){
            //update amount
            let amount = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['unitprice'])

            //round amount
            amount = roundTo(amount,precision)

            state[prefix].Salesitems[payload['index']]['Salesitem']['amount'] = amount
            //update margin
            let cost = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['costprice'])
            let margin = amount - cost
            if(state[prefix].Salesitems[payload['index']]['Contract']['salestype_id'] == 5){
                // let cfrCost = 0
                let importTransportCost = 0
                // if(state[prefix].Salesitems[payload['index']]['Salesitem']['cfr_price']) cfrCost = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['cfr_price'])
                if(state[prefix].Salesitems[payload['index']]['Salesitem']['import_transport_price']) {
                    state[prefix].Salesitems[payload['index']]['Salesitem']['cfr_price'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['unitprice']) - parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['import_transport_price'])
                    importTransportCost = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['import_transport_price'])
                    state[prefix].Salesitems[payload['index']]['Salesitem']['import_transport_cost'] = importTransportCost
                }
                margin = margin - importTransportCost
            }
            state[prefix].Salesitems[payload['index']]['Salesitem']['margin'] = margin
            //update unit margin
            let unitMargin = 0
            if(state[prefix].Salesitems[payload['index']]['Contract']['salestype_id'] == 5){
                unitMargin = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['unitprice']) - parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['costprice']) - parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['import_transport_price'])
            } else {
                unitMargin = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['unitprice']) - parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['costprice'])
            }
            state[prefix].Salesitems[payload['index']]['Salesitem']['unitmargin'] = unitMargin

            //update commission
            if(parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['comm_pct']) > 0 && state[prefix].commissionByUnit == 0){
                let commission = amount * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['comm_pct'])/100
                state[prefix].Salesitems[payload['index']]['Salesitem']['commission'] = commission
            } else if(parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['comm_unit']) > 0 && state[prefix].commissionByUnit == 1){
                let commission = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['itemqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['comm_unit'])
                state[prefix].Salesitems[payload['index']]['Salesitem']['commission'] = commission
            }
        } else if(keys[0] == 'Salesitem' && keys[1] == 'amount' && state[prefix].Salesitems[payload['index']]['type'] == 'othercharge'){
            state[prefix].Salesitems[payload['index']]['Salesitem']['unitprice'] = state[prefix].Salesitems[payload['index']]['Salesitem']['amount']
        }
    },
    updateCommissionByUnit (state, payload) {
        let prefix = payload['prefix']
        let value = payload['value']
        state[prefix].commissionByUnit = value;
    },
    // updateSpecificationNotes (state, value) {
    //     state.current.specificationNotes = value;
    // },
    updateTotals (state, payload) {
        let prefix = null
        let precision = 2
        if(isObject(payload)){
            prefix = payload.prefix
            precision = payload.precision
        } else {
            prefix = payload
        }
        let margin = 0, amount = 0, commission = 0, cost = 0, cfrAmount = 0, importTransportCost = 0, quantities = []
        state[prefix].Salesitems.forEach((item) => {
            if(item.type == 'credit') {
                amount -= isNumeric(item.Salesitem.amount) ? roundTo(parseFloat(item.Salesitem.amount),precision) : 0;
            } else {
                if(item.type == 'default' || (item.type == 'othercharge' && item.Salesitem.chargeto == 0)) {
                    amount += isNumeric(item.Salesitem.amount) ? roundTo(parseFloat(item.Salesitem.amount),precision) : 0;
                }
            }

            if(item.type == 'default') {
                commission += isNumeric(item.Salesitem.commission) ? parseFloat(item.Salesitem.commission) : 0;
            }

            if((item.Salesitem.margin == 0 || item.Salesitem.margin == null) && [1,5].includes(item.Contract?.salestype_id) && item.type != 'othercharge'){
                if(item.Contract?.salestype_id == 5){
                    item.Salesitem.margin = item.Salesitem.amount - ((item.Salesitem.costprice * (item.Salesitem.poqty > 0 ? item.Salesitem.poqty : item.Salesitem.itemqty)) + (item.Salesitem.import_transport_price * (item.Salesitem.poqty > 0 ? item.Salesitem.poqty : item.Salesitem.itemqty)))
                } else {
                    item.Salesitem.margin = item.Salesitem.amount - (item.Salesitem.costprice * (item.Salesitem.poqty > 0 ? item.Salesitem.poqty : item.Salesitem.itemqty))
                }
            }

            if(item.type == 'default') {
                // cost += isNumeric(item.Salesitem.amount) && isNumeric(item.Salesitem.margin) ? (parseFloat(item.Salesitem.amount) - parseFloat(item.Salesitem.margin)) : 0;
                cost += parseFloat(item.Salesitem.costprice) * (item.Salesitem.poqty > 0 ? parseFloat(item.Salesitem.poqty) : parseFloat(item.Salesitem.itemqty))
            } else if(item.type == 'othercharge' && item.Salesitem.chargeto == 1){
                cost += isNumeric(item.Salesitem.costprice) ? parseFloat(item.Salesitem.costprice) : 0
            }
            if(item.type == 'othercharge' && item.Salesitem.chargeto == 0){
                item.Salesitem.margin = item.Salesitem.amount
            } else if(item.type == 'othercharge' && item.Salesitem.chargeto == 1){
                item.Salesitem.margin = -Math.abs(parseFloat(item.Salesitem.costprice))
            }

            if(item.Salesitem.type == 'default') {
                margin += isNumeric(item.Salesitem.margin) ? parseFloat(item.Salesitem.margin) : 0;
                cfrAmount += isNumeric(item.Salesitem.cfr_amount) ? parseFloat(item.Salesitem.cfr_amount) : 0;
                importTransportCost += isNumeric(item.Salesitem.import_transport_cost) ? parseFloat(item.Salesitem.import_transport_cost) : 0;
            }

            let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
            if(idx !== -1){
                quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
            } else {
                if(item.type == 'default') {
                    let obj = {'Total': {}}
                    obj['Total']['uofm'] = item.Measurement.title;
                    obj['Total']['qty'] = isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                    quantities.push(obj)
                }
            }
        })
        state[prefix].itemsTotalAmount = amount
        state[prefix].itemsTotalCfrAmount = cfrAmount
        state[prefix].itemsTotalCommission = commission
        state[prefix].itemsTotalCost = cost
        state[prefix].itemsTotalImportTransportCost = importTransportCost
        state[prefix].itemsTotalMargin = margin
        state[prefix].itemsTotalQty = quantities
        console.log('totals updated...')
        return true
    }
}

const actions = {
    addCreditSalesItemRow ({commit},payload) {
        commit('addCreditSalesItemRow',payload)
    },
    addOtherChargesItemRow ({commit},payload) {
        commit('addOtherChargesItemRow',payload)
    },
    addSalesItemRow ({commit},payload) {
        commit('addSalesItemRow',payload)
    },
    deleteSalesItemRow ({commit},payload){
        commit('deleteSalesItemRow',payload)
    },
    deleteSalesItem ({commit},payload){
        return new Promise((resolve, reject) => {
            let contract_id = payload.contract_id
            let salesitem_id = payload.salesitem_id
            api
                .delete('/contracts/' + contract_id + '/salesitems/' + salesitem_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getSalesItemsByContract ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/" + contract_id + "/salesitems",{

                })
                .then(response => {
                    let payload = {
                        data: response.data.data,
                        prefix: prefix
                    }
                    commit('setSalesItems', payload);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(function(){
                    commit('updateTotals',prefix);
                });
        })
    },
    
    //TODO: remove this function
    getSalesItemsToPrintByContract ({commit},contract_id) {
        return new Promise((resolve, reject) => {
            commit('resetState')
            api
                .get("/contracts/"+contract_id+"/salesitems",{

                })
                .then(response => {
                    commit('setPrintSalesItems',response.data.data);
                    commit('updatePrintTotals')
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetSalesItems ({commit},prefix) {
        return new Promise ((resolve, reject) => {
            commit('resetState',prefix)
            resolve('done')
        })
    },
    resetCurrentSalesItems ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState','current')
            resolve('done')
        })
    },
    updateCommissionByUnit ({commit},payload) {
        commit('updateCommissionByUnit',payload)
    },
    updateSpecificationNotes ({commit},value) {
        commit('updateSpecificationNotes',value)
    },
    updateTotals ({commit},prefix) {
        commit('updateTotals',prefix)
    }
}

export const salesitem = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}