import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Contract: {
			id: null,
			title: null
		},
		Epr: {
			containercount: 1,
			containersize_id: 1,
			contract_id: null,
			currency_id: 2,
			customer_id: 100,
			documents: [],
			eprdate: null,
			etsdate: null,
			etadate: null,
			etddate: null,
			fsc: null,
			grade_remarks: null,
			id: null,
			incoterm_id: 1,
			incotermport: 1,
			internal_remarks: null,
			is_export: 1,
			loadingport_id: null,
			ms_id: 1,
			office_id: 1,
			paymentstatement_id: null,
			popaymentstatement_id: null,
			productgroup_id: null,
			productorigin_id: null,
			remarks: null,
			// salescontact_id: null,
			salestype_id: 1,
			shippingport_id: null,
			shippingweekstatement: null,
			size_remarks: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			// Salescontact: {
			// 	name: null
			// }
		}
	}
}

const state = {
	allExportPurchaseRequests: [],
	current: {
		Contract: {
			id: null,
			title: null
		},
		Epr: {
			containercount: 1,
			containersize_id: 1,
			contract_id: null,
			currency_id: 2,
			customer_id: 100,
			documents: [],
			eprdate: null,
			etsdate: null,
			etadate: null,
			etddate: null,
			fsc: null,
			grade_remarks: null,
			id: null,
			incoterm_id: 1,
			incotermport: null,
			internal_remarks: null,
			is_export: 1,
			loadingport_id: null,
			ms_id: 1,
			office_id: 1,
			paymentstatement_id: null,
			popaymentstatement_id: null,
			productgroup_id: null,
			productorigin_id: null,
			remarks: null,
			// salescontact_id: null,
			salestype_id: 1,
			size_remarks: null,
			shippingport_id: null,
			shippingweekstatement: null,
			status_id: 1,
			supplier_id: null,
			title: null,
			// Salescontact: {
			// 	id: null,
			// 	name: null
			// }
		},
	},
	update: {
		Contract: {
			id: null,
			title: null
		},
		Epr: {
			id: null,
			documents: [],
			// Salescontact: {
			// 	id: null,
			// 	name: null
			// }
		}
	},
	newExportPurchaseRequestSaved: false,
	newExportPurchaseRequestUnsaved: false,
	totalExportPurchaseRequests: 0,
	updatedExportPurchaseRequestSaved: false,
	updatedExportPurchaseRequestUnsaved: false
}

const getters = {
	getField
}

const mutations = {
	resetCurrentState(state) {
		console.log('Resetting this Epr...')
		state.current.Epr = getDefaultState().Epr
		state.current.Contract = getDefaultState().Contract
		state.newExportPurchaseRequestSaved = false
		state.newExportPurchaseRequestUnsaved = false
	},
	resetState (state){
		console.log('Resetting Export Purchase Requests...')
		state.allExportPurchaseRequests = []
		state.totalExportPurchaseRequests = 0
	},
	resetUpdateState(state) {
		console.log('Resetting this Epr...')
		state.update.Epr = getDefaultState().Epr
		state.update.Contract = getDefaultState().Contract
		state.updatedExportPurchaseRequestSaved = false
		state.updatedExportPurchaseRequestUnsaved = false
	},
	setContract(state, payload) {
		console.log('Setting the related Contract')
		state[payload.prefix].Contract.contract_id = payload.contract.id
		state[payload.prefix].Contract = payload.contract
	},
	setEprFromContract(state, payload) {
		let prefix = payload.prefix
		let contract = payload.contract[0]['Contract']
		state[prefix].Epr.containercount = contract.containercount
		state[prefix].Epr.containersize_id = contract.containersize_id
		state[prefix].Epr.currency_id = contract.currency_id
		// state[prefix].Epr.customer_id = contract.customer_id
		state[prefix].Epr.fsc = contract.fsc
		state[prefix].Epr.incoterm_id = 1 //set always 2022-04-01
		state[prefix].Epr.incotermport = 1 //set always 2022-04-01
		state[prefix].Epr.is_export = 1
		state[prefix].Epr.office_id = 1
		// state[prefix].Epr.paymentstatement_id = contract.paymentstatement_id
		state[prefix].Epr.productgroup_id = contract.productgroup_id
		state[prefix].Epr.productorigin_id = contract.productorigin_id
		// state[prefix].Epr.salescontact_id = contract.salescontact_id
		state[prefix].Epr.remarks = contract.note
		// state[prefix].Epr.salestype_id = contract.salestype_id
		state[prefix].Epr.salestype_id = 1
		// state[prefix].Epr.shippingport_id = contract.shippingport_id
		state[prefix].Epr.supplier_id = contract.supplier_id
		state[prefix].Epr.loadingport_id = contract.loadingport_id
		state[prefix].Epr.popaymentstatement_id = contract.Purchaseorder.paymentstatement_id
	},
	setEprFromPreviousEpr(state, payload) {
		let prefix = payload.prefix
		let epr = payload.epr[0]['Epr']

		state[prefix].Epr.containercount = epr.containercount
		state[prefix].Epr.containersize_id = epr.containersize_id
		state[prefix].Epr.currency_id = epr.currency_id
		state[prefix].Epr.fsc = epr.fsc
		state[prefix].Epr.incoterm_id = 1 //set always 2022-04-01
		state[prefix].Epr.incotermport = 1 //set always 2022-04-01
		state[prefix].Epr.is_export = 1
		state[prefix].Epr.office_id = 1
		state[prefix].Epr.productgroup_id = epr.productgroup_id
		state[prefix].Epr.productorigin_id = epr.productorigin_id
		state[prefix].Epr.remarks = epr.note
		state[prefix].Epr.salestype_id = 1
		state[prefix].Epr.supplier_id = epr.supplier_id
		// state[prefix].Epr.shippingweekstatement = epr.shippingweekstatement
		state[prefix].Epr.loadingport_id = epr.loadingport_id
		state[prefix].Epr.popaymentstatement_id = epr.popaymentstatement_id
		state[prefix].Epr.remarks = epr.remarks
		state[prefix].Epr.grade_remarks = epr.grade_remarks
		state[prefix].Epr.internal_remarks = epr.internal_remarks
		state[prefix].Epr.size_remarks = epr.size_remarks
	},
	setEprs(state, data) {
		state.allExportPurchaseRequests = data;
	},
	setEprToBeUpdated (state, data) {
		state.update.Epr = data.Epr
		state.update.Contract = data.Contract
	},
	setTotalEprs (state,val) {
		state.totalExportPurchaseRequests = val
	},
	updateField,
	updateEprStatus (state, payload) {
		state[payload.prefix].Epr.status_id = payload.status
	}
}

const actions = {
	approveEprById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/eprs/" + val + "/approve")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 3,
							prefix: 'update'
						}
						commit('updateEprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	cancelEprById({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/eprs/" + val)
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	convertEprToContractById({commit},payload) {
		return new Promise((resolve, reject) => {
			api
				.put("/eprs/" + payload.epr_id + '/convert',{
					new_contract_number: payload.contract_number,
					new_contract_buyer: payload.contract_buyer,
					new_contract_buyer_term: payload.contract_buyer_term,
					new_contract_pod: payload.contract_pod,
					new_contract_sales_colleague: payload.contract_sales_colleague
				})
				.then(response => {
					if(response.data.status == 'success') {
						if(payload.setContract) {
							let payload2 = {
								prefix: payload.prefix,
								contract: {
									id: response.data.contract_id,
									title: response.data.contract_title
								}
							}
							commit('setContract', payload2)
						}
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	copyContractToEpr({commit, rootState}, payload) {
		return new Promise ((resolve, reject) => {
			commit('setEprFromContract', payload)
			setTimeout(function(){
				resolve('done')
			},1000)
		})
	},
	copyPreviousEprToEpr({commit, rootState}, payload) {
		return new Promise ((resolve, reject) => {
			commit('setEprFromPreviousEpr', payload)
			setTimeout(function(){
				resolve('done')
			},1000)
		})
	},
	createEpr ({state, rootState}){
		let epr = _.cloneDeep(state.current.Epr)
		delete epr.Salescontact

		let eprItems = _.cloneDeep(rootState.appic.epritem.current.EprItems)
		eprItems.forEach((item,idx) => {
			delete eprItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.post('/eprs',{
						epr: epr,
						epritems: eprItems
					}
				)
				.then((response) => {
					if(response.data.status == 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllEprs({commit},payload) {
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/eprs", {
						params: {
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'Epr.eprdate',
									direction: 'DESC'
								}
							]
						}
					})
					.then(response => {
						commit('setEprs', response.data.data)
						commit('setTotalEprs', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getEprToUpdateById({commit}, epr_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/eprs/' + epr_id)
				.then(response => {
					commit('setEprToBeUpdated', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	rejectEprById ({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/eprs/" + val + "/reject")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 4,
							prefix: 'update'
						}
						commit('updateEprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	resetAllActiveEprs ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentEpr({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
			reject('error')
		})
	},
	resetUpdateEpr({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetUpdateState')
			resolve('done')
			reject('error')
		})
	},
	searchAllEprs ({commit}, payload){
		return new Promise((resolve, reject) => {
			api
				.get("/eprs",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
						},
						order: payload.order
					}
				})
				.then(response => {
					commit('setEprs',response.data.data);
					commit('setTotalEprs',response.data.totalRows);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	submitEprById({commit},val) {
		return new Promise((resolve, reject) => {
			api
				.put("/eprs/" + val + "/submit")
				.then(response => {
					if(response.data.status == 'success') {
						let payload = {
							status: 2,
							prefix: 'update'
						}
						commit('updateEprStatus',payload)
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	updateEpr({state, rootState}) {
		let epr = _.cloneDeep(state.update.Epr)
		let documents = _.cloneDeep(state.update.Epr.documents)
		//remove unneeded properties
		delete epr.Customer
		delete epr.Destination
		delete epr.documents
		delete epr.Incoterm
		delete epr.Origin
		delete epr.LoadingPort
		delete epr.Paymentstatement
		delete epr.PoPaymentstatement
		delete epr.Salescontact
		delete epr.ShippingPort
		delete epr.Status
		delete epr.Supplier
		delete epr.SupplierPayment

		let eprItems = _.cloneDeep(rootState.appic.epritem.update.EprItems)
		eprItems.forEach((item,idx) => {
			delete eprItems[idx]['Options']
		})

		return new Promise((resolve, reject) => {
			api
				.put('/eprs/' + state.update.Epr.id,{
						epr: epr,
						epritems: eprItems,
						documents: documents
					}
				)
				.then((response)=>{
					this.updatedExportPurchaseRequestSaved = true
					this.updatedExportPurchaseRequestUnsaved = false
					resolve('done')
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('done')
				})
		})
	}
}

export const epr = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}