import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allSpecs: [],
        Spec: {
            id: null,
            mc_id: null,
            productgroups: [],
            title: null,
            version: '3.0'
        }
    }
}

const state = {
    allSpecs: [],
    allCleanedSpecs: [],
    current: {
        Spec: {
            id: null,
            mc_id: null,
            productgroups: [],
            title: null,
            version: '3.0'
        }
    },
    update: {
        Spec: {}
    }
}

const getters = {
    allCleanedSpecs: state => {
        return state.allCleanedSpecs;
    },
    allSpecs: state => {
        return state.allSpecs;
    },
    getField,
    findSpecById: state => {
        return id => state.allSpecs.find (spec => {
            return spec.Spec.id === id
        })
    }
}

const mutations = {
    setAllCleanedSpecs(state,data){
        state.allCleanedSpecs = data;
    },
    setAllSpecs(state,data){
        state.allSpecs = data;
    },
    setSpecToUpdate(state, spec){
        state.update.Spec = spec.Spec
    },
    resetAllSpecs(state){
        state.allSpecs = getDefaultState().allSpecs
        state.allCleanedSpecs = getDefaultState().allSpecs
    },
    resetCurrentState(state) {
        state.current.Spec = getDefaultState().Spec
    },
    resetSpecToUpdate(state) {
        state.update.Spec = getDefaultState().Spec
    },
    resetState(state) {
        state.allSpecs = getDefaultState().allSpecs
        state.allCleanedSpecs = getDefaultState().allSpecs
    },
    updateField
}

const actions = {
    createSpec ({state, rootState}) {
        let spec = _.cloneDeep(state.current.Spec)
        return new Promise((resolve, reject) => {
            api
                .post('/specs',{
                        spec: spec,
                    }
                )
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteSpecById({commit}, itemSpecId) {
        return new Promise((resolve, reject) => {
            api
                .delete('/specs/' + itemSpecId)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllCleanedSpecs({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/specs/cleaned")
                .then(response => {
                    commit('setAllCleanedSpecs',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllSpecs({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/specs")
                .then(response => {
                    commit('setAllSpecs',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getSpecToUpdateById( {commit}, specId ) {
        return new Promise((resolve, reject) => {
            api
                .get('/specs/' + specId )
                .then(response => {
                    commit('setSpecToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetAllSpecs({commit}){
        commit('resetAllSpecs')
    },
    resetAllCleanedSpecs({commit}){
        commit('resetAllSpecs')
    },
    resetSpecToUpdate( {commit} ) {
        commit('resetSpecToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateSpec ( {state} ) {
        let spec = _.cloneDeep(state.update.Spec)
        return new Promise((resolve, reject) => {
            api
                .put("/specs/" + state.update.Spec.id,{
                    spec: spec
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

export const spec = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}