<template>
    <v-menu
            offset-y origin="right top"
            left content-class="language-dropdown"
            transition="slide-y-transition"
            nudge-top="-10"
            class="user-block-wrap"
    >
        <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <img :src="`data:image/png;base64,${User.photo}`" alt="avatar" height="40" width="40" class="img-responsive rounded-circle" v-on="on" v-if="User.photo != null"/>
                        <img src="/static/avatars/appic_user.jpg" alt="avatar" height="40" width="40" class="img-responsive rounded-circle" v-on="on" v-else/>
                    </template>
                    <span>{{ $t('message.yourProfile') }}</span>
                </v-tooltip>
            </v-btn>
        </template>
        <div class="dropdown-content">
            <div class="dropdown-top white--text primary">
                <span class="white--text fs-14 fw-bold d-block">{{ User.title }}</span>
                <span class="d-block fs-12 fw-normal">{{ User.email }}</span>
            </div>
            <v-list
                dense
                class="dropdown-list"
            >
                <template v-for="userLink in userLinks" v-if="userLink.id !== 3">
                    <v-list-item :to="getMenuLink(userLink.path + 'update/' + User.id)" :key="userLink.id">
                        <i class="material-icons mr-3">{{ userLink.icon }}</i>
                        <span>{{$t(userLink.title)}}</span>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item @click="logoutUser" :key="userLink.id" class="ml-0 d-flex align-content-center">
                        <i class="material-icons mr-3 red--text">{{ userLink.icon }}</i>
                        <span>{{$t(userLink.title)}}</span>
                    </v-list-item>
                </template>
            </v-list>
        </div>
    </v-menu>
</template>
<script>
    import {getCurrentAppLayout} from "Helpers/helpers";
    import {mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";

    export default {
        data() {
            return {
                logoutDialog: false,
                userLinks: [
                    {
                        id: 1,
                        title: 'message.userProfile',
                        icon: 'person_outline',
                        path: '/users/'
                    },
                    {
                        id: 3,
                        title: 'message.logOut',
                        icon: 'power_settings_new',
                        path: '#'
                    }
                ]
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('user',{
                User : 'User'
            })
        },
        methods: {
            async logoutUser() {
                if (await this.$root.$confirm(
                        this.$t('message.logout'),
                        this.$t('message.confirmations.continueLogOut') + ' ' + this.User?.title +'?',
                        {
                            color: 'success',
                            width: 500
                        }
                    )
                ) {
                    this.$store.dispatch("logoutUserFromFirebase", this.$router);
                } else {
                    return false
                }
            },
            getMenuLink(path) {
                return '/' + getCurrentAppLayout(this.$router) + path;
            }
        }
    }
</script>
<style scoped>
.v-dialog__content.v-dialog__content--active .v-dialog--active {
    min-width: unset !important;
}
</style>
