<template>
    <v-container fluid pa-0 ma-0>
        <app-card :fullBlock="true">
            <div class="d-flex flex-row justify-space-between">
                <v-breadcrumbs :items="breadcrumbItems" v-if="breadcrumbItems != null" class="ma-0 py-1 pl-3">
                    <template slot="item" slot-scope="props">
                        <h5 class="text-capitalize mb-0">{{$t(props.item.title)  + (documentTitle ? ": " + documentTitle : "")}}</h5>
                    </template>
                </v-breadcrumbs>
                <HelpPopUp v-if="hasHelpPage" :help-page-id="helpPageId"></HelpPopUp>
            </div>
        </app-card>
    </v-container>
</template>

<script>
    import HelpPopUp from "Components/Appic/Help/HelpPopUp";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {log} from "Helpers/helpers";
    import {mapActions} from "vuex";
    export default {
        components: {HelpPopUp},
        props: ['documentTitle','helpPageComponent'],
        data() {
            return {
                breadcrumbItems: []
            }
        },
        computed: {
            statePrefix() {
                return 'current'
            },
            ...mapFieldsPrefixed('help', {
                TableOfContents: 'TableOfContents'
            },'statePrefix'),
            hasHelpPage () {
                if(this.helpPageComponent != null) {
                    let helpPage = this.TableOfContents.find(t => t.TableOfContent.component == this.helpPageComponent)
                    if (helpPage) return true
                    return false
                }
                return false
            },
            helpPageId () {
                if(this.helpPageComponent != null) {
                    let helpPage = this.TableOfContents.find(t => t.TableOfContent.component == this.helpPageComponent)
                    return helpPage.TableOfContent.id
                } else {
                    return null
                }
            }
        },
        methods: {
            ...mapActions('help',{
                getAdminTableOfContents: 'getAdminTableOfContents',
                getUserTableOfContents: 'getUserTableOfContents'
            }),
        },
        created() {
            this.breadcrumbItems[0] = this.$breadcrumbs[0].meta;
        },
        mounted() {
            if(this.TableOfContents.length == 0){
                if(this.$can('update','HelpPage')){
                    this.getAdminTableOfContents()
                } else {
                    this.getUserTableOfContents()
                }
            }
        }
    };
</script>
