import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        allParentSpecies: [],
        allSpecies: [],
        Species: {
            hscode: null,
            hscode_veneer: null,
            id: null,
            iscategory: null,
            latin_name: null,
            parent_id: null,
            productgroups: [],
            title: null,
            type: null
        }
    }
}

const state = {
    allParentSpecies: [],
    allSpecies: [],
    current: {
        Species: {
            hscode: null,
            hscode_veneer: null,
            id: null,
            iscategory: null,
            latin_name: null,
            parent_id: null,
            productgroups: [],
            title: null,
            type: null
        }
    },
    update: {
        Species: {}
    }
}

const getters = {
    allParentSpecies: state => {
        return state.allParentSpecies;
    },
    allSpecies: state => {
        return state.allSpecies;
    },
    findSpeciesById: state => {
        return id => state.allSpecies.find (species => {
            return species.Species.id === id
        })
    },
    getField
}

const actions = {
    createSpecies ({state}) {
        const species = _.cloneDeep(state.current.Species)
        return new Promise((resolve, reject) => {
            api
                .post('/species',{
                    species: species,
                })
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteSpeciesById ({commit}, species_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/species/' + species_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllSpecies ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/species")
                .then(response => {
                    commit('setAllSpecies',response.data.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllParentSpecies ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/parents/species")
                .then(response => {
                    commit('setAllParentSpecies',response.data.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getSpeciesToUpdateById( {commit}, speciesId ) {
        return new Promise((resolve, reject) => {
            api
                .get('/species/' + speciesId )
                .then(response => {
                    commit('setSpeciesToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetAllSpecies( {commit} ) {
        commit('resetAllSpecies')
    },
    resetAllParentSpecies( {commit} ) {
        commit('resetAllParentSpecies')
    },
    resetSpeciesToUpdate( {commit} ) {
        commit('resetSpeciesToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateSpecies ( {state} ) {
        let species = _.cloneDeep(state.update.Species)
        //remove unneeded properties
        return new Promise((resolve, reject) => {
            api
                .put("/species/" + state.update.Species.id,{
                    species: species
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

const mutations = {
    setAllParentSpecies(state, data){
        state.allParentSpecies = data;
    },
    setAllSpecies(state, data){
        state.allSpecies = data;
    },
    setSpeciesToUpdate ( state, species ){
        state.update.Species = species.Species
    },
    resetAllSpecies( state ) {
        state.allSpecies = getDefaultState().allSpecies
    },
    resetAllParentSpecies( state ) {
        state.allParentSpecies = getDefaultState().allParentSpecies
    },
    resetSpeciesToUpdate (state) {
        state.update.Species = getDefaultState().Species
    },
    resetCurrentState (state) {
        state.current.Species = getDefaultState().Species
    },
    resetState(state){
        state.allSpecies = getDefaultState().allSpecies
        state.allParentSpecies = getDefaultState().allParentSpecies
    },
    updateField
}

export const species = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}