import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Country: {
            id: null,
            alt_name: null,
            continent_id: 1,
            eutr_compliance_required: null,
            shippingport_id: null,
            name: null,
        }
    }
}

const state = {
    allCountries: [],
    current: {
        Country: {
            id: null,
            alt_name: null,
            continent_id: 1,
            eutr_compliance_required: null,
            shippingport_id: null,
            name: null,
        }
    },
    update: {
        Country: {}
    }
}

const getters = {
    allCountries: state => {
        return state.allCountries;
    },
    findCountryById: state => {
        return id => state.allCountries.find (country => {
            return country.Country.id === id
        })
    },
    getField
}

const actions = {
    createCountry ({state, rootState}) {
        let country = _.cloneDeep(state.current.Country)
        delete country?.Continent
        delete country?.Shippingport

        return new Promise((resolve, reject) => {
            api
                .post('/countries',{
                        country: country,
                    }
                )
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteCountryById ({commit}, country_id) {
        return new Promise((resolve, reject) => {
            api
                .delete('/countries/' + country_id)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getCountryToUpdateById ({commit}, countryId) {
        return new Promise((resolve, reject) => {
            api
                .get('/countries/' + countryId )
                .then(response => {
                    commit('setCountryToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAllCountries ({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/countries")
                .then(response => {
                    commit('setAllCountries',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetCountryToUpdate ({commit}) {
        commit('resetCountryToUpdate')
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetState ({commit}){
        commit('resetState')
    },
    updateCountry ({state} ){
        let country = _.cloneDeep(state.update.Country)
        //remove unneeded properties
        delete country?.Continent
        delete country?.Shippingport

        return new Promise((resolve, reject) => {
            api
                .put("/countries/" + state.update.Country.id,{
                    country: country
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
}

const mutations = {
    setAllCountries (state,data) {
        state.allCountries = data;
    },
    setCountryToUpdate ( state, country ){
        state.update.Country = country.Country
    },
    resetCountryToUpdate (state) {
        state.update.Country = getDefaultState().Country
    },
    resetCurrentState (state) {
        state.current.Country = getDefaultState().Country
    },
    resetState (state, data) {
        state.allCountries = []
    },
    updateField
}

export const country = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}