/**
 * Auth Module
 */
import Vue from 'vue'
import firebase from 'firebase/app';
import Nprogress from 'nprogress';
import router from '../../../router';
import {
    facebookAuthProvider,
    googleAuthProvider,
    twitterAuthProvider,
    githubAuthProvider
} from '../../../firebase';
import { api } from "../../../api";
import { store } from "../../store";
import { openDB } from 'idb';
import  i18n  from 'vue-i18n';

const state = {
    user: localStorage.getItem('user'),
    isUserSignInWithAuth0: Boolean(localStorage.getItem('isUserSignInWithAuth0'))
}

// getters
const getters = {
    getUser: state => {
        return state.user;
    },
    isUserSignInWithAuth0: state => {
        return state.isUserSignInWithAuth0;
    }
}

// actions
const actions = {
    signInUserInFirebase(context, payload) {
        return new Promise((resolve, reject) => {
            const {user} = payload;
            context.commit('loginUser');
            let signInResponse = null
            firebase
                .auth()
                .signInWithEmailAndPassword(user.email, user.password)
                .then((user) => {
                    Nprogress.done();
                    //get API user token
                    let token = new Promise((resolve, reject) => {
                        api
                            .post("/token", {
                                email: payload.user.email,
                                password: payload.user.password
                            })
                            .then(response => {
                                signInResponse = response.data
                                resolve(response.data)
                            })
                            .catch(error => {
                                signInResponse = error.response.data
                                reject(error);
                            });
                    })
                    token
                        .then((tokens) => {
                            user.apiToken = tokens.token
                            user.refreshToken = tokens.refreshToken
                            user.sessionId = tokens.sessionId
                            context.commit('loginUserSuccess', user);
                            context.dispatch('updateStore', tokens.tableUpdates)
                            if(tokens.closedSessions > 0) {
                                context.dispatch('updateClosedSessions', tokens.closedSessions)
                            }
                        })
                        .then(() => {
                            context.dispatch('resetConstantsState')
                            context.dispatch('user/getUserByEmailId', user.user.email)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(error => {
                    context.commit('loginUserFailure', 'Invalid credentials.');
                    reject(error)
                });
        })
    },
    logoutUserFromFirebase(context) {
        Nprogress.start();
        firebase.auth().signOut()
            .then(() => {
                Nprogress.done();
                let deleteRefreshToken = new Promise((resolve, reject) => {
                    let user = JSON.parse(localStorage.getItem('user'))
                    api
                        .delete("/refresh-token/" + user.user.email + '/' + user.refreshToken)
                        .then(response => {
                            resolve(response.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                deleteRefreshToken.then(()=>{
                    context.commit('user/resetState');

                    context.state.user = null
                    localStorage.removeItem('user');

                    //close all open tabs
                    if(context.rootState.appic.runtime.openedTabs.length > 0) {
                        context.rootState.appic.runtime.openedTabs.forEach(tab => {
                            let openTab = window.open('', tab)
                            try {
                                openTab.close()
                            } catch(err){
                                console.log(err)
                            }
                        })
                    }

                    //delete vuex key from indexedDB
                    const deleteVuex = async() => {
                        console.log('deleting vuex')

                        const dbName = 'localforage'
                        const storeName = 'keyvaluepairs'
                        const version = 2
                        const db = await openDB(dbName, version, {
                            // upgrade(db, oldVersion, newVersion, transaction) {
                            //     const store = db.createObjectStore(storeName)
                            // }
                        })
                        const tx = await db.transaction(storeName, 'readwrite')
                        const store = await tx.objectStore(storeName)
                        const key = 'vuex'
                        await store.delete(key)
                        await tx.done
                    }
                    deleteVuex()
                        .then(() => {
                            localStorage.removeItem('tableUpdates')
                            localStorage.removeItem('closedSessions')
                            localStorage.removeItem('vuex')
                            localStorage.removeItem('sessionId')

                            //finally close the opener 2021-10-09
                            try {
                                let openerWindow = window.opener
                                if(openerWindow){
                                    openerWindow.close()
                                }
                            } catch (err){
                                console.log(err)
                            }

                            //added by VJC 2020-11-17
                            location.reload()
                        })
                });
            })
            .catch(error => {
                context.commit('loginUserFailure', 'Login failed.');
            })
    },
    updateStore(context, tableUpdates) {
        const currentTableUpdates = JSON.parse(localStorage.getItem('tableUpdates'))
        // console.log(currentTableUpdates)

        const currentAgents = currentTableUpdates?.agents
        const currentBankAccounts = currentTableUpdates?.bankaccounts
        const currentBuyers = currentTableUpdates?.customers
        const currentCertifications = currentTableUpdates?.certifications
        const currentCodeVersion = currentTableUpdates?.code_version
        const currentConstants = currentTableUpdates?.constants
        const currentContracts = currentTableUpdates?.contracts
        const currentCountries = currentTableUpdates?.countries
        const currentDoctypes = currentTableUpdates?.doctypes
        const currentGrades = currentTableUpdates?.grades
        const currentInvoices = currentTableUpdates?.invoices
        const currentIpas = currentTableUpdates?.ipas
        const currentLcas = currentTableUpdates?.lcas
        const currentMoistureContent = currentTableUpdates?.mcs
        const currentPaymentstatements = currentTableUpdates?.paymentstatements
        const currentPackinglists = currentTableUpdates?.pldos
        const currentProductGroups = currentTableUpdates?.productgroups
        const currentPurchaseorders = currentTableUpdates?.purchaseorders
        const currentSdas = currentTableUpdates?.sdas
        const currentSizes = currentTableUpdates?.sizes
        const currentShippingports = currentTableUpdates?.shippingports
        const currentSpecs = currentTableUpdates?.specs
        const currentSpecies = currentTableUpdates?.species
        const currentSuppliers = currentTableUpdates?.suppliers
        const currentThickness = currentTableUpdates?.thicknesses
        const currentTtas = currentTableUpdates?.ttas
        const currentWprs = currentTableUpdates?.wprs
        const currentStandardAssetDocument = currentTableUpdates?.standard_asset_documents

        // check agents
        if (currentAgents != currentTableUpdates?.agents) {
            context.commit('agent/resetState')
            context.dispatch('agent/getAllAgents')
        }
        // check bankaccounts
        if(currentBankAccounts != tableUpdates?.bankaccounts){
            context.commit('bankaccount/resetState')
            context.dispatch('bankaccount/getAllBankAccounts')
            context.dispatch('bankaccount/getAllSupplierBankAccounts')
        }
        // check buyers
        if(currentBuyers != tableUpdates?.customers){
            context.commit('buyer/resetState')
            context.dispatch('buyer/getAllActiveBuyers')
        }
        // check constants
        if (currentConstants != tableUpdates?.constants) {
            context.commit('resetConstantsState')
        }
        // check countries
        if(currentCountries != tableUpdates?.countries){
            context.commit('country/resetState')
            context.dispatch('country/getAllCountries')
        }
        // check doctypes
        if (currentDoctypes != tableUpdates?.doctypes) {
            context.commit('document/resetState')
        }
        //check contracts
        if(currentContracts != tableUpdates?.contracts){
            context.commit('contract/resetState')
            context.dispatch('contract/getAllActiveContracts')
        }
        //check grades
        if(currentGrades != tableUpdates?.grades){
            context.commit('grade/resetState')
            context.dispatch('grade/getAllGrades')
        }
        //check invoices
        if(currentInvoices != tableUpdates?.invoices){
            context.commit('invoice/resetState')
            context.dispatch('invoice/getAllActiveInvoices')
        }
        //check ipas
        if(currentIpas != tableUpdates?.ipas){
            context.commit('ipa/resetState')
            context.dispatch('ipa/getAllActiveIpas')
        }
        //check lcas
        if(currentLcas != tableUpdates?.lcas){
            context.commit('lca/resetState')
            context.dispatch('lca/getAllActiveLcas')
        }
        //check mcs
        if(currentMoistureContent != tableUpdates?.mcs){
            context.commit('mc/resetState')
            context.dispatch('mc/getAllMoistureContent')
        }
        //check paymentstatements
        if(currentPaymentstatements != tableUpdates?.paymentstatements){
            context.commit('paymentterm/resetState')
            context.dispatch('paymentterm/getAllPaymentTerms')
        }
        //check pldos
        if(currentPackinglists != tableUpdates?.pldos){
            context.commit('packinglist/resetState')
            context.dispatch('packinglist/getAllActivePackingLists')
        }
        //check productgroups
        if(currentProductGroups != tableUpdates?.productgroups){
            context.commit('productgroup/resetState')
            context.dispatch('productgroup/getAllProductGroups')
        }
        //check purchaseorders
        if(currentPurchaseorders != tableUpdates?.purchaseorders){
            context.commit('purchaseorder/resetState')
            context.dispatch('purchaseorder/getAllActivePurchaseOrders')
        }
        //check shipping instructions
        if(currentSdas != tableUpdates?.sdas){
            context.commit('sda/resetState')
            context.dispatch('sda/getAllActiveSdas')
        }
        //check sizes
        if(currentSizes != tableUpdates?.sizes){
            context.commit('size/resetState')
            context.dispatch('size/getAllSizes')
        }
        //check shipping ports
        if(currentShippingports != tableUpdates?.shippingports){
            context.commit('shippingport/resetState')
            context.dispatch('shippingport/getAllShippingPorts')
        }
        //check species
        if(currentSpecies != tableUpdates?.species){
            context.commit('species/resetState')
            context.dispatch('species/getAllSpecies')
        }
        //check specs
        if(currentSpecs != tableUpdates?.specs){
            context.commit('spec/resetState')
            context.dispatch('spec/getAllSpecs')
        }
        //check suppliers
        if(currentSuppliers != tableUpdates?.suppliers){
            context.commit('supplier/resetState')
            context.dispatch('supplier/getAllSuppliers')
        }
        //check thickness
        if(currentThickness != tableUpdates?.thicknesses){
            context.commit('thickness/resetState')
            context.dispatch('thickness/getAllThickness')
        }
        //check cover notes/ttas
        if (currentTtas != tableUpdates?.ttas) {
            context.commit('covernote/resetState')
            context.dispatch('covernote/getAllCoverNotes')
        }
        //check warehouse purchase requests/wprs
        if (currentWprs != tableUpdates?.wprs) {
            context.commit('wpr/resetState')
            context.dispatch('wpr/getAllActiveWprs')
        }
        //check standard asset documents
        if(currentStandardAssetDocument != tableUpdates?.standard_asset_documents){
            context.commit('document/resetStandardShipmentDocumentState')
            context.dispatch('document/getAllStandardShippingDocuments')
        }

        //dont update package version on login
        //if(currentCodeVersion != tableUpdates?.code_version) {
        //    context.commit('updatePackageVersion')
        //}

        //update localstorage
        localStorage.setItem('tableUpdates',JSON.stringify(tableUpdates))
    },
    updateClosedSessions(context, closedSessions) {
        localStorage.setItem('closedSessions', closedSessions)
    }
    // signinUserWithGoogle(context) {
    //     context.commit('loginUser');
    //     firebase.auth().signInWithPopup(googleAuthProvider).then((result) => {
    //         Nprogress.done();
    //         setTimeout(() => {
    //             context.commit('loginUserSuccess', result.user);
    //         }, 500)
    //     }).catch(error => {
    //         context.commit('loginUserFailure', error);
    //     });
    // },
    // signupUserInFirebase(context, payload) {
    //     let { userDetail } = payload;
    //     context.commit('signUpUser');
    //     firebase.auth().createUserWithEmailAndPassword(userDetail.email, userDetail.password)
    //         .then(() => {
    //             Nprogress.done();
    //             setTimeout(() => {
    //                 context.commit('signUpUserSuccess', userDetail);
    //             }, 500)
    //         })
    //         .catch(error => {
    //             context.commit('signUpUserFailure', error);
    //         })
    // },
    // signInUserWithAuth0(context, payload) {
    //     context.commit('signInUserWithAuth0Success', payload);
    // },
}

// mutations
const mutations = {
    loginUser(state) {
        Nprogress.start();
    },
    loginUserSuccess(state, user) {
        state.user = user;
        localStorage.setItem('user',JSON.stringify(user));
        localStorage.removeItem('sessionExpired');
        // localStorage.removeItem('concurrentLogin');
        state.isUserSignInWithAuth0 = false
        if(localStorage.getItem('lastRoute') != null && localStorage.getItem('lastRoute').length > 0){
            router
                .push(localStorage.getItem('lastRoute'))
        } else {
            router
                .push("/v1/dashboard/standard")
                .catch(err => {
                    console.log('error')
                })
        }
        setTimeout(function(){
            Vue.prototype.$toast.success('Logged in.',
                {
                    timeout: 6000,
                    classes: ['icon-float-left'],
                    icon: 'check_circle_outline',
                    x: 'center',
                    y: 'top'
                }
            )
            //empty last route
            localStorage.removeItem('lastRoute')
       },500);
    },
    loginUserFailure(state, error) {
        Nprogress.done();
        Vue.prototype.$toast.error(error,
            {
                classes: ['icon-float-left'],
                icon: 'check_circle_outline',
                x: 'center',
                y: 'top'
            }
        )
    },
    logoutUser( state ) {
        state.user = null
        localStorage.removeItem('user');

        //TODO delete vuex key from indexedDB

        const deleteVuex = async() => {
            console.log('deleting vuex')
            const dbName = 'localforage'
            const storeName = 'keyvaluepairs'
            const version = 2
            const db = await openDB(dbName, version, {
                // upgrade(db, oldVersion, newVersion, transaction) {
                //     const store = db.createObjectStore(storeName)
                // }
            })
            const tx = await db.transaction(storeName, 'readwrite')
            const store = await tx.objectStore(storeName)
            const key = 'vuex'
            await store.delete(key)
            await tx.done
        }
        deleteVuex()
        // router.push("/session/login");
    },
    // signUpUser(state) {
    //     Nprogress.start();
    // },
    // signUpUserSuccess(state, user) {
    //     state.user = localStorage.setItem('user', user);
    //     router.push("/v1/dashboard/standard");
    //     Vue.notify({
    //         group: 'loggedIn',
    //         type: 'success',
    //         text: 'Account Created!'
    //     });
    // },
    // signUpUserFailure(state, error) {
    //     Nprogress.done();
    //      Vue.notify({
    //         group: 'loggedIn',
    //         type: 'error',
    //         text: error.message
    //     });
    // },
    // signInUserWithAuth0Success(state, user) {
    //     state.user = user;
    //     localStorage.setItem('user',JSON.stringify(user));
    //     state.isUserSignInWithAuth0 = true;
    // },
    // signOutUserFromAuth0Success(state) {
    //     state.user = null
    //     localStorage.removeItem('user')
    // },
    updateUserToken(state, user) {
        state.user = user;
        localStorage.setItem('user',JSON.stringify(user));
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
