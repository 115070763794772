/**
 * Firebase Login
 * Vuely comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Initialize Firebase 
const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY, // Your Api key will be here
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN, // Your auth domain
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL, // data base url
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID, // project id
    storageBucket: '', // storage bucket
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID, // messaging sender id
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

firebase.initializeApp(config);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = firebase.database();

export {
    auth,
    googleAuthProvider,
    githubAuthProvider,
    facebookAuthProvider,
    twitterAuthProvider,
    database
};
