import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './modules/auth';
import settings from './modules/settings';
import sidebar from './modules/sidebar';
import appic from './modules/appic';
// import createPersistedState from 'vuex-persistedstate';
import VuexPersistence from "vuex-persist";
import localforage from "localforage";
// import { abilityPlugin, ability as appAbility } from './modules/appic/ability';
import { Ability } from "@casl/ability";
import  abilityPlugin  from './modules/appic/ability';
import {sdaaddress} from "@/store/modules/appic/sdaaddress";
// import {TYPE_KEY} from "Helpers/helpers";

Vue.use(Vuex);

// export const ability = appAbility

const vuexIndexedDb = new VuexPersistence({
    storage: localforage,
    asyncStorage: true,
    reducer: ( state ) => ({
        appic: {
            agent: state.appic.agent,
            bankaccount: state.appic.bankaccount,
            buyer: state.appic.buyer,
            commission: state.appic.commission,
            contact: state.appic.contact,
            contract: state.appic.contract,
            country: state.appic.country,
            courier: state.appic.courier,
            covernote: state.appic.covernote,
            currency: state.appic.currency,
            customeraddress: state.appic.customeraddress,
            debitnote: state.appic.debitnote,
            document: state.appic.document,
            etdadjustment: state.appic.etdadjustment,
            epr: state.appic.epr,
            epritem: state.appic.epritem,
            grade: state.appic.grade,
            grossmargin: state.appic.grossmargin,
            help: state.appic.help,
            incoterm: state.appic.incoterm,
            invoice: state.appic.invoice,
            invoicesalesitem: state.appic.invoicesalesitem,
            ipa: state.appic.ipa,
            lca: state.appic.lca,
            lcaclause: state.appic.lcaclause,
            localsalesinvoice: state.appic.localsalesinvoice,
            localsalesitem: state.appic.localsalesitem,
            manualinvoice: state.appic.manualinvoice,
            manualinvoiceitem: state.appic.manualinvoiceitem,
            mc: state.appic.mc,
            ncr: state.appic.ncr,
            ncritem: state.appic.ncritem,
            packinglist: state.appic.packinglist,
            packinglistitem: state.appic.packinglistitem,
            paymentterm: state.appic.paymentterm,
            paymenttransaction: state.appic.paymenttransaction,
            posalesitem: state.appic.posalesitem,
            productsegment: state.appic.productsegment,
            purchaseorder: state.appic.purchaseorder,
            purchaseorderitem: state.appic.purchaseorderitem,
            runtime: state.appic.runtime,
            salesbudget: state.appic.salesbudget,
            salescolleague: state.appic.salescolleague,
            salesitem: state.appic.salesitem,
            salesorder: state.appic.salesorder,
            salesorderitem: state.appic.salesorderitem,
            sda: state.appic.sda,
            sdaaddress: state.appic.sdaaddress,
            sdadocument: state.appic.sdadocument,
            sdaclause: state.appic.sdaclause,
            shippingport: state.appic.shippingport,
            size: state.appic.size,
            spec: state.appic.spec,
            species: state.appic.species,
            specification: state.appic.specification,
            stock: state.appic.stock,
            stockorder: state.appic.stockorder,
            supplier: state.appic.supplier,
            thickness: state.appic.thickness,
            user: state.appic.user,
            wpr: state.appic.wpr,
            wpritem: state.appic.wpritem,
            xero: state.appic.xero
        },
        auth: auth.state,
        settings: settings.state,
        sidebar: sidebar.state
    })
})

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => (
        {
            rules: state.rules,
            versionUpdated: state.versionUpdated
        }
    )
})

export const store = new Vuex.Store({
    plugins: [
        vuexIndexedDb.plugin,
        vuexLocal.plugin,
        abilityPlugin
    ],
    modules: {
        auth,
        settings,
        sidebar,
        appic
    },
    state: {
        // packageVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')).version
        // rules: [{action: 'update', subject: 'Contract'}]
        versionUpdated: false,
        rules: [],
    },
    getters: {
        // appVersion: (state) => {return state.packageVersion}
        ability() {
            return new Ability()
        }
    },
    mutations: {
        createRules(state, rules){
            state.rules = rules
        },
        destroyRules(state){
            state.rules = []
        },
        updatePackageVersion(state) {
            // console.log('Package version updated...')
            state.versionUpdated = true
        },
        resetPackageVersion(state) {
            // console.log('Package version reset...')
            state.versionUpdated = false
        }
    }
    // plugins: [
    //     createPersistedState(
    //         {
    //             paths: [
    //                 'appic.agent',
    //                 'appic.bankaccount',
    //                 'appic.buyer',
    //                 'appic.contact',
    //                 'appic.contract',
    //                 'appic.country',
    //                 'appic.covernote',
    //                 'appic.debitnote',
    //                 'appic.document',
    //                 'appic.grade',
    //                 'appic.incoterm',
    //                 'appic.invoice',
    //                 'appic.invoicesalesitem',
    //                 'appic.ipa',
    //                 'appic.lca',
    //                 'appic.lcaclause',
    //                 'appic.localsalesinvoice',
    //                 'appic.localsalesitem',
    //                 'appic.manualinvoice',
    //                 'appic.manualinvoiceitem',
    //                 'appic.packinglist',
    //                 'appic.packinglistitem',
    //                 'appic.paymentterm',
    //                 'appic.paymenttransaction',
    //                 'appic.posalesitem',
    //                 'appic.productsegment',
    //                 'appic.purchaseorder',
    //                 'appic.purchaseorderitem',
    //                 'appic.runtime',
    //                 'appic.salescolleague',
    //                 'appic.salesitem',
    //                 'appic.salesorder',
    //                 'appic.salesorderitem',
    //                 'appic.sda',
    //                 'appic.sdaclause',
    //                 // 'shipment',
    //                 'appic.shippingport',
    //                 'appic.size',
    //                 'appic.spec',
    //                 'appic.species',
    //                 'appic.specification',
    //                 'appic.supplier',
    //                 'appic.user',
    //                 'appic.wpr',
    //                 'appic.wpritem'
    //             ],
    //             reducer (val) {
    //                 if(val.auth.user == null){
    //                     return {}
    //                 }
    //                 return val
    //             }
    //         }
    //     )
    // ],
    // strict: true,
})
