import {getField, updateField} from "vuex-map-fields";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		contractScheduleCurrentTab: null,
		dashboardCurrentSalesColleague: null,
		dashboardCurrentTab: null,
		financeDataOverviewCurrentTab: null,
		financeReportsCurrentTab: null,
		fscCurrentAudit: null,
		fscPefcReportCurrentTab: null,
		fscPefcShowNoInvoices: 1,
		fscReportsCurrentTab: null,
		invoicesOverviewCurrentTab: null,
		miscellaneousAdminCurrentTab: null,
		openedTabs: [],
		operationsDataOverviewCurrentTab: null,
		pefcCurrentAudit: null,
		pefcReportsCurrentTab: null,
		salesCommissionsOverviewCurrentTab: null,
		salesDataOverviewCurrentTab: null,
		shippingDocumentsOverviewCurrentTab: null,
		shippingInstructionDocumentsManagerCurrentTab: null,
		shippingOverviewCurrentTab: null,
		specificationsDataOverviewCurrentTab: null,
		stockCurrentWarehouse: null,
		stockReportsCurrentTab: null,
		stockReportsCurrentWarehouse: null,
		updateAvailable: false,
		xeroIntegrationsCurrentTab: null
	}
}

const state = {
	contractScheduleCurrentTab: null,
	dashboardCurrentSalesColleague: null,
	dashboardCurrentTab: null,
	financeDataOverviewCurrentTab: null,
	financeReportsCurrentTab: null,
	fscCurrentAudit: null,
	fscPefcReportCurrentTab: null,
	fscPefcShowNoInvoices: 1,
	fscReportsCurrentTab: null,
	invoicesOverviewCurrentTab: null,
	miscellaneousAdminCurrentTab: null,
	openedTabs: [],
	operationsDataOverviewCurrentTab: null,
	pefcCurrentAudit: null,
	pefcReportsCurrentTab: null,
	salesCommissionsOverviewCurrentTab: null,
	salesDataOverviewCurrentTab: null,
	shippingDocumentsOverviewCurrentTab: null,
	shippingInstructionDocumentsManagerCurrentTab: null,
	shippingOverviewCurrentTab: null,
	specificationsDataOverviewCurrentTab: null,
	stockCurrentWarehouse: null,
	stockReportsCurrentTab: null,
	stockReportsCurrentWarehouse: null,
	updateAvailable: false,
	xeroIntegrationsCurrentTab: null
}

const getters = {
	getField
}

const mutations = {
	updateField,
	removeOpenedTab(state, tabName){
		let idx = state.openedTabs.indexOf(tabName)
		state.openedTabs.splice(idx, 1)
	}
}

const actions = {
	removeOpenedTab({commit}, tabName) {
		commit('removeOpenedTab',tabName)
	}
}

export const runtime = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}