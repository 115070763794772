// import MiniSidebarLayout from 'Container/MiniSidebarLayout'
import Full from 'Container/Full'

//APPiC components
const AddBuyer = () => import('Views/v1/customers/AddBuyer');
const AddCommissionInvoice = () => import('Views/v1/debitnotes/AddCommissionInvoice');
const AddContract = () => import('Views/v1/contracts/AddContract');
const AddCoverNote = () => import('Views/v1/covernotes/AddCoverNote');
const AddEpr = () => import('Views/v1/eprs/AddEpr');
const AddGrossMargin = () => import('Views/v1/accounts/AddGrossMargin');
const AddInvoice = () => import('Views/v1/invoices/AddInvoice');
const AddIpa = () => import('Views/v1/ipas/AddIpa');
const AddLca = () => import('Views/v1/lcas/AddLca');
const AddLocalSalesInvoice = () => import('Views/v1/localsales/AddLocalSalesInvoice');
const AddNcr = () => import('Views/v1/ncrs/AddNcr');
const AddPackingList = () => import('Views/v1/pldos/AddPackingList');
const AddPayment = () => import('Views/v1/payments/AddPayment')
const AddPurchaseOrder = () => import('Views/v1/purchaseorders/AddPurchaseOrder');
const AddSalesOrder = () => import('Views/v1/salesorders/AddSalesOrder');
const AddSda = () => import('Views/v1/sdas/AddSda');
const AddStockOrder = () => import('Views/v1/stockorders/AddStockOrder');
const AddSupplier = () => import('Views/v1/suppliers/AddSupplier');
const AddUser = () => import('Views/v1/users/AddUser');
const AddWarehousePurchaseRequest = () => import('Views/v1/wprs/AddWpr');
const AppicReport = () => import('Views/v1/reports/AppicReport');
const AppicSettings = () => import('Views/v1/admin/AppicSettings');
// const BankAccountsListing = () => import('Views/v1/bankaccounts/Listing');
const BankAccountProfile = () => import('Views/v1/bankaccounts/Profile');
const BuyerLocator = () => import('Views/v1/customers/BuyerLocator');
const BuyersOverview = () => import('Views/v1/customers/BuyersOverview');
const CommissionsListing = () => import('Views/v1/contracts/CommissionsListing');
const ContractsOverview = () => import('Views/v1/contracts/ContractsOverview');
const Currencyrates = () => import('Views/v1/currencyrates/Listing');
// const CustomersListing = () => import('Views/v1/customers/Listing');
// const CountriesListing = () => import('Views/v1/datamanagement/Countries');
const Dashboard = () => import('Views/v1/dashboard/Dashboard');
const DocumentManager = () => import('Views/v1/sdas/DocumentManager');
const EprsOverview = () => import('Views/v1/eprs/EprsOverview')
const ExportStatusBoard = () => import('Views/v1/boards/ExportBoard');
const FinanceDataOverview = () => import('Views/v1/datamanagement/FinanceDataOverview');
const FinanceReports = () => import('Views/v1/reports/FinanceReports');
const FscPefcReport = () => import('Views/v1/reports/FscPefcReport');
const GrossMarginsOverview = () => import('Views/v1/accounts/GrossMarginsOverview');
const HelpManual = () => import('Views/v1/pages/HelpManual');
const HubSpotDeals = () => import('Views/v1/reports/HubSpotDeals');
const InternalRemarksListing = () => import('Views/v1/datamanagement/InternalRemarks');
const InvoicesOverview = () => import('Views/v1/invoices/InvoicesOverview');
const MarketingReportV4 = () => import('Views/v1/reports/MarketingReportV4');
// const MarketSegmentsListing = () => import('Views/v1/datamanagement/MarketSegments');
const MiscellaneousAdmin = () => import('Views/v1/admin/MiscellaneousAdmin')
const NcrsOverview = () => import('Views/v1/ncrs/NcrsOverview')
const NotFound = () => import('Views/v1/pages/NotFound');
// const PaymentStatementslisting = () => import('Views/v1/datamanagement/PaymentStatements');
const OperationsDataOverview = () => import('Views/v1/datamanagement/OperationsDataOverview');
const PaymentsSchedule = () => import('Views/v1/payments/PaymentsOverview');
const PdfServer = () => import('Views/v1/admin/PdfServer');
const ProfitAndLossForecast = () => import('Views/v1/reports/ProfitAndLossForecast');
const PurchaseOrdersOverview = () => import('Views/v1/purchaseorders/PurchaseOrdersOverview');
const PurchaseOrderStatusBoard = () => import('Views/v1/boards/PoBoard');
// const ProductSegmentsListing = () => import('Views/v1/datamanagement/ProductSegments');
// const ProductGroupsListing = () => import('Views/v1/datamanagement/ProductGroups');
const ReportDetail = () => import('Components/Appic/MarketingReport/ReportDetail');
const SalesCommissionsOverview = () => import('Views/v1/commissions/SalesCommissionsOverview');
const SalesDataOverview = () => import('Views/v1/datamanagement/SalesDataOverview');
const SalesOrdersOverview = () => import('Views/v1/salesorders/SalesOrdersOverview');
const SalesPersonsListing = () => import('Views/v1/datamanagement/SalesPersons');
const SearchResults = () => import('Views/v1/pages/SearchResults');
const ShipmentsOverview = () => import('Views/v1/shipments/ShipmentsOverview');
// const ShippingInstructionsListing = () => import('Views/v1/sdas/Listing');
// const ShippingInstructionsForm = () => import('Views/v1/sdas/ShippingInstructionForm');
const ShippingAgentsListing = () => import('Views/v1/datamanagement/ShippingAgents');
const ShippingDocumentsOverview = () => import('Views/v1/shipments/ShippingDocumentsOverview');
const ShippingPortsListing = () => import('Views/v1/datamanagement/ShippingPorts');
// const Specifications = () => import('Views/v1/datamanagement/Specifications');
const SpecificationsDataOverview = () => import('Views/v1/datamanagement/SpecificationsDataOverview');
// const StocksListing = () => import('Views/v1/stocks/Listing');
const StandardDataFilters = () => import('Views/v1/admin/StandardDataFilters');
const StocksOverview = () => import('Views/v1/stocks/StocksOverview');
// const StockOrderListing = () => import('Views/v1/stocks/StockOrderListing');
const StockOrdersOverview = () => import('Views/v1/stockorders/StockOrdersOverview');
const StockReports = () => import('Views/v1/reports/StockReports');
const SuppliersOverview = () => import('Views/v1/suppliers/SuppliersOverview');
const UpdateContract = () => import('Views/v1/contracts/AddContract');
const UpdateShipment = () => import('Views/v1/shipments/UpdateShipment');
// const UsersListing = () => import('Views/v1/users/Listing');
// const UserAccessManager = () => import('Views/v1/users/UserAccessManager');
const UsersOverview = () => import('Views/v1/users/UsersOverview');
const WarehousePurchaseRequestOverview = () => import('Views/v1/wprs/WprsOverview');
const XeroOverview = () => import('Views/v1/xero/XeroOverview');
const ZohoReports = () => import('Views/v1/admin/ZohoReports');

export default {
    path: '/',
    component: Full,
    redirect: '/v1/dashboard/standard',
    children: [
        {
            path: '/v1/dashboard/standard',
            name: 'my_dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
                title: 'message.myDashboard',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'Dashboards'
                    },
                    {
                        breadcrumbActive: 'Zoho'
                    }
                ]
            }
        },
        {
           path: '/v1/reports/appic-report',
           component: AppicReport,
           meta: {
               requiresAuth: true,
               title: 'message.appicReport',
               breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.appic'
                   }
               ],
           }
        },
        {
           path: '/v1/reports/marketing-report',
           component: MarketingReportV4,
           meta: {
               requiresAuth: true,
               title: 'message.marketingReport',
               breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.marketing'
                   }
               ]
           }
        },
        {
           path: '/v1/reports/fsc-pefc',
           component: FscPefcReport,
           meta: {
               requiresAuth: true,
               title: 'message.fscPefcReport',
               breadcrumb: [
                   {
                       breadcrumbInactive: 'message.reports'
                   },
                   {
                       breadcrumbActive: 'message.fscPefc'
                   }
               ]
           }
        },
        {
            path: '/v1/reports/pl-forecast',
            component: ProfitAndLossForecast,
            meta: {
                requiresAuth: true,
                title: 'message.profitAndLossForecast',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.reports'
                    },
                    {
                        breadcrumbActive: 'message.profitAndLoss'
                    }
                ]
            }
        },
        {
            path: '/v1/contracts/add',
            name: 'add_contract',
            component: AddContract,
            meta: {
                requiresAuth: true,
                title: 'message.addContract',
                breadcrumb: [
                    {
                       breadcrumbInactive: 'message.contract'
                    },
                    {
                       breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            }
        },
        {
            path: '/v1/contracts/overview',
            name: 'contract_listing',
            component: ContractsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.contracts',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contract'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/contracts/update/:contractId',
            name: 'update_contract',
            component: UpdateContract,
            meta: {
                requiresAuth: true,
                title: 'message.updateContract',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contract'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/covernotes/add',
            name: 'add_covernote',
            component: AddCoverNote,
            meta: {
                requiresAuth: true,
                title: 'message.addCoverNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.coverNote'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/covernotes/update/:coverNoteId',
            name: 'update_covernote',
            component: AddCoverNote,
            meta: {
                requiresAuth: true,
                title: 'message.addCoverNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.coverNote'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/debitnotes/add/:contractId?',
            name: 'add_debitnote',
            component: AddCommissionInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.addDebitNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.debitNote'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/debitnotes/update/:debitNoteId?',
            name: 'update_debitnote',
            component: AddCommissionInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.updateDebitNote',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.debitNote'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/overview',
            name: 'gross_margin_listing',
            component: GrossMarginsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.grossMargins',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMargin'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/add/:source/:sourceId',
            name: 'add_gross_margin',
            component: AddGrossMargin,
            meta: {
                requiresAuth: true,
                title: 'message.addGrossMarginCalculation',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMarginCalculation'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/gross-margins/update/:grossMarginId',
            name: 'update_gross_margin',
            component: AddGrossMargin,
            meta: {
                requiresAuth: true,
                title: 'message.updateGrossMargin',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.grossMargin'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/add/:contractId?',
            name: 'add_invoice',
            component: AddInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.addInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/update/:invoiceId?',
            name: 'update_invoice',
            component: AddInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.updateInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/invoices/overview',
            name: 'invoice_listing',
            component: InvoicesOverview,
            meta: {
                requiresAuth: true,
                title: 'message.invoices',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.invoice'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        // {
        //     path: '/v1/debitnotes/add',
        //     name: 'add_debitnote',
        //     component: AddCommissionInvoice,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.addCommissionInvoice',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'Commission Invoice'
        //             },
        //             {
        //                 breadcrumbActive: 'message.add'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        {
            path: '/v1/shipping-documents/overview',
            name: 'shipping_documents_listing',
            component: ShippingDocumentsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.shippingDocumentsOverview',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shippingDocuments'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/packinglists/add/:contractId?',
            name: 'add_packinglist',
            component: AddPackingList,
            meta: {
                requiresAuth: true,
                title: 'message.addPackingList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.packingList'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/packinglists/update/:packingListId',
            name: 'update_packinglist',
            component: AddPackingList,
            meta: {
                requiresAuth: true,
                title: 'message.updatePackingList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.packingList'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ipas/add/:contractId?',
            name: 'add_ipa',
            component: AddIpa,
            meta: {
                requiresAuth: true,
                title: 'message.addImportPermitAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.ipa'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ipas/update/:ipaId?',
            name: 'update_ipa',
            component: AddIpa,
            meta: {
                requiresAuth: true,
                title: 'message.updateImportPermitAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.ipa'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/lcas/add/:contractId?',
            name: 'add_lca',
            component: AddLca,
            meta: {
                requiresAuth: true,
                title: 'message.addLetterOfCreditAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.lca'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/lcas/update/:lcaId?',
            name: 'update_lca',
            component: AddLca,
            meta: {
                requiresAuth: true,
                title: 'message.updateLetterCreditAdvice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.lca'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/localsales/add/:salesOrderId?',
            name: 'add_localsale',
            component: AddLocalSalesInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.addLocalSalesInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.localSalesInvoice'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/localsales/update/:localSalesInvoiceId?',
            name: 'update_localsale',
            component: AddLocalSalesInvoice,
            meta: {
                requiresAuth: true,
                title: 'message.updateLocalSalesInvoice',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.localSalesInvoice'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/overview',
            name: 'purchaseorder_listing',
            component: PurchaseOrdersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.purchaseOrders',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/update/:purchaseOrderId?',
            name: 'update_purchaseorder',
            component: AddPurchaseOrder,
            meta: {
                requiresAuth: true,
                title: 'message.updatePurchaseOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/purchaseorders/add',
            name: 'add_purchaseorder',
            component: AddPurchaseOrder,
            meta: {
                requiresAuth: true,
                title: 'message.addPurchaseOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.purchaseOrder'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/payments/overview',
            component: PaymentsSchedule,
            meta: {
                requiresAuth: true,
                title: 'message.payments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.payment'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/payments/update/:salesType/:documentId',
            name: 'update_payments',
            component: AddPayment,
            meta: {
                requiresAuth: true,
                title: 'message.updatePayments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.payments'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/overview',
            name: 'salesorder_listing',
            component: SalesOrdersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.salesOrders',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/add',
            name: 'add_salesorder',
            component: AddSalesOrder,
            meta: {
                requiresAuth: true,
                title: 'message.addSalesOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salesorders/update/:salesOrderId?',
            name: 'update_salesorder',
            component: AddSalesOrder,
            meta: {
                requiresAuth: true,
                title: 'message.updateSalesOrder',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesOrder'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sdas/add/:contractId?',
            name: 'add_sda',
            component: AddSda,
            meta: {
                requiresAuth: true,
                title: 'message.addShippingInstruction',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.sda'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sdas/update/:sdaId?',
            name: 'update_sda',
            component: AddSda,
            meta: {
                requiresAuth: true,
                title: 'message.updateShippingInstruction',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.sda'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stocks/overview',
            name: 'stocks_listing',
            component: StocksOverview,
            meta: {
                requiresAuth: true,
                title: 'message.masterStockList',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/stocks/reports',
            name: 'stocks_reports',
            component: StockReports,
            meta: {
                requiresAuth: true,
                title: 'message.stockReport',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/contracts/commissions-listing',
            component: CommissionsListing,
            meta: {
                requiresAuth: true,
                title: 'message.salesCommissions',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'Sales Commissions'
                    },
                    {
                        breadcrumbActive: 'Listing'
                    }
                ]
            }
        },
        {
            path: '/v1/currencyrates',
            component: Currencyrates,
            meta: {
                requiresAuth: true,
                title: 'message.exchangeRates',
                breadcrumb: [
                    {
                        breadcrumbActive: 'Exchange Rates'
                    }
                ]
            }
        },
        {
            path: '/v1/customers',
            name: 'customer_listing',
            component: BuyersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.buyerProfiles',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.profiles'
                    }
                ]
            }
        },
        {
            path: '/v1/customers/map',
            name: 'customer_locator',
            component: BuyerLocator,
            meta: {
                requiresAuth: true,
                title: 'message.buyerSupplierLocations',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.locations'
                    }
                ]
            }
        },
        {
            path: '/v1/customers/update/:buyerId?',
            name: 'update_customer',
            component: AddBuyer,
            meta: {
                requiresAuth: true,
                title: 'message.buyerProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.profile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/customers/add',
            name: 'add_customer',
            component: AddBuyer,
            meta: {
                requiresAuth: true,
                title: 'message.newBuyerProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.buyer'
                    },
                    {
                        breadcrumbActive: 'message.newProfile'
                    }
                ]
            },
            props: true
        },
        // {
        //     path: '/v1/countries',
        //     component: CountriesListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.countryListing',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.countries'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/marketsegments',
        //     component: MarketSegmentsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.marketSegmentListing',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.marketSegments'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/productsegments',
        //     component: ProductSegmentsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.productSegmentListing',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.productSegments'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/productgroups',
        //     component: ProductGroupsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.productGroupListing',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.productGroups'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        {
            path: '/v1/suppliers',
            name: 'supplier_listing',
            component: SuppliersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.supplierProfiles',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.suppliers'
                    },
                    {
                        breadcrumbActive: 'message.profiles'
                    }
                ]
            }
        },
        {
            path: '/v1/suppliers/update/:supplierId?',
            name: 'update_supplier',
            component: AddSupplier,
            meta: {
                requiresAuth: true,
                title: 'message.supplierProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.supplier'
                    },
                    {
                        breadcrumbActive: 'message.profile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/suppliers/add',
            name: 'add_supplier',
            component: AddSupplier,
            meta: {
                requiresAuth: true,
                title: 'message.supplierProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.suppliers'
                    },
                    {
                        breadcrumbActive: 'message.newProfile'
                    }
                ]
            },
            props: true
        },
        // {
        //     path: '/v1/specifications',
        //     component: Specifications,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.specifications',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.specifications'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/paymentstatements',
        //     component: PaymentStatementslisting,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.termsOfPayment',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.termsOfPayment'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        {
            path: '/v1/internalremarks',
            component: InternalRemarksListing,
            meta: {
                requiresAuth: true,
                title: 'message.internalRemarks',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.internalRemarks'
                    },
                    {
                        breadcrumbActive: 'message.listing'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shippinginstructions/document-manager',
            component: DocumentManager,
            meta: {
                requiresAuth: true,
                title: 'message.siDocumentManager',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shippingInstructions'
                    },
                    {
                        breadcrumbActive: 'message.documentManager'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/statusboards/export',
            component: ExportStatusBoard,
            meta: {
                requiresAuth: true,
                title: 'message.exportBoard',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.statusBoard'
                    },
                    {
                        breadcrumbActive: 'message.export'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/statusboards/purchaseorders',
            component: PurchaseOrderStatusBoard,
            meta: {
                requiresAuth: true,
                title: 'message.poBoard',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.statusBoard'
                    },
                    {
                        breadcrumbActive: 'message.purchaseOrders'
                    }
                ]
            },
            props: true
        },
        // {
        //     path: '/v1/agents',
        //     component: ShippingAgentsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.shippingAgents',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.shippingAgents'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/bankaccounts',
        //     component: BankAccountsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.bankAccounts',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.bankAccounts'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/shippingports',
        //     component: ShippingPortsListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.shippingPorts',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: 'message.shippingPorts'
        //             },
        //             {
        //                 breadcrumbActive: 'message.listing'
        //             }
        //         ]
        //     },
        //     props: true
        // },
        {
            path: '/v1/bankaccounts/profile/:ba_id',
            component: BankAccountProfile,
            meta: {
                requiresAuth: true,
                title: 'message.bankAccountProfile',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.'
                    },
                    {
                        breadcrumbActive: 'message.profile'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/salescontacts',
            component: SalesPersonsListing,
            meta: {
                requiresAuth: true,
                title: 'message.marketingColleagues',
                breadcrumb: [
                    {
                        breadcrumbInactive: ''
                    },
                    {
                        breadcrumbActive: ''
                    }
                ]
            },
            props: true
        },
        // {
        //     path: '/v1/users',
        //     component: UsersListing,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.appicUsers',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: ''
        //             },
        //             {
        //                 breadcrumbActive: ''
        //             }
        //         ]
        //     },
        //     props: true
        // },
        // {
        //     path: '/v1/users/user-access-manager/:user_id?',
        //     component: UserAccessManager,
        //     meta: {
        //         requiresAuth: true,
        //         title: 'message.userAccessManager',
        //         breadcrumb: [
        //             {
        //                 breadcrumbInactive: ''
        //             },
        //             {
        //                 breadcrumbActive: ''
        //             }
        //         ]
        //     },
        //     props: true
        // },
        {
            path: '/v1/search/:searchKeyword?',
            component: SearchResults,
            name: 'search',
            meta: {
                requiresAuth: true,
                title: 'message.searchResults',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.search'
                    },
                    {
                        breadcrumbActive: 'message.results'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/overview',
            name: 'stockorders_listing',
            component: StockOrdersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.stockPoSummary',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stocks'
                    },
                    {
                        breadcrumbActive: 'message.incomingAndArrivals'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/add',
            name: 'add_stockorder',
            component: AddStockOrder,
            meta: {
                requiresAuth: true,
                title: 'message.newStockEntry',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockArrival'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/stockorders/update/:stockOrderId?',
            name: 'update_stockorder',
            component: AddStockOrder,
            meta: {
                requiresAuth: true,
                title: 'message.updateStockPo',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPo'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shipments/overview',
            component: ShipmentsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.shipments',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shipments'
                    },
                    {
                        breadcrumbActive: 'Overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/shipments/update/:shipmentContractId',
            name: 'update_shipment',
            component: UpdateShipment,
            meta: {
                requiresAuth: true,
                title: 'message.updateShipment',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.shipments'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/wprs/add',
            name: 'add_wpr',
            component: AddWarehousePurchaseRequest,
            meta: {
                requiresAuth: true,
                title: 'message.addStockPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            }
        },
        {
            path: '/v1/wprs/overview',
            name: 'wpr_listing',
            component: WarehousePurchaseRequestOverview,
            meta: {
                requiresAuth: true,
                title: 'message.stockPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        {
            path: '/v1/wprs/update/:wprId',
            name: 'update_wpr',
            component: AddWarehousePurchaseRequest,
            meta: {
                requiresAuth: true,
                title: 'message.updateStockPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/wprs/operations-overview',
            name: 'wpr_operations_listing',
            component: WarehousePurchaseRequestOverview,
            meta: {
                requiresAuth: true,
                title: 'message.stockPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.stockPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            }
        },
        //Add all data management routes
        {
            path: '/v1/operations-data',
            name: 'operations_data',
            component: OperationsDataOverview,
            meta: {
                requiresAuth: true,
                title: 'message.operationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.operationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/specifications-data',
            name: 'specifications_data',
            component: SpecificationsDataOverview,
            meta: {
                requiresAuth: true,
                title: 'message.specificationsData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.specificationsData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance-data',
            name: 'finance_data',
            component: FinanceDataOverview,
            meta: {
                requiresAuth: true,
                title: 'message.financeData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.financeData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sales-data',
            name: 'sales_data',
            component: SalesDataOverview,
            meta: {
                requiresAuth: true,
                title: 'message.salesData',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesData'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/sales-commissions/overview',
            name: 'commission_listing',
            component: SalesCommissionsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.salesCommissions',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.salesCommissions'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ncrs/overview',
            name: 'ncr_listing',
            component: NcrsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.contractRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/ncrs/add/:salesContactId?/:buyerId?',
            name: 'add_ncr',
            component: AddNcr,
            meta: {
                requiresAuth: true,
                title: 'message.addContractRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/ncrs/update/:ncrId',
            name: 'update_ncr',
            component: AddNcr,
            meta: {
                requiresAuth: true,
                title: 'message.updateContractRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.contractRequest'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/users/add',
            name: 'add_user',
            component: AddUser,
            meta: {
                requiresAuth: true,
                title: 'message.addUser',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/users/overview',
            name: 'user_listing',
            component: UsersOverview,
            meta: {
                requiresAuth: true,
                title: 'message.users',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/users/update/:userId',
            name: 'update_user',
            component: AddUser,
            meta: {
                requiresAuth: true,
                title: 'message.updateUser',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.users'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance/reports',
            name: 'finance_reports',
            component: FinanceReports,
            meta: {
                requiresAuth: true,
                title: 'message.financeReports',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/finance/xero-integrations',
            name: 'xero_integrations',
            component: XeroOverview,
            meta: {
                requiresAuth: true,
                title: 'message.xeroIntegrations',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.finance'
                    },
                    {
                        breadcrumbActive: 'message.reports'
                    }
                ]
            }
        },
        {
            path: '/v1/eprs/overview',
            name: 'epr_listing',
            component: EprsOverview,
            meta: {
                requiresAuth: true,
                title: 'message.exportPurchaseRequests',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: true
        },
        {
            path: '/v1/eprs/add',
            name: 'add_epr',
            component: AddEpr,
            meta: {
                requiresAuth: true,
                title: 'message.addExportPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.add'
                    }
                ],
                stateToUse: 'current'
            },
            props: true
        },
        {
            path: '/v1/eprs/update/:eprId',
            name: 'update_epr',
            component: AddEpr,
            meta: {
                requiresAuth: true,
                title: 'message.updateExportPurchaseRequest',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.exportPurchaseRequests'
                    },
                    {
                        breadcrumbActive: 'message.update'
                    }
                ],
                stateToUse: 'update'
            },
            props: true
        },
        {
            path: '/v1/admin/data-filters',
            name: 'data_filters',
            component: StandardDataFilters,
            meta: {
                requiresAuth: true,
                title: 'message.appicSettings',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.appicSettings'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/appic-settings',
            name: 'appic_settings',
            component: AppicSettings,
            meta: {
                requiresAuth: true,
                title: 'message.appicSettings',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.appicSettings'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/pdf-server',
            name: 'pdf_server',
            component: PdfServer,
            meta: {
                requiresAuth: true,
                title: 'message.pdfServer',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.pdfServer'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/zoho-reports',
            name: 'zoho_reports',
            component: ZohoReports,
            meta: {
                requiresAuth: true,
                title: 'message.zohoReports',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.zohoReports'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/admin/others',
            name: 'admin_others',
            component: MiscellaneousAdmin,
            meta: {
                requiresAuth: true,
                title: 'message.others',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.others'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/marketing-report/detail',
            name: 'marketing_report_detail',
            component: ReportDetail,
            meta: {
                requiresAuth: true,
                title: 'message.marketingReportDetail',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.reports'
                    },
                    {
                        breadcrumbActive: 'message.marketing'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/help',
            name: 'help_manual',
            component: HelpManual,
            meta: {
                requiresAuth: true,
                title: 'message.appicManual',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.help'
                    },
                    {
                        breadcrumbActive: 'message.manual'
                    }
                ]
            },
            props: false
        },
        {
            path: '/v1/reports/hubspot-deals',
            name: 'hubspot_deals',
            component: HubSpotDeals,
            meta: {
                requiresAuth: true,
                title: 'message.hubSpotDeals',
                breadcrumb: [
                    {
                        breadcrumbInactive: 'message.hubSpotDeals'
                    },
                    {
                        breadcrumbActive: 'message.overview'
                    }
                ]
            },
            props: false
        }
   ]
}
