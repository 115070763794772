import { api } from "../../../api"
import {getField, updateField} from "vuex-map-fields"
import _ from 'lodash'

const getDefaultState = () => {
    return {
        allActiveSuspendedBuyers: [],
        allBuyers: [],
        filteredBuyers: [],
        filteredActiveSuspendedBuyers: [],
        Company: {
            id: null,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            country_id: null,
            contacts: [],
            email: null,
            fax: null,
            GPCustomerNumber: null,
            gstno: null,
            latitude: null,
            longitude: null,
            name: null,
            override_ots_country: null,
            postcode: null,
            state: null,
            tel: null,
            uscc: null,
            website: null
        },
        CreditLimit: {
            id: null,
            amount: 0,
            currency_id: 1,
        },
        Customer: {
            id: null,
            banks: [],
            company_id: null,
            cs_paymentstatement_id: null,
            deliveryAddresses: [],
            doc_setting: null,
            ls_paymentstatement_id: null,
            marketsegment_id: null,
            marketsegment2_id: null,
            no_ots_access: null,
            otsname: null,
            password: null,
            paymentstatement_id: null,
            removed: null,
            salescontact_id: null,
            shippingport_id: null,
            shippingport2_id: null,
            territory_id: null,
            title: null,
            userid: null
        }
    }
}

const namespaced = {
    namespaced: true
}

const state = {
    allActiveSuspendedBuyers: [],
    allBuyers: [],
    filteredBuyers: [],
    filteredActiveSuspendedBuyers: [],
    buyerCountries: [],
    buyerSalesColleagues: [],
    buyerProductSegments: [],
    current: {
        Company: {
            id: null,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            country_id: null,
            contacts: [],
            email: null,
            fax: null,
            GPCustomerNumber: null,
            gstno: null,
            latitude: null,
            longitude: null,
            name: null,
            override_ots_country: null,
            postcode: null,
            state: null,
            taxno_type: null,
            tel: null,
            uscc: null,
            website: null
        },
        Country: {
            code: null
        },
        CreditLimit: {
            id: null,
            amount: 0,
            currency_id: 1,
        },
        Customer: {
            id: null,
            banks: [],
            company_id: null,
            cs_paymentstatement_id: null,
            deliveryAddresses: [],
            doc_setting: null,
            ls_paymentstatement_id: null,
            marketsegment_id: null,
            marketsegment2_id: null,
            otsname: null,
            no_ots_access: null,
            password: null,
            paymentstatement_id: null,
            removed: null,
            salescontact_id: null,
            shippingport_id: null,
            shippingport2_id: null,
            territory_id: null,
            title: null,
            userid: null
        },
        Marketsegment: {
            id: null,
            title: null
        },
        Salescontact: {
            id: null,
            name: null
        },
        DirectSalesPaymentTerms: [
            {paymentstatement_id: null, default: true},
            {paymentstatement_id: null, default: false},
            {paymentstatement_id: null, default: false}
        ],
        WarehouseSalesPaymentTerms: [
            {paymentstatement_id: null, default: true},
            {paymentstatement_id: null, default: false}
        ]
    },
    update: {
        Company: {},
        Country: {
            code: null
        },
        CreditLimit: {
            id: null,
            amount: 0,
            currency_id: 1
        },
        Customer: {
            removed: null,
        },
        Marketsegment: {},
        Salescontact: {},
        DirectSalesPaymentTerms: [
            {paymentstatement_id: null, default: true},
            {paymentstatement_id: null, default: false},
            {paymentstatement_id: null, default: false}
        ],
        WarehouseSalesPaymentTerms: [
            {paymentstatement_id: null, default: true},
            {paymentstatement_id: null, default: false}
        ]
    },
    newBuyerSaved: false,
    newBuyerUnsaved: false,
    updatedBuyerUnsaved: false,
    updatedBuyerSaved: false
}

const getters = {
    getField,
    allActiveSuspendedBuyers: state => { return state.allActiveSuspendedBuyers },
    allBuyers: state => { return state.allBuyers },
    buyerCountries: state => { return state.buyerCountries },
    buyerSalesColleagues: state => { return state.buyerSalesColleagues },
    buyerProductSegments: state => { return state.buyerProductSegments },
    findBuyerById: state => {
        return id => state.allBuyers.find (buyer => {
            return buyer.Customer.id === id
        })
    }
}

const actions = {
    activateBuyerById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .put("/customers/" + val + '/activate')
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    checkBuyerBeforeDestroy({ state }, val) {
        return new Promise((resolve, reject) => {
            api
                .get("/customers/" + val + "/check-records")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    createBuyer ({state, rootState}) {
        let company = _.cloneDeep(state.current.Company)
        let customer = _.cloneDeep(state.current.Customer)
        let creditLimit = _.cloneDeep(state.current.CreditLimit)
        //remove unneeded
        delete company.contacts
        delete customer.banks
        delete customer.deliveryAddresses

        return new Promise((resolve, reject) => {
            api
                .post("/customers" ,
                    {
                        company: company,
                        creditLimit: creditLimit,
                        customer: customer,
                        contacts: rootState.appic.contact.current.Contacts
                    }
                )
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    destroyBuyerById({ state }, val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/customers/" + val + "/destroy")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    filterAllBuyers ({commit,state},payload) {
        let filterOptions = payload.filterOptions
        let filters = {}
        if(filterOptions.country?.Country?.id){
            Object.assign(filters,{'Company': {'country_id': [filterOptions.country.Country.id]}})
        }
        if(filterOptions.salescolleague?.Salescontact?.id){
            Object.assign(filters,{'Customer': {'salescontact_id': [filterOptions.salescolleague.Salescontact.id]}})
        }
        if(filterOptions.productsegment?.Marketsegment?.id){
            Object.assign(filters,{'Customer': {'marketsegment_id': [filterOptions.productsegment.Marketsegment.id]}})
        }
        let buyers = state.allBuyers.filter(d => {
            return Object.keys(filters).every(f => {
                return Object.keys(filters[f]).every(k => {
                    return filters[f][k].length < 1 || filters[f][k].includes(d[f][k])
                })
            })
        })
        commit('setFilteredBuyers',buyers);
    },
    filterAllActiveSuspendedBuyers ({commit,state},payload) {
        let filterOptions = payload.filterOptions
        let filters = {}
        if(filterOptions.country?.Country?.id){
            Object.assign(filters,{'Company': {'country_id': [filterOptions.country.Country.id]}})
        }
        if(filterOptions.salescolleague?.Salescontact?.id){
            Object.assign(filters,{'Customer': {'salescontact_id': [filterOptions.salescolleague.Salescontact.id]}})
        }
        if(filterOptions.productsegment?.Marketsegment?.id){
            Object.assign(filters,{'Customer': {'marketsegment_id': [filterOptions.productsegment.Marketsegment.id]}})
        }
        let buyers = state.allActiveSuspendedBuyers.filter(d => {
            return Object.keys(filters).every(f => {
                return Object.keys(filters[f]).every(k => {
                    return filters[f][k].length < 1 || filters[f][k].includes(d[f][k])
                })
            })
        })
        commit('setFilteredActiveSuspendedBuyers',buyers);
    },
    getAllActiveBuyers ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/customers",{
                    params: {
                        conditions: [
                            {
                                field: 'customers.removed',
                                value: '0'
                            }
                        ]
                    }
                })
                .then(response => {
                    commit('setAllActiveBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllBuyers ({commit}) {
        return new Promise ((resolve, reject) => {
            api
                .get("/customers/all")
                .then(response => {
                    commit('setAllActiveSuspendedBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllBuyerCountries ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/customers/countries",{
                })
                .then(response => {
                    commit('setBuyerCountries',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllBuyerProductSegments ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/customers/product-segments",{
                })
                .then(response => {
                    commit('setBuyerProductSegments',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getBuyerToUpdateById ({commit},customer_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/customers/" + customer_id,{
                })
                .then(response => {
                    try {
                        commit('setBuyerToBeUpdated', response.data.data[0]);
                        resolve('done')
                    } catch(error) {
                        reject(error);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllBuyerSalesColleagues ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/customers/sales-colleagues",{
                })
                .then(response => {
                    commit('setBuyerSalesColleagues',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllBuyers ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentBuyer ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    searchAllBuyers ({commit, state},payload) {
        let buyers = state.allBuyers.filter(d => {
            if(payload.search.field.split('.').reduce((o,i)=>o[i], d)) {
                // return payload.search.field.split('.').reduce((o, i) => o[i], d).includes(payload.search.value)
                let fieldValue = payload.search.field.split('.').reduce((o, i) => o[i], d).toLowerCase()
                let searchTerm = payload.search.value.toLowerCase()
                return fieldValue.includes(searchTerm)
            } else {
                return false
            }
        })
        commit('setFilteredBuyers',buyers);
    },
    searchAllActiveSuspendedBuyers ({commit, state},payload) {
        let buyers = state.allActiveSuspendedBuyers.filter(d => {
            if(payload.search.field.split('.').reduce((o,i)=>o[i], d)) {
                // return payload.search.field.split('.').reduce((o, i) => o[i], d).includes(payload.search.value)
                let fieldValue = payload.search.field.split('.').reduce((o, i) => o[i], d).toLowerCase()
                let searchTerm = payload.search.value.toLowerCase()
                return fieldValue.includes(searchTerm)
            } else {
                return false
            }
        })
        commit('setFilteredActiveSuspendedBuyers',buyers);
    },
    sendOtsLoginInfoToBuyer ({commit}, payload) {
        return new Promise ((resolve, reject) => {
            api
                .put('/customers/' + payload.buyerId + '/send-ots-login-info',{
                    contacts: payload.contacts,
                    bccsales: payload.bccSales
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    suspendBuyerById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/customers/" + val)
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    suspendMultipleBuyersById ({commit},customerIds) {
        return new Promise((resolve, reject) => {
            api
                .patch("/customers/bulk-suspend",  {
                    customers: customerIds
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateBuyer ({state, rootState}) {
        let company = _.cloneDeep(state.update.Company)
        let customer = _.cloneDeep(state.update.Customer)
        let creditLimit = _.cloneDeep(state.update.CreditLimit)
        //remove unneeded
        delete company.contacts
        delete customer.banks
        delete customer.deliveryAddresses

        return new Promise((resolve, reject) => {
            api
                .put("/customers/" + state.update.Customer.id ,
                    {
                        company: company,
                        customer: customer,
                        creditLimit: creditLimit,
                        contacts: rootState.appic.contact.update.Contacts,
                        addresses: rootState.appic.customeraddress.update.Addresses
                })
                .then(response => {
                    this.updatedBuyerSaved = true
                    this.updatedBuyerUnsaved = false
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                    resolve(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

const mutations = {
    resetCurrentState (state) {
        console.log('Resetting this buyer...')
        state.current.Company = getDefaultState().Company
        state.current.Customer = getDefaultState().Customer
        state.current.CreditLimit = getDefaultState().CreditLimit
        state.newBuyerUnsaved = false
        state.newBuyerSaved = false
    },
    resetState (state){
        state.allActiveSuspendedBuyers = getDefaultState().allActiveSuspendedBuyers
        state.allBuyers = getDefaultState().allBuyers
        state.current.Company = getDefaultState().Company
        state.current.Customer = getDefaultState().Customer
        state.current.CreditLimit = getDefaultState().CreditLimit
    },
    setAllActiveBuyers (state,data) {
        state.allBuyers = data;
        state.filteredBuyers = data;
    },
    setAllActiveSuspendedBuyers (state, data) {
        state.allActiveSuspendedBuyers = data;
        state.filteredActiveSuspendedBuyers = data
    },
    setBuyerToBeUpdated (state,data) {
        state.update.Company = data.Company
        state.update.Customer = data.Customer
        state.update.Country = data.Country
        state.update.CreditLimit = data.CreditLimit
    },
    setFilteredActiveSuspendedBuyers (state, data) {
        state.filteredActiveSuspendedBuyers = data;
    },
    setFilteredBuyers (state,data) {
        state.filteredBuyers = data;
    },
    setBuyerCountries (state,data) {
        state.buyerCountries = data;
    },
    setBuyerProductSegments (state,data) {
        state.buyerProductSegments = data;
    },
    setBuyerSalesColleagues (state,data) {
        state.buyerSalesColleagues = data;
    },
    updateField
}

export const buyer = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}