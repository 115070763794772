import axios from 'axios';
import { store } from '../store/store';

// export default
// axios.create({
//     baseURL: process.env.VUE_APP_API_BASE_URL,
//     headers: {"Authorization": apiToken()}
// });

console.log(process.env.VUE_APP_API_BASE_URL);

let instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});
instance.interceptors.request.use((config) => {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user?.apiToken) {
        config.headers['Authorization'] = 'Bearer ' + user.apiToken
        config.headers['User-Id'] = user.user.email
    }
    return config
}, error => {
    return Promise.reject(error)
})
let context = this
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    if (error.response.data.message == 'The refresh token is expired' || error.response.data.message == 'Token not found'){
        store.dispatch("logoutUserFromFirebase" );
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    //get new token and try the request again with the new token
    let newToken = new Promise((resolve,reject)=>{
        let user = JSON.parse(localStorage.getItem('user'))
        instance
            .post('/token/refresh',{
                email: user.user.email,
                refresh_token: user.refreshToken
            })
            .then(response => {
                //update user object in localStorage
                user.apiToken = response.data.token
                store.commit('updateUserToken', user);
                //update table updates
                store.dispatch('updateStore', response.data.tableUpdates);
                resolve(response.data.token)
            })
            .catch(error => {
                reject(error);
            });
    })
    return newToken
        .then(newToken => {
            //update api token
            const config = error.config;
            let user = JSON.parse(localStorage.getItem('user'))
            config.headers['Authorization'] = 'Bearer ' + newToken
            config.headers['User-Id'] = user.user.email

            return new Promise((resolve, reject) => {
                instance.request(config).then(response => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                })
            });
        });
});

export const api = instance
