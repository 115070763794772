import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		Sda: {
			id: null,
			alsonotifybl: 0,
			alsonotifybl2: null,
			alsonotifyblstatement: null,
			contract_id: null,
			consigneecert2: null,
			consigneephyto2: null,
			consigneebltoinvaddress2: null,
			deliveryaddress_id: null,
			doc_deliveryaddress_id: null,
			docrequired: 'triplicate',
			invoicingaddress2: 'main',
			longform: 0,
			othercertstatement: null,
			otherconsignee: null,
			otherinvstatement: null,
			othernotifybl2: null,
			othernotifyblstatement: null,
			otherphytostatement: null,
			othershipper2: 'main',
			othershipperstatement: null,
			sdadate: null,
			title: null,
			Addresses: {
				Customer: [],
				Supplier: []
			},
			Contract: {
				customer_id: null,
				incoterm_id: null,
				supplier_id: null,
				supplier_pi_no: null,
			},
		}
	}
}

const state = {
	allActiveSdas: {},
	current: {
		Sda: {
			id: null,
			alsonotifybl: 0,
			alsonotifybl2: null,
			alsonotifyblstatement: null,
			contract_id: null,
			consigneecert2: null,
			consigneebltoinvaddress2: null,
			consigneephyto2: null,
			deliveryaddress_id: null,
			doc_deliveryaddress_id: null,
			docrequired: 'triplicate',
			invoicingaddress2: 'main',
			longform: 0,
			othercertstatement: null,
			otherconsignee: null,
			otherinvstatement: null,
			othernotifybl2: null,
			othernotifyblstatement: null,
			otherphytostatement: null,
			othershipper2: 'main',
			othershipperstatement: null,
			sdadate: null,
			title: null,
			Addresses: {
				Customer: [],
				Supplier: []
			},
			Contract: {
				customer_id: null,
				incoterm_id: null,
				partial_no: null,
				revision_no: null,
				supplier_id: null,
				supplier_pi_no: null,
				title: null,
				version: null
			},
		},
		Sdas: [],
		totalSdas: 0,
	},
	update: {
		Sda: {
			Addresses: {
				Customer: [],
				Supplier: []
			},
			Contract: {
				incoterm_id: null
			},
		}
	},
	newSdaSaved: false,
	newSdaUnsaved: false,
	updatedSdaSaved: false,
	updatedSdaUnsaved: false
}

const getters = {
	allActiveSdas: state => { return state.allActiveSdas },
	getField
}

const mutations = {
	resetCurrentState (state) {
		console.log('Resetting this SDA...')
		state.current.Sda = getDefaultState().Sda
		state.newSdaUnsaved = false
		state.newSdaSaved = false
	},
	resetState (state){
		console.log('Resetting SDAs...')
		state.allActiveSdas = []
	},
	setAllActiveSdas (state, data) {
		state.allActiveSdas = data;
	},
	setCurrentSda (state,payload) {
		let contract = payload
		state.current.Sda.contract_id = contract.Contract?.id
		let contract_title = contract.Contract?.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title
		state.current.Sda.title = contract_title
		state.current.Sda.Contract.customer_id = contract.Customer.id
		state.current.Sda.Contract.supplier_id = contract.Supplier.id
		state.current.Sda.Contract.partial_no = contract.Contract.partial_no
		state.current.Sda.Contract.revision_no = contract.Contract.revision_no
		state.current.Sda.Contract.title = contract.Contract.title
		state.current.Sda.Contract.version = contract.Contract.version
	},
	setSdas (state,data) {
		state.current.Sdas = data;
	},
	setSdaToBeUpdated (state, data) {
		state.update.Sda = data.Sda
	},
	setTotalSdas (state,val) {
		state.current.totalSdas = val
	},
	updateField
}

const actions = {
	cancelSdaById ({commit},val){
		return new Promise((resolve, reject) => {
			api
				.delete("/sdas/" + val)
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createSda ({state, rootState}){
		let sda = _.cloneDeep(state.current.Sda)
		//remove unneeded properties
		delete sda.Contract
		delete sda.Addresses
		return new Promise((resolve, reject) => {
			api
				.post('/sdas',{
						sda: sda,
						clauses: rootState.appic.sdaclause.current.Clauses
					}
				)
				.then((response) => {
					if(response.data.status == 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllActiveSdas ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get("/sdas/active")
				.then(response => {
					commit('setAllActiveSdas',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllSdas ({commit},payload) {
		// let filterOptions = payload.filterOptions
		//let conditions = []
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/sdas", {
						params: {
							// conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'sdas.sdadate',
									direction: 'DESC'
								},
							]
						}
					})
					.then(response => {
						commit('setSdas', response.data.data)
						commit('setTotalSdas', response.data.totalRows)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getSdaToUpdateById ({commit},sda_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/sdas/' + sda_id)
				.then(response => {
					let sda = response.data.data[0]
					sda['Sda']['Addresses'] = {
						Customer: [],
						Supplier: []
					}
					commit('setSdaToBeUpdated', sda);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	loadContractWithoutSda ({commit, rootState},payload) {
		return new Promise((resolve, reject) => {
			commit('setCurrentSda', payload)
			resolve('done')
		})
	},
	resetAllActiveSdas ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetState')
			resolve('done')
		})
	},
	resetCurrentSda ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllSdas ({commit}, payload) {
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/sdas",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// skip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
					}
				})
				.then(response => {
					commit('setSdas', response.data.data)
					commit('setTotalSdas', response.data.totalRows)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	sendShippingInstructionToSupplier ({commit}, payload){
		return new Promise ((resolve, reject) => {
			api
				.put('/sdas/' + payload.sdaId + '/send-to-supplier',{
					contacts: payload.contacts
				})
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	updateSda ({state, rootState}){
		let sda = _.cloneDeep(state.update.Sda)
		//remove unneeded properties
		delete sda.Addresses
		delete sda.Contract
		delete sda.Customer
		delete sda.Destination
		delete sda.Destinationport
		delete sda.Office
		delete sda.Supplier

		return new Promise((resolve, reject) => {
			api
				.put("/sdas/" + state.update.Sda.id,{
					sda: sda,
					clauses: rootState.appic.sdaclause.update.Clauses,
				})
				.then(response => {
					this.updatedSdaSaved = true
					this.updatedSdaUnsaved = false
					resolve('done')
				})
				.catch(error => {
					reject(error)
					resolve(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const sda = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}