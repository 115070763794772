import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Incoterm: {
            id: null,
            title: null
        }
    }
}

const state = {
    allIncoTerms: [],
    current: {
        Incoterm: {
            id: null,
            title: null
        }
    },
    update: {
        Incoterm: {}
    }
}

const getters = {
    allIncoTerms: state => {
        return state.allIncoTerms;
    },
    getField
}

const mutations = {
    resetCurrentState (state) {
        state.current.Incoterm = getDefaultState().Incoterm
    },
    resetIncoTermToUpdate (state) {
        state.update.Incoterm = getDefaultState().Incoterm
    },
    resetState (state) {
        state.allIncoTerms = []
    },
    setAllIncoTerms(state,data){
        state.allIncoTerms = data;
    },
    setIncoTermToUpdate ( state, incoTerm ){
        state.update.Incoterm = incoTerm.Incoterm
    },
    updateField
}

const actions = {
    createIncoTerm( {state} ) {
        const incoTerm = _.cloneDeep(state.current.Incoterm)
        return new Promise((resolve, reject) => {
            api
                .post('/incoterms',{
                        incoterm: incoTerm,
                    }
                )
                .then((response)=>{
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    deleteIncoTermById({commit}, incoTermId) {
        return new Promise((resolve, reject) => {
            api
                .delete('/incoterms/' + incoTermId)
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    getAllIncoTerms({commit}){
        return new Promise((resolve, reject) => {
            api
                .get("/incoterms")
                .then(response => {
                    commit('setAllIncoTerms',response.data.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getIncoTermToUpdateById({commit}, incoTermId) {
        return new Promise((resolve, reject) => {
            api
                .get('/incoterms/' + incoTermId )
                .then(response => {
                    commit('setIncoTermToUpdate', response.data.data[0])
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetCurrentState ({commit}) {
        commit('resetCurrentState')
    },
    resetIncoTermToUpdate({commit}) {
        commit('resetIncoTermToUpdate')
    },
    resetState ( {commit} ){
        commit('resetState')
    },
    updateIncoTerm ( {state} ){
        const incoTerm = _.cloneDeep(state.update.Incoterm)

        return new Promise((resolve, reject) => {
            api
                .put("/incoterms/" + state.update.Incoterm.id,{
                    incoterm: incoTerm
                })
                .then(response => {
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

export const incoterm = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}