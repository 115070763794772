import { api } from "../../../api"
import {getField, updateField} from "vuex-map-fields"
import _ from 'lodash'

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        id: null,
        adjustment: 0,
        adjustment_title: 'Adjustment',
        bank_id: null,
        Bank: {
            id: null,
            title: null,
            account: null,
            swiftcode: null,
            beneficiary: null
        },
        buyeraddresstype: 0,
        customer_pi_no: null,
        commission_by_unit: 0,
        commission_detail: null,
		commission_per_line: 0,
        commission_term_id: 0,
        commission_term_against_id: 0,
        containercount: 1,
        containersize_id: 1,
        contractdate: new Date().toISOString().substr(0, 10),
        currency_id: 2,
        customer_id: null,
        Customer: {
            id: null,
            title: ''
        },
        date_signed: null,
        Debitnote: {
            id: null,
            title: null
        },
        deliveryaddress_id: 0,
        Deliveryaddress: {
            id: 0,
            address: 'message.sameAsBuyer'
        },
        destinatione_id: null,
        documents: [],
        eutr_compliance_required: null,
        footer_notes: [1,2,3,4,5,6,7,8,9],
        fsc: 0,
        is_export: 0,
        is_others: null,
        issigned: 0,
        isposigned: 0,
        issplit: null,
        issplitcomplete: null,
        incoterm_id: 2,
        incotermport: 0,
        Invoice: {
            id: null,
            invno: null
        },
        loadingport_id: null,
        longform: null,
        Loadingport: {
            country: null,
        },
        margin: 0,
        marketsegment_id: null,
        ms_id: 1,
        note: null,
        office_id: 1,
        partialShipments: [],
        partial_no: null,
        paymentstatement_id: null,
        paymentterm_id: null,
        pi_no: null,
        pi_exrate: 1,
        pi_exratedate: null,
        productgroup_id: null,
        productorigin_id: null,
        Purchaseorder: {
            id: null,
            date_signed: null,
            paymentstatement_id: null
        },
        remove_fscpefc_report: null,
        revision_no: null,
        salescontact_id: null,
        salestatus_id: 1,
        salestype_id: 1,
        sellertitle: 'Seller',
        shippingport_id: null,
        shipweekdate: null,
        shippingweekstatement: null,
        showbank: 1,
        showsignature: 'Y',
        stampsignatured: null,
        supplier_id: null,
        supplier_pi_no: null,
        title: null,
        unit_commission: 0
    }
}

const state = {
    allActiveContracts: [],
    allClosedContracts: [],
    clone: {
        copyPurchaseOrder: 1,
        OriginalContract: {
            id: null,
            title: null,
        },
        Contract: {
            Purchaseorder: {}
        },
        Salesitems: []
    },
    current: {
        Contract: {
            id: null,
            adjustment: 0,
            adjustment_title: null,
            bank_id: null,
            Bank: {
                id: null,
                title: null,
                account: null,
                swiftcode: null,
                beneficiary: null
            },
            buyeraddresstype: 0,
            customer_pi_no: null,
            commission_by_unit: 0,
			commission_detail: null,
			commission_per_line: 0,
            commission_term_id: 0,
            commission_term_against_id: 0,
            containercount: 1,
            containersize_id: 1,
            contractdate: new Date().toISOString().substr(0, 10),
            currency_id: null,
            customer_id: null,
            Customer: {
                id: null,
                title: ''
            },
            date_signed: null,
            deliveryaddress_id: 0,
            Debitnote: {
                id: null,
                title: null
            },
            Deliveryaddress: {
                id: 0,
                address: 'message.sameAsBuyer'
            },
            destinatione_id: null,
            documents: [],
            eutr_compliance_required: null,
            footer_notes: [1,2,3,4,5,6,7,8,9],
            fsc: 0,
            is_export: null,
            is_others: null,
            issigned: null,
            isposigned: null,
            issplit: null,
            issplitcomplete: null,
            incoterm_id: 2,
            incotermport: 0,
            Invoice: {
                id: null,
                invno: null
            },
            loadingport_id: null,
            Loadingport: {
                country: null,
            },
            longform: null,
            margin: 0,
            marketsegment_id: null,
            ms_id: 1,
            note: null,
            office_id: 1,
            partial_no: null,
            partialShipments: [],
            paymentstatement_id: null,
            paymentterm_id: null,
            pi_no: null,
            pi_exrate: 1,
            pi_exratedate: null,
            productgroup_id: null,
            productorigin_id: null,
            Purchaseorder: {
                id: null,
                date_signed: null,
                paymentstatement_id: null
            },
            remove_fscpefc_report: null,
            revision_no: null,
            salescontact_id: null,
            salestatus_id: 1,
            salestype_id: 1,
            sellertitle: 'Seller',
            shippingport_id: null,
            shipweekdate: null,
            shippingweekstatement: null,
            showbank: 1,
            showsignature: 'Y',
            stampsignatured: null,
            supplier_id: null,
            supplier_pi_no: null,
            title: null,
            unit_commission: 0
        },
        Contracts: [],
        contractBuyers: [],
        contractDestinations: [],
        contractPaymentStatuses: [],
        contractStatuses: [],
        contractSuppliers: [],
        totalContracts: 0,
    },
    exportConditions: [],
    partial: {
        copyPurchaseOrder: 1,
        OriginalContract: {
            id: null,
            title: null,
        },
        Contract: {
            Purchaseorder: {}
        },
        Salesitems: []
    },
    update: {
        Contract: {
            documents: [],
            Customer: {},
            Debitnote: {},
            Deliveryaddress: {},
            Invoice: {},
            Purchaseorder: {},
            Loadingport: {}
        }
    },
    newContractSaved: false,
    newContractUnsaved: false,
    updatedContractUnsaved: false,
    updatedContractSaved: false
}

const getters = {
    allActiveContracts: state => {
        return state.allActiveContracts
    },
    allClosedContracts: state => {
        return state.allClosedContracts
    },
    allContractsWithoutCoverNote: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Tta?.id == null && contract?.Invoice?.id != null
        })
    },
    allContractsWithoutSda: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Sda?.id == null
        })
    },
    allLcContractsWithoutLca: state => {
        return state.allActiveContracts.filter(contract => {
            // return contract?.Lca?.id == null && [4029,4030,4096,4219,4276,4302,4317,4318,4347,4378].includes(contract.Paymentstatement.id)
            if(contract?.Paymentstatement?.title != null) {
                return contract?.Lca?.id == null && (contract.Paymentstatement.title.includes(' L/C') || contract.Paymentstatement.title.includes('L/C ') || contract.Paymentstatement.title.includes('L/C') || contract.Paymentstatement.title.includes('LC') || contract.Paymentstatement.title.includes(' LC') || contract.Paymentstatement.title.includes('LC '))
            } else {
                return false
            }
        })
    },
    allUnInvoicedCommissionContracts: state => {
        let contracts = [];
        state.allActiveContracts.forEach(contract => {
            if(contract?.Debitnote?.id == null && [2].includes(contract.Contract.salestype_id)){
                contracts.push(contract)
            }
        })
        state.allClosedContracts.forEach(contract => {
            if(contract?.Debitnote?.id == null && [2].includes(contract.Contract.salestype_id)){
                contracts.push(contract)
            }
        })
        return contracts
    },
    allUnpurchasedContracts: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Purchaseorder?.id == null && [1,3,5,6].includes(contract.Contract.salestype_id)
        })
    },
    allUnInvoicedContracts: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Invoice?.id == null && [1,5].includes(contract.Contract.salestype_id)
        })
    },
    allUnShippedContracts: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Invoice?.id != null && [1,3,5,6].includes(contract?.Contract?.salestype_id) && contract?.Pldo?.id === null
        })
    },
    allUnShippedMyContracts: state => {
        return state.allActiveContracts.filter(contract => {
            return contract?.Invoice?.id != null && [1,3,5,6].includes(contract?.Contract?.salestype_id) && contract?.Pldo?.id === null && contract?.Contract?.destinatione_id === 1
        })
    },
    contractBuyers: state => { return state.current.contractBuyers },
    contractDestinations: state => { return state.current.contractDestinations },
    contractPaymentStatuses: state => { return state.current.contractPaymentStatuses },
    contractStatuses: state => { return state.current.contractStatuses },
    contractSuppliers: state => { return state.current.contractSuppliers },
    getContractById: (state) => (id) => {
        let activeContract = state.allActiveContracts.find(contract => contract.Contract.id === id)
        if(activeContract){
            return activeContract
        } else {
            let closedContract = state.allClosedContracts.find(contract => contract.Contract.id === id)
            return  closedContract
        }
    },
    getField
}

const mutations = {
    cancelContract () {
        state.current.Contract.salestatus_id = 10
    },
    copyContract (state, payload) {
        state[payload.destination].OriginalContract.id = _.cloneDeep(state[payload.source].Contract.id)
        state[payload.destination].OriginalContract.title = _.cloneDeep(state[payload.source].Contract.title)
        state[payload.destination].Contract = _.cloneDeep(state[payload.source].Contract)
        state[payload.destination].Contract.id = null
        state[payload.destination].Contract.contractdate = new Date().toISOString().substr(0, 10)
        state[payload.destination].Contract.partial_no = null
        state[payload.destination].Contract.revision_no = null
        state[payload.destination].Contract.salestatus_id = 1
        state[payload.destination].Contract.shipweekdate = null
        state[payload.destination].Contract.shippingweekstatement = null
        state[payload.destination].Contract.title = null

        state[payload.destination].Contract.customer_pi_no = null
        state[payload.destination].Contract.date_signed = null
        state[payload.destination].Contract.documents = []
        state[payload.destination].Contract.issigned = 0
        state[payload.destination].Contract.isposigned = 0
        state[payload.destination].Contract.issplit = 0
        state[payload.destination].Contract.issplitcomplete = null

        state[payload.destination].Salesitems = _.cloneDeep(payload.salesItemsToClone)
        state[payload.destination].Salesitems.forEach((item)=>{
            item.Salesitem.id = null
        })

        state[payload.destination].copyPurchaseOrder = 1
        if(state[payload.destination].Contract.salestype_id == 2) state[payload.destination].copyPurchaseOrder = 0
        if(state[payload.destination].Contract.salestype_id == 3) state[payload.destination].Contract.salestype_id = 1
    },
    partialContract (state, payload) {
        state[payload.destination].OriginalContract.id = _.cloneDeep(state[payload.source].Contract.id)
        state[payload.destination].OriginalContract.title = _.cloneDeep(state[payload.source].Contract.title)
        state[payload.destination].OriginalContract.revision_no = _.cloneDeep(state[payload.source].Contract.revision_no)
        state[payload.destination].OriginalContract.partial_no = _.cloneDeep(state[payload.source].Contract.partial_no)
        state[payload.destination].OriginalContract.version = _.cloneDeep(state[payload.source].Contract.version)
        state[payload.destination].Contract = _.cloneDeep(state[payload.source].Contract)
        state[payload.destination].Contract.id = null
        state[payload.destination].Contract.contractdate = new Date().toISOString().substr(0, 10)
        state[payload.destination].Contract.partial_no = null
        state[payload.destination].Contract.issplit = 0
        state[payload.destination].Contract.issplitcomplete = null
        state[payload.destination].Contract.parent_id = _.cloneDeep(state[payload.source].Contract.id)
        state[payload.destination].Contract.ispartialshipment = 1
        state[payload.destination].Salesitems = _.cloneDeep(payload.salesItemsToSplit)
        state[payload.destination].Salesitems.forEach((item)=>{
            item.Salesitem.id = null
			item.Salesitem.invprice = item.Salesitem.unitprice
        })
        state[payload.destination].Contract.partial_no = (state[payload.source].Contract.partialShipments.length + 1).toString()
        state[payload.destination].Contract.salestatus_id = 1

        state[payload.destination].copyPurchaseOrder = 1
        if(state[payload.destination].Contract.salestype_id == 2) state[payload.destination].copyPurchaseOrder = 0
    },
    resetState (state){
        console.log('Resetting contracts...')
        state.allActiveContracts = []
        state.allClosedContracts = []
        state.exportConditions = []
    },
    resetCurrentState (state){
        console.log('Resetting this contract...')
        state.current.Contract = getDefaultState()
        state.newContractUnsaved = false
        state.newContractSaved = false
    },
    setContracts (state, data) {
        state.current.Contracts = data;
        // state.current.totalContracts = data.length;
    },
    setAllActiveContracts (state, data) {
        state.allActiveContracts = data;
    },
    setAllClosedContracts (state, data) {
        state.allClosedContracts = data;
    },
    setContractBuyer(state,payload){
        let prefix = payload.prefix
        let buyer = payload.buyer
        state[prefix].Contract.destinatione_id = buyer.Country.id
        state[prefix].Contract.marketsegment_id = buyer.Marketsegment.id
        state[prefix].Contract.salescontact_id = buyer.Salescontact.id
        if([1,5].includes(state[prefix].Contract.salestype_id)){
            if(buyer.Customer.paymentstatement_id) {
                state[prefix].Contract.paymentstatement_id = buyer.Customer.paymentstatement_id
            }
        }
    },
    setContractBuyers (state,data) {
        state.current.contractBuyers = data;
    },
    setContractDestinations (state,data) {
        state.current.contractDestinations = data;
    },
    setContractStatuses (state,data) {
        state.current.contractStatuses = data;
    },
    setContractSuppliers (state,data) {
        state.current.contractSuppliers = data;
    },
    setContractPaymentStatuses (state,data) {
        state.current.contractPaymentStatuses = data;
    },
    setContractToBePrinted (state, data) {
        state.print.Contract = data.Contract
    },
    setContractToBeUpdated (state, data) {
        state.update.Contract = data.Contract
    },
    setExportConditions (state, conditions) {
        state.exportConditions = conditions
    },
    setTotalContracts (state,val) {
        state.current.totalContracts = val;
    },
    updateField,
    updatePartialContractSalesItems (state, payload){
        let keys = payload['field'].split('.')
        let prefix = 'partial'
        state[prefix].Salesitems[payload['index']][keys[0]][keys[1]] = payload['value']
        let numberFields = ['invqty','invprice','invoiceamount']
        if(keys[0] == 'Salesitem' && numberFields.indexOf(keys[1]) !== -1){
            //update amount
            if(state[prefix].Salesitems[payload['index']]['type'] == 'default') {
                let amount = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invqty']) * parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice'])
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount'] = amount
            }
            //update converted fields
            if(state[prefix].Salesitems[payload['index']]['type'] == 'default') {
                state[prefix].Salesitems[payload['index']]['Salesitem']['invprice_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invqty']) * (parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invprice']) * state[prefix].itemsCurrencyRate)
            } else if(state[prefix].Salesitems[payload['index']]['type'] == 'othercharge') {
                state[prefix].Salesitems[payload['index']]['Salesitem']['invprice_converted'] = 0;
                state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount_converted'] = parseFloat(state[prefix].Salesitems[payload['index']]['Salesitem']['invoiceamount']) * state[prefix].itemsCurrencyRate
            }
        }
    },
}

const actions = {
    cancelContract ({commit}) {
        commit('cancelContract')
    },
    cancelContractById ({commit},val){
        return new Promise((resolve, reject) => {
            api
                .delete("/contracts/" + val)
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    cloneContract({commit, rootState}){
        return new Promise((resolve, reject) => {
            let payload = {
                source: 'update',
                destination: 'clone',
                salesItemsToClone: rootState.appic.salesitem.update.Salesitems
            }
            commit('copyContract', payload)
            resolve('done')
        })
    },
    completeContractById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .put("/contracts/" + val + "/close")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    contractBuyerChanged({commit},payload){
        return new Promise((resolve, reject) => {
            console.log('Buyer changed ...')
            commit("setContractBuyer", payload)
            resolve('done')
        })
    },
    createClonedContract ({state}) {
        let contractToCopy = _.cloneDeep(state.clone.Contract)
        let originalContract = _.cloneDeep(state.clone.OriginalContract)
        let salesItemsToCopy = _.cloneDeep(state.clone.Salesitems)
        // remove unneeded properties
        delete contractToCopy.id
        delete contractToCopy.documents
        delete contractToCopy.Bank
        delete contractToCopy.Customer
        delete contractToCopy.Debitnote
        delete contractToCopy.Deliveryaddress
        delete contractToCopy.Destination
        delete contractToCopy.Invoice
        delete contractToCopy.Loadingport
        delete contractToCopy.Office
        delete contractToCopy.Origin
        delete contractToCopy.partialShipments
        delete contractToCopy.Paymentterm
        delete contractToCopy.Purchaseorder
        delete contractToCopy.Salesperson
        delete contractToCopy.Shipment
        delete contractToCopy.Shippingport
        delete contractToCopy.Supplier
        return new Promise((resolve, reject) => {
            api
                .post("/contracts/"+ originalContract['id'] + "/clone" ,{
                    copyPurchaseOrder: state.clone.copyPurchaseOrder,
                    originalContract: originalContract,
                    contractToCopy: contractToCopy,
                    salesItemsToCopy: salesItemsToCopy
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })

        })
    },
    createContract ({state, rootState}) {
        let contract = _.cloneDeep(state.current.Contract)
        let documents = _.cloneDeep(state.current.Contract.documents)
        // remove unneeded properties
        delete contract.Bank
        delete contract.Customer
        delete contract.Debitnote
        delete contract.Deliveryaddress
        delete contract.Deliveryaddress_address
        delete contract.Invoice
        delete contract.Loadingport
        delete contract.documents
        delete contract.id
        delete contract.partialShipments
        delete contract.Purchaseorder
        return new Promise((resolve, reject) => {
            api
                .post("/contracts" ,
                    {
                        contract: contract,
                        salesitems: rootState.appic.salesitem.current.Salesitems,
                        documents: documents,
                        specificationNotes_notes: rootState.appic.salesitem.current.specificationNotes
                    }
                )
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    createPartialContract ({state}) {
        let contractToSplit = _.cloneDeep(state.partial.Contract)
        let originalContract = _.cloneDeep(state.partial.OriginalContract)
        let salesItemsToSplit = _.cloneDeep(state.partial.Salesitems)
        // remove unneeded properties
        delete contractToSplit.id
        delete contractToSplit.documents
        delete contractToSplit.Bank
        delete contractToSplit.Customer
        delete contractToSplit.Debitnote
        delete contractToSplit.Deliveryaddress
        delete contractToSplit.Destination
        delete contractToSplit.Invoice
        delete contractToSplit.Loadingport
        delete contractToSplit.Office
        delete contractToSplit.Origin
        delete contractToSplit.partialShipments
        delete contractToSplit.Paymentterm
        delete contractToSplit.Purchaseorder
        delete contractToSplit.Salesperson
        delete contractToSplit.Shipment
        delete contractToSplit.Shippingport
        delete contractToSplit.Supplier
        return new Promise((resolve, reject) => {
            api
                .post("/contracts/"+ originalContract['id'] + "/partial" ,{
                    copyPurchaseOrder: state.partial.copyPurchaseOrder,
                    originalContract: originalContract,
                    contractToSplit: contractToSplit,
                    salesItemsToSplit: salesItemsToSplit
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve(response.data)
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                })

        })
    },
    getAllActiveContracts ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/active",{
                    params: {
                        conditions: [
                            {
                                field: 'salestype_id',
                                value: [1,2,3,5,6]
                            }
                        ]
                    }
                })
                .then(response => {
                    commit('setAllActiveContracts',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllClosedContracts ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/closed",{
                    params: {
                        conditions: [
                            {
                                field: 'salestype_id',
                                value: [1,2,3,5,6]
                            }
                        ]
                    }
                })
                .then(response => {
                    commit('setAllClosedContracts',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContracts ({commit},payload) {
        // let filterOptions = payload.filterOptions
        // let conditions = []
        // if(filterOptions.status?.Status?.id){
        //     if([1,2,10].includes(filterOptions.status.Status.id)) {
        //         let obj = {
        //             field: 'contracts.salestatus_id',
        //             value: filterOptions.status.Status.id
        //         }
        //         conditions.push(obj)
        //     } else if(filterOptions.status.Status.id == 11) {
        //         let obj_1 = {
        //             field: 'contracts.salestatus_id',
        //             value: 1
        //         }
        //         conditions.push(obj_1)
        //         let obj_2 = {
        //             field: 'contracts.salestype_id',
        //             value: [1,2,5]
        //         }
        //         conditions.push(obj_2)
        //         let obj_3 = {
        //             field: 'contracts.issigned',
        //             value: 0,
        //             notNull: true
        //         }
        //         conditions.push(obj_3)
        //         let obj_4 = {
        //             field: 'contracts.isposigned',
        //             value: [0,1],
        //             notNull: true
        //         }
        //         conditions.push(obj_4)
        //     } else if(filterOptions.status.Status.id == 12) {
        //         let obj_1 = {
        //             field: 'contracts.salestatus_id',
        //             value: 1
        //         }
        //         conditions.push(obj_1)
        //         let obj_2 = {
        //             field: 'contracts.salestype_id',
        //             value: [1, 2, 5]
        //         }
        //         conditions.push(obj_2)
        //         let obj_3 = {
        //             field: 'contracts.isposigned',
        //             value: 0,
        //             notNull: true
        //         }
        //         conditions.push(obj_3)
        //         let obj_4 = {
        //             field: 'contracts.issigned',
        //             value: 1
        //         }
        //         conditions.push(obj_4)
        //     } else if(filterOptions.status.Status.id == 13){
        //         let obj_1 = {
        //             field: 'contracts.salestatus_id',
        //             value: 1
        //         }
        //         conditions.push(obj_1)
        //         let obj_2 = {
        //             field: 'contracts.salestype_id',
        //             value: 3
        //         }
        //         conditions.push(obj_2)
        //         let obj_3 = {
        //             field: 'contracts.isposigned',
        //             value: 0,
        //             notNull: true
        //         }
        //         conditions.push(obj_3)
        //     } else if(filterOptions.status.Status.id == 999){
        //         let obj_1 = {
        //             field: 'contracts.salestatus_id',
        //             value: [1,2,10],
        //             not: true
        //         }
        //         conditions.push(obj_1)
        //         let obj_2 = {
        //             field: 'contracts.salestatus_id',
        //             value: 'null',
        //             or: true
        //         }
        //         conditions.push(obj_2)
        //     } else {
        //         let obj = {
        //             field: 'contracts.salestatus_id',
        //             value: filterOptions.status.Status.id
        //         }
        //         conditions.push(obj)
        //     }
        // }
        // if(filterOptions.supplier?.Supplier?.id){
        //     let obj = {
        //         field: 'contracts.supplier_id',
        //         value: filterOptions.supplier.Supplier.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.buyer?.Customer?.id){
        //     let obj = {
        //         field: 'contracts.customer_id',
        //         value: filterOptions.buyer.Customer.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.destination?.Destination?.id){
        //     let obj = {
        //         field: 'contracts.destinatione_id',
        //         value: filterOptions.destination.Destination.id
        //     }
        //     conditions.push(obj)
        // }
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/contracts", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'contracts.contractdate',
                                    direction: 'DESC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        commit('setContracts', response.data.data);
                        commit('setTotalContracts', response.data.totalRows);
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getAllContractBuyers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/buyers",{
                })
                .then(response => {
                    commit('setContractBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractDestinations ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/destinations",{
                })
                .then(response => {
                    commit('setContractDestinations',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractPaymentStatuses ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/payment-statuses",{
                })
                .then(response => {
                    commit('setContractPaymentStatuses',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractStatuses ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/status",{
                })
                .then(response => {
                    commit('setContractStatuses',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractSuppliers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/suppliers",{
                })
                .then(response => {
                    commit('setContractSuppliers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractsWithoutInvoice({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/no-invoice",{
                })
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllContractsWithoutDebitNote() {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/no-debit-note",{
                })
                .then(response => {
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getContractToUpdateById ({commit},contract_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/" + contract_id)
                .then(response => {
                    commit('setContractToBeUpdated',response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    resetAllContracts ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentContract ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    reopenContractById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .patch("/contracts/" + val + "/reopen")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    restoreContractById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .patch("/contracts/" + val + "/restore")
                .then(response => {
                    if(response.data.status == 'success') {
                        resolve('done')
                    } else {
                        resolve('error')
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    searchAllContracts ({commit},payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/contracts",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                    }
                })
                .then(response => {
                    commit('setExportConditions', payload.conditions)
                    commit('setContracts', response.data.data);
                    commit('setTotalContracts', response.data.totalRows);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
    splitContract({commit, rootState}){
        return new Promise((resolve, reject) => {
            let payload = {
                source: 'update',
                destination: 'partial',
                salesItemsToSplit: rootState.appic.salesitem.update.Salesitems
            }
            commit('partialContract', payload)
            resolve('done')
        })
    },
    updateContract ({state, rootState}) {
        let contract = _.cloneDeep(state.update.Contract)
        let documents = _.cloneDeep(state.update.Contract.documents)
        let purchaseOrder = _.cloneDeep(state.update.Contract.Purchaseorder)

        // remove unneeded properties
        delete contract.documents
        delete contract.Bank
        delete contract.Customer
        delete contract.Debitnote
        delete contract.Deliveryaddress
        delete contract.Deliveryaddress_address
        delete contract.Destination
        delete contract.Invoice
        delete contract.Loadingport
        delete contract.Office
        delete contract.Origin
        delete contract.Paymentterm
        delete contract.partialShipments
        delete contract.Purchaseorder
        delete contract.Salesperson
        delete contract.Shippingport
        delete contract.Supplier
        delete contract.Shipment

        return new Promise((resolve, reject) => {
            api
                .put('/contracts/' + state.update.Contract.id, {
                    contract: contract,
                    purchaseorder: purchaseOrder,
                    salesitems: rootState.appic.salesitem.update.Salesitems,
                    documents: documents,
                    specificationNotes_notes: rootState.appic.salesitem.update.specificationNotes
                })
                .then(response => {
                    this.updatedContractSaved = true
                    this.updatedContractUnsaved = false
                    resolve('done')
                })
                .catch(error => {
                    reject(error)
                    resolve(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
}

export const contract = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}